/** @format */

import {
    Box,
    Button,
    Modal,
    Stack,
    TextField,
    Select,
    MenuItem,
    SelectChangeEvent,
    Accordion,
    AccordionSummary,
} from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/img/illustration/dashboard-images/plus.svg';
import CloseIcon from '@mui/icons-material/Close';
import { getRequest, saveOrUpdateRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const useStyles = makeStyles({
    reset: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#10384f',
        color: 'white',

        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#10384f',
            color: 'white',
        },
    },
    save: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#2e7d32',
        color: 'white',

        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#2e7d32',
            color: 'white',
        },
    },
    menu: {
        display: 'block',
        lineHeight: '12.5px',
        padding: '4px',
        paddingLeft: '15px',
    },
    checkbox: {
        display: 'flex',
        padding: '3px',
    },
    list: {
        fontSize: '13px',
        fontFamily: 'appRegular',
    },
});

const style = {
    position: 'absolute' as 'absolute',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '90%',
    top: '50%',
    left: '43%',
    transform: 'translate(-39%, -48%)',
    width: '67%',
    paddingTop: '3%',
    paddingBottom: '2%',
    overflowX: 'auto',
    borderRadius: 4,
};

const MenuProps = {
    PaperProps: {
        style: {
            height: 66,
        },
    },
};

function CreateNewUser(props: any) {
    const classes = useStyles();

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const subscriptionarray = ['SMS', 'WatsApp'];
    const forecastarray = ['Daily Forecast Alert', 'Yesterday Forecast Alert'];

    const [open, setOpen] = useState<boolean>(false);
    const [userType, setUserType] = useState<any>('select');
    const [language, setLanguage] = useState<any>('select');
    const [roleID, setRoleID] = useState<String>('select');
    const [status, setStatus] = useState<String>('select');
    const [lob, setLob] = useState<any>('select');
    const [firstName, setFirstName] = useState<String>('');
    const [lastName, setLastName] = useState<String>('');
    const [email, setEmail] = useState<any>();
    const [phoneNum, setPhoneNum] = useState<any>();
    const [passWord, setPassWord] = useState<String>('');
    const [subscriptionArray, setSubscriptionArray] = useState<any[]>([]);
    const [forecastArray, setForecastArray] = useState<any[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const [roleIDArray, setRoleIDArray] = useState<any[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<any[]>([]);

    const [locationKey, setLocationKey] = useState<any>(Math.random());

    const [double, setDouble] = useState(false);


    const createUserData = () => {
        let userRequest = {
            countrycode: loggedUser?.countrycode,
            firstname: firstName,
            lastname: lastName,
            email: email,
            phonenumber: phoneNum,
            usertype: userType,
            defaultlanguage: language,
            role: roleID,
            status: status,
            password: passWord,
            lob: lob,
            smssubscription: subscriptionArray.includes('SMS'),
            whatsappsubscription: subscriptionArray.includes('WatsApp'),
            dailyforecastalert: forecastArray.includes('Daily Forecast Alert'),
            yesterdayforecastalert: forecastArray.includes(
                'Yesterday Forecast Alert'
            ),
        };

        if (selectedLocation && selectedLocation.length > 0) {
            userRequest = {
                ...userRequest,
                ...{ selectedlocations: selectedLocation },
            };
        }

        if (roleID === 'ADMIN') {
            userRequest = {
                ...userRequest,
                ...{ selectedlocations: [] },
            };
        }

        saveOrUpdateRequest({
            url: 'user/create',
            method: 'POST',
            data: userRequest,
        }).then((response: any) => {
            if (response && response.status === 200) {

            setOpen(false);
            setErrorMsg('');
            Alert('success', 'user created successfully');
            setFirstName('');
            setLastName('');
            setEmail('');
            setErrorMsg('');
            setLob('');
            setPassWord('');
            setPhoneNum('');
            setRoleID('');
            setStatus('');
            setUserType('');
            setLanguage('');
            setSubscriptionArray([]);
            setForecastArray([]);
            setSelectedLocation([]);
            props.userListData();
            }
            else {
                Alert('error', response.statusmessage);
                setDouble(false);
            }
        });
    
       
    };

    const handleOpenPopup = () => {
        setOpen(true);
        setDouble(false);
    };

    const handleClosePopup = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrorMsg('');
        setLob('');
        setPassWord('');
        setPhoneNum('');
        setRoleID('');
        setStatus('');
        setUserType('');
        setLanguage('');
        setSubscriptionArray([]);
        setForecastArray([]);
        setSelectedLocation([]);
        setOpen(false);
        setDouble(false);
    };

    const handleCreate = () => {
        let errorMessage = validateError();
        if (errorMessage === '') {
            createUserData();
            setDouble(true);
        } else {
            setErrorMsg(errorMessage);
        }
    };

    const handleFirstName = (event: any) => {
        setFirstName(event.target.value);
    };
    const handleLastName = (event: any) => {
        setLastName(event.target.value);
    };
    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    };
    const handlePhoneNum = (event: any) => {
        setPhoneNum(event.target.value);
    };
    const handlePassWord = (event: any) => {
        setPassWord(event.target.value);
    };
    const handleStatus = (event: any) => {
        setSelectedLocation([]);
        setStatus(event.target.value);
    };
    const handleRole = (event: any) => {
        setRoleID(event.target.value);
        setLocationKey(Math.random());
    };

    const handleUserType = (event: any) => {
        setUserType(event.target.value);
    };

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const validateError = () => {
        let error = [];
        let errorMessage = '';
        var mailReg = new RegExp(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        );
        /*
The combination means, email address must start with “_A-Za-z0-9-\+” ,
 optional follow by “.[_A-Za-z0-9-]“, and end with a “@” symbol.
  The email’s domain name must start with “A-Za-z0-9-”,
   follow by first level Tld (.com, .net) “.[A-Za-z0-9]” and
    optional follow by a second level Tld (.com.au, .com.my) “\.[A-Za-z]{2,}”,
     where second level Tld must start with a dot “.”
      and length must equal or more than 2 characters */

        if (firstName.length === 0) {
            error.push('First name is Required');
        }

        if (lastName.length === 0) {
            error.push('Last name is Required');
        }

        if (passWord.length === 0) {
            error.push('Password is Required');
        }

        if (!mailReg.test(email) || email.length === 0) {
            error.push('Invalid EmailID');
        }

        var reg = new RegExp('^[0-9]+$');

        if (loggedUser?.countrycode === 'IN') {
            if (!reg.test(phoneNum) || phoneNum.length !== 10) {
                error.push('Invalid phone number');
            }
        }

        if (loggedUser?.countrycode === 'TH') {
            if (!reg.test(phoneNum) || phoneNum.length !== 9) {
                error.push('Invalid phone number');
            }
        }

        if (userType === 'select') {
            error.push('Please Select a UserType');
        }

        if (language === 'select') {
            error.push('Please Select a Language');
        }
        if (roleID === 'select') {
            error.push('Please Select a RoleID');
        }
        if (status === 'select') {
            error.push('Please Select a Status');
        }
        if (lob === 'select') {
            error.push('Please Select a LOB');
        }

        if (roleID !== 'ADMIN' && selectedLocation.length === 0) {
            
            error.push('Please select a Location');
        }

        if (subscriptionArray.length === 0) {
            error.push('Please select Channel Mode');
        }

        if (forecastArray.length === 0) {
            error.push('Please select Forecast Report');
        }

        error.forEach((err: string) => {
            errorMessage += err + ',';
        });

        return errorMessage;
    };

    function getLocationDropDown(roleId: String) {
        if (
            roleId !== 'select' &&
            roleId !== '' &&
            (roleId === 'TM_PL' || roleId === 'TM_SAM' || roleID === 'TL')
        ) {
            return (
                <div>
                    <div className='usermgt-headerfont'>State</div>
                    <div>
                        <Autocomplete
                            className='usermgmt-user-type-width'
                            multiple
                            id='checkboxes-tags-demo'
                            key={locationKey}
                            options={props.locationArray}
                            disableCloseOnSelect
                            getOptionLabel={(location) => location.locationDesc}
                            onChange={(
                                event: any,
                                value: any,
                                reason: string
                            ) => {
                                let selectedLocationTemp: any[] = [];
                                value.forEach((element: any) => {
                                    selectedLocationTemp.push(
                                        element.locationCode
                                    );
                                });
                                setSelectedLocation(selectedLocationTemp);
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{
                                            marginRight: 8,
                                        }}
                                        checked={selected}
                                    />
                                    {option.locationDesc}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </div>
            );
        } else if (
            roleId !== 'select' &&
            roleId !== '' &&
            roleId !== 'TM_PL' &&
            roleId !== 'TM_SAM' &&
            roleID !== 'TL' &&
            roleId !== 'ADMIN'
        ) {
            return (
                <div>
                    {loggedUser?.countrycode === 'IN' ? (
                        <div>
                            <div className='usermgt-headerfont'>Village</div>
                            <div>
                                <Autocomplete
                                    className='usermgmt-user-type-width'
                                    multiple
                                    id='checkboxes-tags-demo'
                                    options={props.villageArray}
                                    disableCloseOnSelect
                                    onChange={(
                                        event: any,
                                        value: any,
                                        reason: string
                                    ) => {
                                        let selectedLocationTemp: any[] = [];
                                        value.forEach((element: any) => {
                                            selectedLocationTemp.push(
                                                element.locationCode
                                            );
                                        });
                                        setSelectedLocation(
                                            selectedLocationTemp
                                        );
                                    }}
                                    getOptionLabel={(village) =>
                                        village.locationDesc
                                    }
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{
                                                    marginRight: 8,
                                                }}
                                                checked={selected}
                                            />
                                            {option.locationDesc}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='usermgt-headerfont'>
                                Sub District
                            </div>
                            <div>
                                <Autocomplete
                                    className='usermgmt-user-type-width'
                                    multiple
                                    id='checkboxes-tags-demo'
                                    options={props.subDistrictArray}
                                    disableCloseOnSelect
                                    onChange={(
                                        event: any,
                                        value: any,
                                        reason: string
                                    ) => {
                                        let selectedLocationTemp: any[] = [];
                                        value.forEach((element: any) => {
                                            selectedLocationTemp.push(
                                                element.locationCode
                                            );
                                        });
                                        setSelectedLocation(
                                            selectedLocationTemp
                                        );
                                    }}
                                    getOptionLabel={(sub) => sub.locationDesc}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{
                                                    marginRight: 8,
                                                }}
                                                checked={selected}
                                            />
                                            {option.locationDesc}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        return <div></div>;
    }

    const handleReset = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrorMsg('');
        setLob('');
        setPassWord('');
        setPhoneNum('');
        setRoleID('');
        setStatus('');
        setUserType('');
        setLanguage('');
        setSubscriptionArray([]);
        setForecastArray([]);
    };

    const handleSubscriptionChange = (
        event: SelectChangeEvent<typeof subscriptionArray>
    ) => {
        
        const {
            target: { value },
        } = event;
        setSubscriptionArray(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleForecastChange = (
        event: SelectChangeEvent<typeof forecastArray>
    ) => {
        const {
            target: { value },
        } = event;
        setForecastArray(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div className='usermgt-createnew'>
            <div className='modal-advisory-button'>
                <Button
                    className='advisory-new-button'
                    onClick={handleOpenPopup}>
                    <PlusIcon className='plus-advisory-icon' width={15} />
                    &nbsp;&nbsp;Create User
                </Button>

                <Modal open={open}>
                    <Box sx={style}>
                        <div className='closeheading-usermgt'>
                            <div>Create User</div>
                            <div>
                                <CloseIcon
                                    className='advisory_close_icon'
                                    onClick={handleClosePopup}
                                />
                            </div>
                        </div>
                        <div>
                            <span
                                style={{
                                    color: 'red',
                                    fontFamily: 'appRegular',
                                    fontSize: 13,
                                    paddingBottom: 4,
                                }}>
                                {errorMsg}
                            </span>
                        </div>

                        <Accordion>
                            <div className='accordian-usermgt'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography sx={{ color: '#10384f' }}>
                                        User Details
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            First Name
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleFirstName}
                                                value={firstName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Last Name
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleLastName}
                                                value={lastName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Email
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleEmail}
                                                value={email}
                                            />
                                        </div>
                                    </div>
                                </Stack>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4, lg: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Phone Number
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handlePhoneNum}
                                                value={phoneNum}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Password
                                        </div>
                                        <div>
                                            <FormControl
                                                className='create-usermgt-pass'
                                                sx={{
                                                    m: 1,
                                                    margin: 0,
                                                }}
                                                variant='outlined'>
                                                <OutlinedInput
                                                    id='outlined-adornment-password'
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    onChange={handlePassWord}
                                                    value={passWord}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge='end'>
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            User Type
                                        </div>
                                        <Select
                                            className='usermgmt-user-type-width'
                                            value={userType}
                                            onChange={handleUserType}>
                                            <MenuItem
                                                className={classes.menu}
                                                value='select'
                                                disabled>
                                                <span className='select'>
                                                    Select a UserType
                                                </span>
                                            </MenuItem>
                                            {props.userTypeArray &&
                                                props.userTypeArray.map(
                                                    (usertype: any) => (
                                                        <MenuItem
                                                            className={
                                                                classes.menu
                                                            }
                                                            value={usertype}>
                                                            {usertype}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                </Stack>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    <FormControl
                                        className='usermgt-channelmode'
                                        sx={{
                                            m: 1,
                                            height: 66,
                                            margin: 0,
                                        }}>
                                        <div className='usermgt-headerfont'>
                                            Channel Mode
                                        </div>

                                        <Select
                                            className='channel-user'
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            value={subscriptionArray}
                                            onChange={handleSubscriptionChange}
                                            input={
                                                <OutlinedInput label='Tag' />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    ? selected.join(',')
                                                    : ''
                                            }
                                            MenuProps={MenuProps}>
                                            {subscriptionarray.map((sub) => (
                                                <MenuItem
                                                    key={sub}
                                                    value={sub}
                                                    className={
                                                        classes.checkbox
                                                    }>
                                                    <Checkbox
                                                        checked={
                                                            subscriptionArray.indexOf(
                                                                sub
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        primary={sub}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl
                                        className='usermgt-channelmode'
                                        sx={{
                                            m: 1,
                                            height: 66,
                                            margin: 0,
                                        }}>
                                        <div className='usermgt-headerfont'>
                                            Forecast Report
                                        </div>

                                        <Select
                                            className='channel-user'
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            value={forecastArray}
                                            onChange={handleForecastChange}
                                            input={
                                                <OutlinedInput label='Tag' />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    ? selected.join(',')
                                                    : ''
                                            }
                                            MenuProps={MenuProps}>
                                            {forecastarray.map((forecast) => (
                                                <MenuItem
                                                    className={classes.checkbox}
                                                    key={forecast}
                                                    value={forecast}>
                                                    <Checkbox
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        checked={
                                                            forecastArray.indexOf(
                                                                forecast
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        className={classes.list}
                                                        primary={forecast}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Preferred Language
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={language}
                                                onChange={handleLanguageChange}>
                                                <MenuItem
                                                    className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Language
                                                    </span>
                                                </MenuItem>
                                                {props.languageArray &&
                                                    props.languageArray.map(
                                                        (language: any) => (
                                                            <MenuItem
                                                                className={
                                                                    classes.menu
                                                                }
                                                                value={
                                                                    language
                                                                }>
                                                                {language}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <div className='accordian-usermgt'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography sx={{ color: '#10384f' }}>
                                        Role Details
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Status
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={status}
                                                onChange={handleStatus}>
                                                <MenuItem
                                                    className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Status
                                                    </span>
                                                </MenuItem>
                                                {props.statusArray &&
                                                    props.statusArray.map(
                                                        (userstatus: any) => (
                                                            <MenuItem
                                                                className={
                                                                    classes.menu
                                                                }
                                                                value={
                                                                    userstatus
                                                                }>
                                                                {userstatus}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Business Unit
                                        </div>
                                        <Select
                                            value={lob}
                                            onChange={(event: any) => {
                                                setLob(event.target.value);
                                                setRoleIDArray(
                                                    props.valueMap?.get(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                            className='usermgmt-user-type-width'>
                                            <MenuItem
                                                value='select'
                                                disabled
                                                className={classes.menu}>
                                                <span className='select'>
                                                    Select a BU
                                                </span>
                                            </MenuItem>

                                            <MenuItem
                                                className={classes.menu}
                                                value={
                                                    loggedUser?.lineofbusiness
                                                }>
                                                {loggedUser?.lineofbusiness}
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Role
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={roleID}
                                                onChange={handleRole}>
                                                <MenuItem
                                                    className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Role
                                                    </span>
                                                </MenuItem>
                                                {roleIDArray &&
                                                    roleIDArray.map(
                                                        (role: any) => (
                                                            <MenuItem
                                                                className={
                                                                    classes.menu
                                                                }
                                                                value={
                                                                    role.rolelabel
                                                                }>
                                                                {
                                                                    role.roledescription
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ overflowX: 'auto' }}>
                            <div className='accordian-usermgt'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography sx={{ color: '#10384f' }}>
                                        user GeoLocation Details
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    {getLocationDropDown(roleID)}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <div className='button-usermgt'>
                            <Button
                                className={classes.reset}
                                variant='outlined'
                                onClick={handleReset}>
                                Reset
                            </Button>
                            <Button
                                className={classes.save}
                                disabled={double}
                                variant='contained'
                                color='success'
                                onClick={handleCreate}>
                                Save
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default CreateNewUser;
