/** @format */

import React, { ChangeEvent, useEffect, useState } from 'react';
import TopHeader from '../../components/layouts/TopHeader';
import Filter from '../../components/ui/Filter';
import { useLocation } from 'react-router-dom';
import { getRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Box, Tab, Table, Tabs } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import './EditUserDetails';
import EditUserDetails from './EditUserDetails';
import Footer from '../../components/layouts/Footer';
import Pagination from '../../components/ui/Pagination';
import { assignedNull, optionList, tableParams } from '../../utils/helper';
import CreateNewUser from './createNewUser';
import { CustomButton } from '../../components/form/CustomButton';
import '../../assets/scss/filter.scss';
import { Select, MenuItem, Button } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { downloadUserManagement, downloadSuperAdmin } from '../../utils/helper';
import CreateNewSuperAdmin from './createNewSuperAdmin';
import EditSuperAdminDetails from './EditSuperAdminDetails';
import Loader from '../../components/ui/Loader';
import { CircularProgress } from '@mui/material';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #919191',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #919191',
            padding: '10px 26px 10px 12px',
        },
    },
}));

function UserManagement() {
    let closeToggle: any;

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const styles = makeStyles((theme) =>
        createStyles({
            paper: {
                borderLeftStyle: 'solid',
                borderLeftColor: '#89D329',
                borderLeftWidth: 5,
                '&:hover': {
                    backgroundColor: 'lightgray !important',
                },
            },
            paper2: {
                borderLeftStyle: 'solid',
                borderLeftColor: '#ff9d00',
                borderLeftWidth: 5,
                '&:hover': {
                    backgroundColor: 'lightgray !important',
                },
            },
            table: {
                minWidth: 600,
                width: '99%',
                borderCollapse: 'separate',
                borderSpacing: '0 1em',
            },
            resize: {
                fontSize: 14,
                fontFamily: 'appRegular',
            },

            resizeChannel: {
                fontSize: 14,
                fontFamily: 'appRegular',
                width: '11rem',
                height: 20,
            },
            thead: {
                backgroundColor: 'lightgray',
                '& th:first-child': {
                    borderRadius: '0.5em 0 0 0.5em',
                },
                '& th:last-child': {
                    borderRadius: '0 0.5em 0.5em 0',
                },
            },
        })
    );

    const classes = styles();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableErrorCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: 'none',
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const StyledTableErrorRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'none',
        },
    }));

    const [lobArray, setLobArray] = useState<any[]>([]);
    const [statusArray, setstatusArray] = useState<any[]>([]);
    const [userTypeArray, setUserTypeArray] = useState<any[]>([]);
    const [languageArray, setlanguageArray] = useState<any[]>([]);
    const [valueMap, setValueMap] = useState<Map<any, any>>();
    const [lobRoles, setLobRoles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [roleError, setRoleError] = useState<boolean>(false);
    const [lobMap, setLobMap] = useState<Map<any, any>>();
    const [langMap, setLangMap] = useState<Map<any, any>>();
    const [countryMap, setCountryMap] = useState<Map<any, any>>();
    const [lobError, setLobError] = useState<any>(false);
    const [countryAdminArray, setCountryAdminArray] = useState<any[]>([]);
    const [roleAdminArray, setRoleAdminArray] = useState<any[]>([]);

    const [errorMsgAdmin, setErrorMsgAdmin] = useState<string>('');

    var searchCriteria = useLocation().search;

    var urlSearchParams = new URLSearchParams(searchCriteria);

    const [isFiltered, setIsFiltered] = useState<boolean>(
        // urlSearchParams.get('isfiltered')
        //     ? () => {
        //           if (urlSearchParams.get('isfiltered') === 'true') {
        //               return true;
        //           } else {
        //               return false;
        //           }
        //       }
        //     :
        loggedUser?.roleid === 'SUPER_ADMIN' ? false : true
    );

    const [userDetails, setUserDetails] = useState<any[]>([]);

    const [userRoleSelected, setUserRoleSelected] = useState<any>('ALL');
    const [lobSelected, setLobSelected] = useState<any>('ALL');
    const [editUserDetailsModal, setEditUserDetailsModal] = useState(false);

    const [fetchData, setFetchData] = useState<boolean>(false);
    const [dataPerPage, setDataPerPage] = useState<any[]>([]);
    const [selectPageOne, setSelectPageOne] = useState<boolean>(true);
    const [params, setParams] = useState(tableParams());
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [isClosed, setIsClosed] = useState<any>(false);
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? true : false
    );
    const [locationArray, setLocationArray] = useState<any[]>([]);
    const [villageArray, setVillageArray] = useState<any[]>([]);
    const [subDistrictArray, setSubDistrictArray] = useState<any[]>([]);
    const [adminCountry, setAdminCountry] = useState<any[]>([]);
    const [lobList, setlobList] = useState<any>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [event, setEvent] = useState<any>();
    const [selectedRole, setSelectedRole] = useState<any>('ALL');
    const [selectedLob, setSelectedLob] = useState<any>('ALL');
    const [applyflag, setapplyflag] = useState<any>(false);
    const [selectedUser, setSelectedUser] = useState<any>();
    const [statusBarValue, setStatusBarValue] = useState<number>(() => {
        let ordinal = 0;
        let role = 'SUPER_ADMIN';
        if (role === 'SUPER_ADMIN') {
            ordinal = 0;
        } else if (role === 'USER_LIST') {
            ordinal = 1;
        }
        return ordinal;
    });
    const [filter, setFilter] = useState<any>({
        lob:
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser?.lineofbusiness,
        country: loggedUser?.countrycode,
    });

    const [countryList, setCountryList] = useState<any>([
        // {label: 'IN', value: 'IN'},
        // {label: 'TH', value: 'TH'},
    ]);
    const [lobval, setLobval] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser?.lineofbusiness
    );
    const [countryval, setCountryval] = useState<any>(loggedUser?.countrycode);
    const [countryLoader, setCountryLoader] = useState<any>(false);

    useEffect(() => {
        if (loggedUser?.roleid === 'ADMIN') {
            userListData();
            userMasterData();
            getStateData();
            getVillageData();
        } else if (loggedUser?.roleid === 'SUPER_ADMIN') {
            AdminListData();
            userMasterData();
            userMasterCountry();
            getLookup();
             getStateData();
            getVillageData();
        }
    }, []);

    useEffect(() => {
        getStateData();
        getVillageData();
    }, [filter.country]);

    useEffect(() => {
        if (fetchData) {
            if (loggedUser?.roleid === 'ADMIN') {
                userListData();
                setFetchData(false);
            } else if (
                loggedUser?.roleid === 'SUPER_ADMIN' &&
                statusBarValue === 0
            ) {
                AdminListData();
                setFetchData(false);
                setParams(tableParams());
            } else if (
                loggedUser?.roleid === 'SUPER_ADMIN' &&
                statusBarValue === 1
            ) {
                userListData();
                setFetchData(false);
                setParams(tableParams());
            }
        }
    }, [fetchData]);

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const handleStatusBarChange = (
        event: React.SyntheticEvent,
        ordinal: number
    ) => {
       

        if(ordinal === 1){
            userListData()
        }else{
            AdminListData()
        }
        
        setParams(tableParams());
        setSelectPageOne(true);
        setStatusBarValue(ordinal);
    };

    const getLookup = () => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                let countryDataOrg = response?.body?.data?.countries;

                if (countryDataOrg.length > 0) {
                    getLobLookup(loggedUser?.countrycode);
                    const filterCountry = optionList(
                        countryDataOrg,
                        'countrycode',
                        'country'
                    );
                    setCountryList([...countryList, ...filterCountry]);
                } else {
                    setCountryList([...countryList]);
                }
            }
        });
    };

    const getLobLookup = (country: any, apply?: any) => {
        let obj: any = {
            countrycode: country,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((resp: any) => {
            if (resp?.body?.data) {
                let lobDataOrg = resp?.body?.data?.lineofbusiness;
                if (lobDataOrg) {
                    if (lobDataOrg.length > 0) {
                        setLobError(false);
                        const filterlob = lobDataOrg.map((lob: any) => {
                            return {
                                label: lob,
                                value: lob,
                            };
                        });
                        setlobList([
                            { label: 'ALL', value: 'ALL' },
                            ...filterlob,
                        ]);

                        if (apply) {
                            setFilter({
                                ...filter,
                                lob: lobval,
                                country: countryval,
                            });
                        } else {
                            setFilter({
                                ...filter,
                                lob:
                                    loggedUser?.roleid === 'SUPER_ADMIN'
                                        ? 'ALL'
                                        : loggedUser?.lineofbusiness,
                                country: country,
                            });
                        }
                        setCountryLoader(false);
                    }
                } else {
                    setLobError(true);
                    setlobList([{ label: 'ALL', value: 'ALL' }]);
                    setCountryLoader(false);
                }
            }
        });
    };

    const getRoleLookup = (lob: any) => {
        let obj: any = {
            countrycode: filter.country,
        };
        getRequest({
            url: 'lookup/master/role',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                if (lob !== 'ALL') {
                    setRoleError(false);
                    let tempmap = new Map<any, any>();
                    response.body.data.lob.forEach((value: any) => {
                        tempmap.set(value.lineofbusiness, value.roles);
                    });
                    setValueMap(tempmap);
                    let role = tempmap?.get(lob);
                    let allArray = [
                        { rolelabel: 'ALL', roledescription: 'ALL' },
                    ];
                    setLobRoles([...allArray, ...role]);
                } else {
                    setRoleError(true);
                    setLobRoles([]);
                }
            }
        });
    };
    function userMasterData(country?:any) {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: country ? country : loggedUser?.countrycode,
        }

        loggedUser?.assignedcountries &&
            loggedUser?.assignedcountries.forEach((val: any) => {
                if (val.countrycode === loggedUser?.countrycode) {
                    let lobDataOrg = val.lineofbusiness;
                    if (lobDataOrg.length > 0) {
                        let lobFilter = lobDataOrg.map((lob: any) => {
                            return {
                                label: lob,
                                value: lob,
                            };
                        });
                        setlobList([...lobList, ...lobFilter]);
                    } else {
                        setlobList([...lobList]);
                    }
                }
            });

        getRequest({
            url: 'lookup/master/role',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setstatusArray(response.body.data.status);
                    setUserTypeArray(response.body.data.usertype);
                    setlanguageArray(response.body.data.languages);
                    if (filter.lob !== 'ALL') {
                        setRoleError(false);
                        let tempmap = new Map<any, any>();
                        response.body.data.lob.forEach((value: any) => {
                            tempmap.set(value.lineofbusiness, value.roles);
                        });
                        setValueMap(tempmap);
                        let role = tempmap?.get(filter.lob);
                        let allArray = [
                            { rolelabel: 'ALL', roledescription: 'ALL' },
                        ];
                        setLobRoles([...allArray, ...role]);
                    } else {
                        setRoleError(true);
                    }
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    }

    function getUserRoleAndLob(user: any) {
        let obj: any = {
            countrycode: user.countrycode,
        };

        getRequest({
            url: 'lookup/master/role',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setlanguageArray(response.body.data.languages);

                    setRoleError(false);
                    let tempLobArray: any = [];
                    let tempmap = new Map<any, any>();
                    response.body.data.lob.forEach((value: any) => {
                        tempmap.set(value.lineofbusiness, value.roles);
                        tempLobArray.push(value.lineofbusiness);
                    });
                    setValueMap(tempmap);
                    setLobArray(tempLobArray);

                    setSelectedUser(user);
                    setErrorMsgAdmin('');
                    setEditUserDetailsModal(true);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    }

    const getStateData = (country?:any,lobval?:any) => {

        let obj: any = {
            countrycode: country ? country : loggedUser?.countrycode,
            lob: lobval ? lobval : loggedUser?.lineofbusiness,
        };


        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let tempLocationMap = new Map();
                    response.body.data.forEach((location: any) => {
                        tempLocationMap.set(location.locationhierlevel1code, {
                            locationCode: location.locationhierlevel1code,
                            locationDesc: location.locationhierlevel1desc,
                        });
                    });
                    let tempLocationArray: any[] = [];
                    tempLocationMap.forEach((values, keys) => {
                        tempLocationArray.push(values);
                    });

                    setLocationArray(tempLocationArray);
                }
                else{
                    console.log('error','session expired')
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getVillageData = (country?:any) => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: country ? country : loggedUser?.countrycode,
        };

        getRequest({
            url: 'lookup/geomapping',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let tempVillageMap = new Map();
                    let tempVillageArray: any[] = [];
                    response.body.data.forEach((location: any) => {
                        tempVillageMap.set(location.locationhierlevel4code, {
                            locationCode: location.locationhierlevel4code,
                            locationDesc: location.locationhierlevel4desc,
                        });
                    });

                    let tempSubDistrictMap = new Map();
                    let tempSubDistrictArray: any[] = [];
                    response.body.data.forEach((location: any) => {
                        tempSubDistrictMap.set(
                            location.locationhierlevel3code,
                            {
                                locationCode: location.locationhierlevel3code,
                                locationDesc: location.locationhierlevel3desc,
                            }
                        );
                    });

                    tempVillageMap.forEach((values, keys) => {
                        tempVillageArray.push(values);
                    });

                    setVillageArray(tempVillageArray);

                    tempSubDistrictMap.forEach((values, keys) => {
                        tempSubDistrictArray.push(values);
                    });
                    setSubDistrictArray(tempSubDistrictArray);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const userListData = () => {
        let obj: any = {
            countrycode: filter.country,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            search: search || null,
            isfiltered: loggedUser?.roleid === 'SUPER_ADMIN' ? false : true,
            ...params,
        };

        if (isFiltered && userRoleSelected !== 'ALL') {
            obj = {
                ...obj,
                'role': userRoleSelected,
            };
        }

        if (isFiltered) {
            let condFilter = assignedNull(filter);
            obj = { ...obj, ...condFilter, ['isfiltered']: true };
        }

      
        setLoader(true);
        getRequest({
            url: 'user/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.data) {
                    setUserDetails(response?.body?.data?.data);
                    setTotalCount(response?.body?.data.totalcount);
                    setLoader(false);
                } else {
                    setErrorMsg('No data found for current User.');
                    setUserDetails([]);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                setUserDetails([]);
            });
    };

    const AdminListData = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: 'NA',
            loggeduserrole: loggedUser?.roleid,
            search: search || null,
            ...params,
        };
        setLoader(true);
        getRequest({
            url: 'superadmin/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setUserDetails(response?.body?.data);
                    setAdminCountry(response?.body?.data?.assignedcountries);
                    setTotalCount(response?.body?.totalcount);
                    setLoader(false);
                } else {
                    setErrorMsg('No data found for current User.');
                    setUserDetails([]);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                setUserDetails([]);
            });
    };

    const userMasterCountry = () => {
        getRequest({
            url: 'superadmin/lookup/master',
            method: 'GET',
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let lobArray = response.body.data?.lineofbusiness;
                    let tempLobMap = new Map();
                    lobArray.forEach((lob: any) =>
                        tempLobMap.set(lob.countrycode, lob.lobs)
                    );
                    setLobMap(tempLobMap);
                    let tempLanguageMap = new Map();
                    lobArray.forEach((lang: any) =>
                        tempLanguageMap.set(lang.countrycode, lang.languages)
                    );
                    setLangMap(tempLanguageMap);
                    let tempCountryMap = new Map();
                    let CountryArray = response.body.data.countries;
                    CountryArray.forEach((countries: any) => {
                        tempCountryMap.set(
                            countries.countrycode,
                            countries.country
                        );
                    });
                    setCountryMap(tempCountryMap);
                    setCountryAdminArray(response.body.data.countries);
                    setRoleAdminArray(response.body.data.role);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
        setFetchData(true);
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let searchtext = event.target.value;
        setSearch(searchtext);
        setRunHandleSearch(true);
    };

    const enterKeyHandler = (event: KeyboardEvent) => {
        setEvent(event);
        if (loggedUser?.roleid === 'ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }
        } else if (
            loggedUser?.roleid === 'SUPER_ADMIN' &&
            statusBarValue === 0
        ) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    AdminListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    AdminListData();
                }
            }
        } else if (
            loggedUser?.roleid === 'SUPER_ADMIN' &&
            statusBarValue === 1
        ) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }
        }
    };

    if (runHandleSearch) {
        if (loggedUser?.roleid === 'ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }

            setRunHandleSearch(false);
        } else if (
            loggedUser?.roleid === 'SUPER_ADMIN' &&
            statusBarValue === 0
        ) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    AdminListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    AdminListData();
                }
            }

            setRunHandleSearch(false);
        } else if (
            loggedUser?.roleid === 'SUPER_ADMIN' &&
            statusBarValue === 1
        ) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }

            setRunHandleSearch(false);
        }
    }

    const handleRoleChange = (roleid: any) => {
        setSelectedRole(roleid);
    };

    const handleLobChange = (lob: any) => {
        setSelectedLob(lob);
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            setSelectedRole('ALL');
            setFilter({
                lob: 'ALL',
                country: loggedUser?.countrycode
            })
            getLobLookup(loggedUser?.countrycode)
            setIsClosed(false);
            setLobError(false);
            getRoleLookup(loggedUser?.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser?.lineofbusiness)

        } else if (applyflag && isClosed) {
            getLobLookup(countryval, true);
            setSelectedRole(userRoleSelected);
            setFilter({
                lob: lobval,
                country: countryval,
            });
            setIsClosed(false);
            setLobError(false);
            getRoleLookup(lobval);
        }
    };
    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };
    const handleResetFilter = () => {
        setIsFiltered(loggedUser?.roleid === 'SUPER_ADMIN' ? false : true);
        setapplyflag(false);
        setParams(tableParams());
        closeToggle && closeToggle();
        setSelectPageOne(true);
        setBadgeEnabled(loggedUser?.roleid === 'SUPER_ADMIN' ? true : false);
        setSelectedRole('ALL');
        setSelectedLob('ALL');
        setUserRoleSelected('ALL');
        setFetchData(true);
        setLobError(false);
        setFilter({
            lob: loggedUser?.roleid === 'SUPER_ADMIN' ? 
                'ALL' : loggedUser?.lineofbusiness,
            country: loggedUser?.countrycode
        })
        userMasterData(loggedUser?.countrycode)
        getStateData(loggedUser?.countrycode, loggedUser?.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser.lineofbusiness)
        getVillageData(loggedUser?.countrycode)
        getLobLookup(loggedUser?.countrycode)
        getRoleLookup(loggedUser?.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser?.lineofbusiness)


        
    };
    const handleApplyFilter = (event: any) => {
        event.preventDefault();
        // if (selectedRole !== 'ALL' || selectedLob !== 'ALL') {
            setapplyflag(true);
            userMasterData(filter.country)
            getStateData(filter.country, filter.lob)
            getVillageData(filter.country)
            setParams(tableParams());
            setSelectPageOne(true);
            setIsFiltered(true);
            closeToggle && closeToggle();
            setBadgeEnabled(true);
            // setSelectedRole(selectedRole)
            setUserRoleSelected(selectedRole);
            setLobval(filter.lob)
            setCountryval(filter.country)
            setFetchData(true);
        // } else {
        //     setParams(tableParams());
        //     setSelectPageOne(true);
        //     setIsFiltered(true);
        //     closeToggle && closeToggle();
        //     setFetchData(true);
        //     setIsFiltered(false);
        // }
    };

    return (
        <div>
            <TopHeader>
                <h3>USER MANAGEMENT</h3>
            </TopHeader>
            <div>
                <div className='usermgt-filter'>
                    <Filter
                        isDownloadIcon
                        isFilterIcon={
                            loggedUser?.roleid === 'ADMIN' ||
                            (loggedUser?.roleid === 'SUPER_ADMIN' &&
                                statusBarValue === 1)
                                ? true
                                : false
                        }
                        placeHolder='Search Name (min 3 letters)'
                        searchOption1='First Name'
                        searchOption2='Last Name'
                        handleSearch={handleSearch}
                        searchText={search}
                        onKeyDown={enterKeyHandler}
                        onClose={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        isBadgeEnabled={badgeEnabled}
                        handleOnClickDownload={() =>
                            loggedUser?.roleid === 'ADMIN'
                                ? downloadUserManagement(
                                      search,
                                      isFiltered,
                                      'user-management',
                                      userRoleSelected
                                  )
                                : loggedUser?.roleid === 'SUPER_ADMIN' &&
                                  statusBarValue === 0
                                ? downloadSuperAdmin(search, 'SUPER_ADMIN')
                                : downloadUserManagement(
                                      search,
                                      isFiltered,
                                      'user-management',
                                      userRoleSelected,
                                      filter
                                  )
                        }>
                        {countryLoader ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                {loggedUser?.roleid === 'SUPER_ADMIN' &&
                                    statusBarValue === 1 && (
                                        <div className='row'>
                                            <div className='user_lob_topdiv'>
                                                <div>
                                                    <div className='titleLabel'>
                                                        Country
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingTop: '5px',
                                                            padddingBottom:
                                                                '5px',
                                                        }}>
                                                        <Select
                                                            className='usermgmt_dropdown'
                                                            // variant='standard'
                                                            defaultValue={
                                                                filter.country
                                                            }
                                                            // style={customStyles}
                                                            size='small'
                                                            onChange={(
                                                                evt: any
                                                            ) => {
                                                                evt.preventDefault();
                                                                setFilter({
                                                                    country: evt.target.value,
                                                                    lob: 'ALL'
                                                                })
                                                                setCountryLoader(
                                                                    true
                                                                );
                                                                getLobLookup(
                                                                    evt.target
                                                                        .value
                                                                );
                                                                getRoleLookup(
                                                                    'ALL'
                                                                );
                                                                setSelectedRole('ALL');
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        '& .MuiMenuItem-root.Mui-selected': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                        '& .MuiMenuItem-root:hover': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                        '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                    },
                                                                },
                                                            }}>
                                                            {countryList &&
                                                                countryList.map(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.value !==
                                                                        filter?.country ? (
                                                                            <MenuItem
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        ) : (
                                                                            <MenuItem
                                                                                disabled
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                )}
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='titleLabel'>
                                                         Business Unit
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingTop: '5px',
                                                            padddingBottom:
                                                                '5px',
                                                        }}>
                                                        <Select
                                                            className='usermgmt_dropdown'
                                                            // variant='standard'
                                                            defaultValue={
                                                                filter.lob
                                                                    ? filter.lob
                                                                    : 'ALL'
                                                            }
                                                            // style={customStyles}
                                                            size='small'
                                                            onChange={(
                                                                evt: any
                                                            ) => {
                                                                evt.preventDefault();
                                                                handleFilter(
                                                                    evt.target
                                                                        .value,
                                                                    'lob'
                                                                );
                                                 
                                                                getRoleLookup(
                                                                    evt.target
                                                                        .value
                                                                );

                                                                setSelectedRole('ALL');
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        '& .MuiMenuItem-root.Mui-selected': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                        '& .MuiMenuItem-root:hover': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                        '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                            backgroundColor:
                                                                                '#007aff',
                                                                            color:
                                                                                'white',
                                                                        },
                                                                    },
                                                                },
                                                            }}>
                                                            {lobList &&
                                                                lobList.map(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.label !==
                                                                        filter?.lob ? (
                                                                            <MenuItem
                                                                                value={
                                                                                    option.label
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        ) : (
                                                                            <MenuItem
                                                                                disabled
                                                                                value={
                                                                                    option.label
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                )}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            {lobError && (
                                                <div
                                                    style={{
                                                        paddingBottom: '3px',
                                                        textAlign: 'right',
                                                    }}>
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '10px',
                                                            fontFamily:
                                                                'appRegular',
                                                        }}>
                                                        No LOB available for
                                                        selected country
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                <div
                                    className='row'
                                    style={{ paddingBottom: '20px' }}>
                                    <div className='title_label'>Roles</div>
                                    <Select
                                        className='usermgmt_dropdown'
                                        value={selectedRole}
                                        onChange={(e: any) =>
                                            handleRoleChange(e.target.value)
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    '& .MuiMenuItem-root.Mui-selected': {
                                                        backgroundColor:
                                                            '#007aff',
                                                        color: 'white',
                                                    },
                                                    '& .MuiMenuItem-root:hover': {
                                                        backgroundColor:
                                                            '#007aff',
                                                        color: 'white',
                                                    },
                                                    '& .MuiMenuItem-root.Mui-selected:hover': {
                                                        backgroundColor:
                                                            '#007aff',
                                                        color: 'white',
                                                    },
                                                },
                                            },
                                        }}>
                                        {lobRoles &&
                                            lobRoles.map((role: any) => (
                                                <MenuItem
                                                    className='usermgt_select_dropdown'
                                                    value={role.rolelabel}>
                                                    {role.roledescription}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {roleError && (
                                        <div
                                            style={{
                                                paddingTop: '6px',
                                            }}>
                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: '11px',
                                                    fontFamily: 'appRegular',
                                                }}>
                                                Please select BU to filter with Role
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className='row filterFooter'>
                                    <CustomButton
                                        label={'Reset'}
                                        style={{
                                            width: '200px',
                                            height: '35px',
                                        }}
                                        className='cus-btn-filter reset'
                                        handleClick={handleResetFilter}
                                    />
                                    <CustomButton
                                        label={'Apply'}
                                        style={{
                                            borderRadius: '20px',
                                            backgroundColor: '#7eb343',
                                            width: '200px',
                                            height: '35px',
                                            border: 0,
                                            boxShadow: '0px 3px 6px #c7c7c729',
                                        }}
                                        className='cus-btn'
                                        handleClick={handleApplyFilter}
                                    />
                                </div>
                            </>
                        )}
                    </Filter>
                </div>
                <div
                    className={
                        loggedUser?.roleid === 'SUPER_ADMIN'
                            ? 'status-bar-usermgt'
                            : 'status_bar_usermgt_admin'
                    }>
                    {loggedUser?.roleid === 'SUPER_ADMIN' && (
                        <div className='top-usermgt-div'>
                            <Box>
                                <Tabs
                                    onChange={handleStatusBarChange}
                                    value={statusBarValue}
                                    variant='scrollable'
                                    scrollButtons='auto'
                                    aria-label='Tabs where each tab needs to be selected manually'>
                                    <Tab
                                        label='SUPER ADMIN'
                                    />
                                    <Tab
                                        label='USER LIST'
                                    />
                                </Tabs>
                            </Box>
                        </div>
                    )}
                    <div className='createnew_btn'>
                        {loggedUser?.roleid === 'ADMIN' ? (
                            <CreateNewUser
                                classes={classes}
                                lobArray={lobArray}
                                statusArray={statusArray}
                                userTypeArray={userTypeArray}
                                valueMap={valueMap}
                                locationArray={locationArray}
                                villageArray={villageArray}
                                userListData={userListData}
                                userMasterCountry={userMasterCountry}
                                languageArray={languageArray}
                                subDistrictArray={subDistrictArray}
                            />
                        ) : loggedUser?.roleid === 'SUPER_ADMIN' ? (
                            <CreateNewSuperAdmin
                                classes={classes}
                                userTypeArray={userTypeArray}
                                languageArray={languageArray}
                                statusArray={statusArray}
                                userMasterData={userMasterData}
                                countryAdminArray={countryAdminArray}
                                lobMap={lobMap}
                                langMap={langMap}
                                countryMap={countryMap}
                                roleAdminArray={roleAdminArray}
                                AdminListData={AdminListData}
                                setStatusBarValue={setStatusBarValue}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            <div className='usermgt-middlecontainer'>
                {loader ? <Loader /> : null}
                <Table className={classes.table} aria-label='simple table'>
                    <TableHead
                        classes={{ root: classes.thead }}
                        sx={{
                            backgroundColor: '#d6d6d6',
                            fontWeight: 900,
                            fontFamily: 'bold',
                            borderRadius: 20,
                        }}>
                        <TableRow>
                            <StyledTableCell align='left'>
                                First Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Last Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Email
                            </StyledTableCell>
                            {loggedUser?.roleid === 'ADMIN' ? (
                                <StyledTableCell align='left'>
                                    Phone Num
                                </StyledTableCell>
                            ) : (
                                ''
                            )}

                            <StyledTableCell align='left'>
                                Role Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Status
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {userDetails.length ? (
                            userDetails.map((userDetail: any) => (
                                <StyledTableRow hover className={classes.paper}>
                                    <StyledTableCell
                                        align='left'
                                        scope='userDetail'
                                        className={
                                            userDetail.status === 'ACTIVE'
                                                ? classes.paper
                                                : classes.paper2
                                        }>
                                        {userDetail.firstname
                                            .charAt(0)
                                            .toUpperCase() +
                                            userDetail.firstname.slice(1)}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        {userDetail.lastname
                                            .charAt(0)
                                            .toUpperCase() +
                                            userDetail.lastname.slice(1)}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        {userDetail.email}
                                    </StyledTableCell>
                                    {loggedUser?.roleid === 'ADMIN' ? (
                                        <StyledTableCell align='left'>
                                            {userDetail.phonenumber}
                                        </StyledTableCell>
                                    ) : (
                                        ''
                                    )}

                                    <StyledTableCell align='left'>
                                        {userDetail.rolename}
                                    </StyledTableCell>

                                    <StyledTableCell align='left'>
                                        {userDetail.status === 'ACTIVE' ? (
                                            <div className='active'>
                                                {userDetail.status}
                                            </div>
                                        ) : (
                                            <div className='inactive'>
                                                {userDetail.status}
                                            </div>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        <Button
                                            className='edit-button-usermgt'
                                            onClick={() => {
                                                getUserRoleAndLob(userDetail);
                                            }}>
                                            <EditNoteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableErrorRow>
                                <StyledTableErrorCell
                                    align='left'
                                    scope='userDetail'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>

                                <StyledTableErrorCell align='left'>
                                    <div className='usermgt_error_msg'>
                                        {errorMsg}
                                    </div>
                                </StyledTableErrorCell>

                                <StyledTableErrorCell align='left'></StyledTableErrorCell>

                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                            </StyledTableErrorRow>
                        )}
                    </TableBody>
                </Table>
                {loggedUser?.roleid === 'ADMIN' ||
                (loggedUser?.roleid === 'SUPER_ADMIN' && statusBarValue === 1)
                    ? selectedUser && (
                          <EditUserDetails
                              userDetail={selectedUser}
                              StyledTableRow={StyledTableRow}
                              StyledTableCell={StyledTableCell}
                              classes={classes}
                              lobArray={lobArray}
                              statusArray={statusArray}
                              userTypeArray={userTypeArray}
                              languageArray={languageArray}
                              valueMap={valueMap}
                              locationArray={locationArray}
                              villageArray={villageArray}
                              userListData={userListData}
                              editUserDetailsModal={editUserDetailsModal}
                              setEditUserDetailsModal={setEditUserDetailsModal}
                              setErrorMsgAdmin={setErrorMsgAdmin}
                              errorMsgAdmin={errorMsgAdmin}
                              subDistrictArray={subDistrictArray}
                          />
                      )
                    : loggedUser?.roleid === 'SUPER_ADMIN'
                    ? selectedUser && (
                          <EditSuperAdminDetails
                              userDetail={selectedUser}
                              StyledTableRow={StyledTableRow}
                              StyledTableCell={StyledTableCell}
                              classes={classes}
                              statusArray={statusArray}
                              userTypeArray={userTypeArray}
                              roleAdminArray={roleAdminArray}
                              AdminListData={AdminListData}
                              editUserDetailsModal={editUserDetailsModal}
                              setEditUserDetailsModal={setEditUserDetailsModal}
                              countryAdminArray={countryAdminArray}
                              lobMap={lobMap}
                              countryMap={countryMap}
                              setErrorMsgAdmin={setErrorMsgAdmin}
                              errorMsgAdmin={
                                  errorMsgAdmin
                              }></EditSuperAdminDetails>
                      )
                    : ''}
            </div>
            <div className='footer_advisory'>
                {userDetails.length ? (
                    <Footer>
                        <Pagination
                            postsTitle='User(s)'
                            posts={userDetails}
                            onIndex={toPagi}
                            postsPerPage={10}
                            onIndexPageNo={pageNoSelected}
                            totalCountAvailable={totalCount}
                            selectPageOne={selectPageOne}
                        />
                    </Footer>
                ) : null}
            </div>
        </div>
    );
}

export default UserManagement;
