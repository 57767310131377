/** @format */
import React, { useState, useEffect } from 'react';
import {
    Grid,
    InputLabel,
    InputBase,
    styled,
    Divider,
    TextField,
    Button,
    MenuItem,
} from '@mui/material';
import Select from '@mui/material/Select';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Loader from '../../../components/ui/Loader';
import { getRequest } from '../../../services/apiUtils';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { Alert } from '../../../utils/toastr';
import { ReactComponent as RightNext } from '../../../assets/img/svg/country-config/right_next.svg';
//Redux
import { connect } from 'react-redux';
import './BasicInfo.scss';
import {
    setDefaultCropList,
    chooseCrop,
    chooseVariety,
    chooseYear,
    chooseSeason,
    chooseSeasonStartDate,
    chooseSeasonEndDate,
    reset,
    chooseCountry,
    chooseState,
    typecalendarname,
} from '../../../redux/actions/create-crop/createCrop';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '0.5px solid #919191',
        fontSize: 13,
        // color: '#1A1A1A',
        width: '170.67px',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: 'appRegular',
    },
}));

const BasicInfo = (props: any) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [stateList, setStateList] = useState<any[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [Country, setCountry] = useState<any[]>([]);

    const [variety, setVariety] = useState<any>();
    const [season, setSeason] = useState<any>();
    const [yearToggler, setYearToggler] = useState(false);
    const [minDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(2, 'year');
        return currentDate;
    });
    const [maxDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        return dayjs().add(2, 'year');
    });

    const date = new Date();
    const currentYear = date.getFullYear();
    const yearList: number[] = [currentYear, currentYear + 1];

    useEffect(() => {
        getCountryList();
        if (!props.backButtonClicked) {
            getLookup();
            if (props.countryDetails.countryCode !== '') {
                getStateInfo(props.countryDetails.countryCode);
            }

            if (props.selectedCropObj.cropId !== 'select') {
                getVariety(props.selectedCropObj.cropId);
            }
        }
    }, []);

    const getLookup = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggeduser = JSON.parse(lsData);
        let obj: any = {
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lineofbusiness,
        };
        setLoader(true);
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    props.setDefaultCropList(response?.body?.data.croplist);
                    setSeason(response?.body?.data?.seasonlist);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                setLoader(false);
            });
    };

    const handleFromDate = (newValue: Dayjs | null) => {

        props.chooseSeasonStartDate(newValue);
    };

    const handleToDate = (newValue: Dayjs | null) => {
        props.chooseSeasonEndDate(newValue);
    };

    const handlePageNext = () => {

        const selectedValues: string[] = props.allGeoLevels.map(
            (level: any) => level.selectedValue
        );
        let notSelected = false;
        for (let i = 0; i < selectedValues.length; i++) {
            selectedValues[i] === '' && (notSelected = true);
        }

        if (
            props.seasonStartDate === '' ||
            props.seasonEndDate === '' ||
            props.year === '' ||
            props.season === 'select' ||
            notSelected ||
            props.selectedCropObj.cropId === '' ||
            props.variety === 'select' 
        ) {
            setError(true);
        } else {
            props.handleGoToNextPage(1);
        }
    };

    function getVariety(cropID: any) {
        const lsData: any = getLocalStorageData('userData');
        const loggedUser = JSON.parse(lsData);

        let queryVariety: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            cropid: cropID,
        };

        getRequest({
            url: 'cropcalendar/lookup/master',
            method: 'GET',
            queryParams: queryVariety,
        })
            .then((response: any) => {
                setVariety(response.body.data.cropvariety);
            })
            .catch((error: any) => {
                Alert('error', 'Error occured in getting variety');
            });
    }

    function getCountryList() {
        const lsData: any = getLocalStorageData('userData');
        const loggedUser = JSON.parse(lsData);
        getRequest({
            url: 'lookup/country/config',
            method: 'GET',
        })
            .then((response: any) => {
                let tempCountryArray: any[] = [];
                let countries = response.body.data.countries;
                countries.forEach((country: any) => {
                    if (loggedUser?.countrycode === country.countrycode) {
                        tempCountryArray.push(country);
                    }
                });
                setCountry(tempCountryArray);
            })
            .catch((error: any) => {
                Alert('error', 'Error occured in getting country');
            });
    }

    function getStateInfo(country: any) {
        const lsData: any = getLocalStorageData('userData');
        const loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: country,
            lob: loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    let tempStateMap = new Map();
                    response.body.data.forEach((state: any) => {
                        tempStateMap.set(
                            state.locationhierlevel1code,
                            state.locationhierlevel1desc
                        );
                    });
                    let tempStateArray: any[] = [];
                    tempStateMap.forEach((key: any, value: any) => {
                        tempStateArray.push({
                            name: key,
                            code: value,
                        });
                    });
                    setStateList(tempStateArray);
                }
                else{
                    console.log('error','session expired')
                }
            })
            .catch((error: any) => {
                Alert('error', 'Error occured in getting geo location');
            });
    }

    const handleCropName = (event: any) => {
        let cropObj = props.defaultCropList.find(
            (crop: any) => crop.cropid === event.target.value
        );
        getVariety(event.target.value);
        props.chooseCrop({
            cropid: cropObj.cropid,
            cropname: cropObj.cropname,
        });
    };

    const handleVariety = (event: any) => {
        props.chooseVariety(event.target.value);
    };

    const handleSeason = (event: any) => {
        props.chooseSeason(event.target.value);
    };

    const handleCalendarName = (event: any) => {
        props.typecalendarname(event.target.value);
    };

    return (
        <div>
            {loader && <Loader />}

            <Grid container spacing={3} sx={{ paddingBottom: 3 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <React.Fragment>
                        <InputLabel className='label-header'>
                            Calendar Name
                        </InputLabel>
                        <BootstrapInput
                            value={props.calendarname}
                            placeholder={`Please Enter Calendar Name`}
                            onChange={(event) => {
                                handleCalendarName(event);
                            }}
                            type='string'
                        />
                    </React.Fragment>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <div className='createcropcreate-width'>
                        <div className='label-header'>Crop</div>
                        <Select
                            className='crop-createcrop-div'
                            value={props.selectedCropObj.cropId}
                            onChange={(event) => {
                                handleCropName(event);
                            }}>
                            <MenuItem
                                value='select'
                                className='select-create'
                                disabled>
                                <span className='select'>Select a Crop</span>
                            </MenuItem>
                            {props.defaultCropList.map((each: any) => (
                                <MenuItem
                                    value={each.cropid}
                                    className='select-createcrop'>
                                    {each.cropname.charAt(0).toUpperCase() +
                                        each.cropname.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <div className='createcropcreate-width'>
                        <div className='label-header'>Variety</div>
                        <Select
                            className='crop-createcrop-div'
                            value={props.variety}
                            onChange={(event) => {
                                handleVariety(event);
                            }}>
                            <MenuItem
                                value='select'
                                className='select-create'
                                disabled>
                                <span className='select'>Select a Variety</span>
                            </MenuItem>
                            {variety &&
                                variety.map((each: any) => (
                                    <MenuItem
                                        value={each.variety}
                                        className='select-createcrop'>
                                        {each.variety}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <div
                        style={{
                            color: '#696969',
                            fontSize: '12px',
                            fontFamily: 'appRegular',
                            marginBottom: '4px',
                        }}>
                        Country
                    </div>

                    <Select
                        labelId='demo-simple-select-label'
                        className='createcrop-country-width'
                        id='demo-simple-select'
                        value={props.countryDetails.countryCode}
                        onChange={(event) => {
                            getStateInfo(event.target.value);
                            props.chooseCountry(event.target.value);
                        }}>
                        <MenuItem
                            value='select'
                            className='select-create'
                            disabled>
                            <span className='select'>Select a Country</span>
                        </MenuItem>

                        {Country &&
                            Country.map((country: any) => (
                                <MenuItem
                                    className='select-createcrop'
                                    value={country.countrycode}>
                                    {country.country}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <div
                        style={{
                            color: '#696969',
                            fontSize: '12px',
                            fontFamily: 'appRegular',
                            marginBottom: '4px',
                        }}>
                        State
                    </div>

                    <Select
                        labelId='demo-simple-state-label'
                        className='createcrop-country-width'
                        id='demo-simple-select'
                        value={props.geolevel1Details.geolevel1Code}
                        onChange={(event) => {
                            props.chooseState(event.target.value);
                        }}>
                        <MenuItem
                            value='select'
                            className='select-create'
                            disabled>
                            <span className='select'>Select a State</span>
                        </MenuItem>
                        {stateList &&
                            stateList.map((val: any) => {
                                return (
                                    <MenuItem
                                        className='select-createcrop'
                                        value={val.code}>
                                        {val.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <div className='createcropcreate-width'>
                        <div className='label-header'>Season</div>
                        <Select
                            className='crop-createcrop-div'
                            value={props.season}
                            onChange={(event) => {
                                handleSeason(event);
                            }}>
                            <MenuItem
                                value='select'
                                className='select-create'
                                disabled>
                                <span className='select'>Select a Season</span>
                            </MenuItem>
                            {season &&
                                season.map((each: any) => (
                                    <MenuItem
                                        value={each.season}
                                        className='select-createcrop'>
                                        {each.season}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <React.Fragment>
                        <InputLabel
                            className='label-header'
                            style={{ marginBottom: '4px' }}>
                            Year
                        </InputLabel>
                        <Select
                            onChange={(event) =>
                                props.chooseYear(event.target.value)
                            }
                            className='createcrop-country-width'
                            value={props.year}>
                            {yearList.map((eachYear: any) => (
                                <MenuItem key={eachYear} value={eachYear}>
                                    {' '}
                                    {eachYear}
                                </MenuItem>
                            ))}
                        </Select>
                    </React.Fragment>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <InputLabel className='label-header'>
                        Season Start Date
                    </InputLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ marginTop: '4px' }}>
                        <DatePicker
                            value={props.fromValue}
                            minDate={minDate}
                            // maxDate={toValue ? toValue : maxDate}
                            maxDate={
                                props.toValue !== null ? props.toValue : maxDate
                            }
                            onChange={handleFromDate}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    type='tel'
                                    title='From date'
                                    placeholder='mm/dd/yyyy'
                                    helperText={null}
                                    sx={{ width: '196.67px', marginTop: '4px' }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InputLabel className='label-header'>
                        Season End Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={props.toValue}
                            onChange={handleToDate}
                            minDate={
                                props.fromValue !== null
                                    ? props.fromValue
                                    : minDate
                            }
                            maxDate={maxDate}
                            views={['year', 'month', 'day']}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    type='tel'
                                    title='To date'
                                    placeholder='mm/dd/yyyy'
                                    helperText={null}
                                    // sx={{ width: 160 }}
                                    sx={{ width: '196.67px', marginTop: '4px' }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            {error && (
                <div className='compulsory'>* All fields are mandatory</div>
            )}
            <div className='buttonsContainer'>
                <span>
                    <Button
                        style={{
                            width: '181px',
                            height: '35px',
                        }}
                        onClick={() => {
                            setVariety([])
                            props.reset();
                        }}
                        className='roleDef_reset_btn'>
                        Reset
                    </Button>
                </span>

                <span>
                    <Button
                        onClick={handlePageNext}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: '181px',
                            height: '35px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Next
                    </Button>
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        defaultCropList: state.crop.defaultCropList,
        selectedCropObj: state.crop.selectedCrop,
        calendarname: state.crop.calendarname,
        variety: state.crop.variety,
        season: state.crop.season,
        year: state.crop.year,
        seasonStartDate: state.crop.seasonstartDate,
        seasonEndDate: state.crop.seasonEndDate,
        fromValue: state.crop.fromDate,
        toValue: state.crop.toDate,
        allGeoLevels: state.crop.allGeoLevels,
        countryDetails: state.crop.countryDetails,
        geolevel1Details: state.crop.geolevel1Details,
    };
};

const mapDispatchToProps = {
    setDefaultCropList: setDefaultCropList,
    chooseCrop: chooseCrop,
    chooseVariety,
    chooseSeason,
    chooseYear,
    chooseSeasonStartDate,
    chooseSeasonEndDate,
    reset,
    chooseCountry: chooseCountry,
    chooseState,
    typecalendarname: (cal: any) => typecalendarname(cal),
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
