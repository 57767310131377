/** @format */

import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import {
    Chip as MuiChip,
    ChipProps as MuiChipProps,
    MenuItem,
    Select,
} from '@mui/material';

import PestNotification from './pest_notification';
import './index.scss';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Pagination from '../../../components/ui/Pagination';
import Footer from '../../../components/layouts/Footer';
import { Alert } from '../../../utils/toastr';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import {
    anyKey,
    assignedNull,
    optionList,
    tableParams,
} from '../../../utils/helper';
import { getGeoLevelsFirst } from '../../service-alerts/service-alert-utils/Utils';
import Loader from '../../../components/ui/Loader';
import { getRequest } from '../../../services/apiUtils';
import ServiceAlertHeader from '../../service-alerts/service-alerts-header/index';
import GeoLevelDropdown from '../../../components/ui/GeoLevelDropdown';
import { CustomButton } from '../../../components/form/CustomButton';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Farmers } from '../ServiceAlertDataTypes/ServiceAlertDataTypes';
import { downloadAlertPage } from '../../../utils/helper';
import {
    createSearchParams,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import FromDateToDate from '../service-alerts-header/fromDateToDate';
import { connect } from 'react-redux';
import Calendar from '../service-alerts-header/Calendar';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #919191',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #919191',
            padding: '10px 26px 10px 12px',
        },
    },
}));

const PestAlertNotification = (props: any) => {
    let closeToggle: any;
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    const [data, setData] = useState<any[]>([]);
    var searchCriteria = useLocation().search;

    var urlSearchParams = new URLSearchParams(searchCriteria);
    const [geoLoader, setGeoLoader] = useState<boolean>(true);
    const [statusBarValue, setStatusBarValue] = useState<number>(() => {
        let ordinal = 0;
        let status = urlSearchParams.get('status');

        if (status === 'ALL') {
            ordinal = 0;
        } else if (status === 'APPROVED') {
            ordinal = 1;
        } else if (status === 'REJECTED') {
            ordinal = 3;
        } else if (status === 'PENDING') {
            ordinal = 2;
        }
        return ordinal;
    });

    const [loader, setLoader] = useState(true);
    const [countryChange, setCountryChange] = useState(false);
    const [dataPerPage, setDataPerPage] = useState<any[]>([]);

    const [isFiltered, setIsFiltered] = useState<boolean>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const [search, setSearch] = useState<any>(
        urlSearchParams.get('search') !== '' &&
            urlSearchParams.get('search') !== 'null'
            ? urlSearchParams.get('search')
            : ''
    );
    const [params, setParams] = useState(tableParams());
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(
        loggedUser?.roleid !== 'SUPER_ADMIN' && urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : true
    );

    interface FilterTypes {
        vendor: any;
        lob: any;
        countrycode: any;
        cropname: any;
        geolevel0code: any;
        geolevel1code: any;
        geolevel2code: any;
        geolevel3code: any;
        geolevel4code: any;
        geolevel5code: any;
        fromdate: any;
        todate: any;
    }

    const [filter, setFilter] = useState<FilterTypes>({
        cropname: urlSearchParams.get('cropname')
            ? urlSearchParams.get('cropname')
            : 'ALL',
        vendor: urlSearchParams.get('vendor')
            ? urlSearchParams.get('vendor')
            : 'ALL',
        lob: urlSearchParams.get('lob')
            ? urlSearchParams.get('lob')
            : loggedUser?.roleid === 'SUPER_ADMIN'
            ? 'ALL'
            : loggedUser?.lineofbusiness,
        countrycode: urlSearchParams.get('countrycode')
            ? urlSearchParams.get('countrycode')
            : loggedUser?.countrycode,
        geolevel0code: urlSearchParams.get('geolevel0code')
            ? urlSearchParams.get('geolevel0code') 
            : loggedUser?.countrycode,
        geolevel1code: urlSearchParams.get('geolevel1code')
            ? urlSearchParams.get('geolevel1code')
            : 'ALL',
        geolevel2code: urlSearchParams.get('geolevel2code')
            ? urlSearchParams.get('geolevel2code')
            : 'ALL',
        geolevel3code: urlSearchParams.get('geolevel3code')
            ? urlSearchParams.get('geolevel3code')
            : 'ALL',
        geolevel4code: urlSearchParams.get('geolevel4code')
            ? urlSearchParams.get('geolevel4code')
            : 'ALL',
        geolevel5code: null,
        fromdate: null,
        todate: null,
    });
    const [cropval, setCropval] = useState<any>(filter.cropname);
    const [vendorval, setVendorval] = useState<any>(filter.vendor);
    const [lobval, setLobval] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? filter.lob : loggedUser?.lineofbusiness
    );
    const [fromDateVal, setFromDateVal] = useState<any>(null);
    const [toDateVal, setToDateVal] = useState<any>(null);

    const [geolevel, setgeolevel] = useState<any>({ ...filter });
    const [currOptions, setcurrOptions] = useState<any>([]);

    const [pageFilter, setPageFilter] = useState(() => {
        var pageFilter = {
            monthyearfilter:
                props.calendarval !== null
                    ? dayjs(props.calendarval).month() +
                      1 +
                      '-' +
                      dayjs(props.calendarval).year()
                    : urlSearchParams.get('monthyearfilter')
                    ? urlSearchParams.get('monthyearfilter')
                    : dayjs().month() + 1 + '-' + dayjs().year(),
            risktype: urlSearchParams.get('risktype')
                ? urlSearchParams.get('risktype')
                : 'ALL',
            status: urlSearchParams.get('status')
                ? urlSearchParams.get('status')
                : 'ALL',
        };
        return pageFilter;
    });

    const [isPageFiltered, setIsPageFiltered] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);

    const riskState = urlSearchParams.get('risktype')
        ? urlSearchParams.get('risktype')
        : 'ALL';

    const [currentAllChipStyle, setCurrentAllChipStyle] = useState(
        riskState === 'ALL' ? 'pest_alert_all_risk_active' : 'pestAllChipStyle'
    );

    const [currentHighChipStyle, setCurrentHighChipStyle] = useState(
        riskState === 'HIGH'
            ? 'pest_alert_high_risk_active'
            : 'pest_alert_high_risk'
    );
    const [currentMediumChipStyle, setCurrentMediumChipStyle] = useState(
        riskState === 'MEDIUM'
            ? 'pest_alert_medium_risk_active'
            : 'pest_alert_medium_risk'
    );
    const [currentLowChipStyle, setCurrentLowChipStyle] = useState(
        riskState === 'LOW'
            ? 'pest_alert_low_risk_active'
            : 'pest_alert_low_risk'
    );

    const [currentNoneChipStyle, setCurrentNoneChipStyle] = useState(
        riskState === 'NONE'
            ? 'pest_alert_none_risk_active'
            : 'pest_alert_none_risk'
    );

    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);
    const [geoLevelList, setGeoLevelList] = useState<any>([]);
    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(
        urlSearchParams.get('search') !== null ||
            urlSearchParams.get('search') !== ''
            ? true
            : false
    );
    const [lookUpData, setLookUpData] = useState<string[]>([]);
    const[riskOptions, setRiskOptions] = useState<string[]>([]);

    const [selectPageOne, setSelectPageOne] = useState(true);
    const [event, setEvent] = useState<any>(new Event('Enter'));
    const [applyflag, setapplyflag] = useState<any>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const [loadLookup, setLoadLookup] = useState<boolean>(false);
    const [isClosed, setIsClosed] = useState<any>(false);
    const [messageID, setMessageID] = useState<number>(
        urlSearchParams.get('messageid') ? urlSearchParams.get('messageid') : 0
    );

    const [chipCropData, setChipCropData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [chipVendorData, setChipVendorData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);

    const [chipLobData, setChipLobData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);

    const [chipCountryData, setChipCountryData] = useState<readonly ChipData[]>(
        [{ value: loggedUser?.countrycode, label: loggedUser?.country }]
    );

    const [countryval, setCountryval] = useState<any>(filter.countrycode);
        let [searchParams, setSearchParams] = useSearchParams();

    interface ChipProps extends MuiChipProps {
        status?: string;
        activefilter?: string;
    }

    interface ChipData {
        value: string;
        label: string;
    }

    const condStatus: any = {
        'ACTIVE': {
            color: '#89D329',
            border: '1px solid #E3F1D0',
            backgroundColor: '#E3F1D0',
        },
        'INACTIVE': {
            color: '#ff9d00',
            border: '1px solid #ff9d00',
            backgroundColor: '#ffebcc',
        },
    };

    const Chip = styled(MuiChip)<ChipProps>(
        ({ status, activefilter, theme }) => ({
            ...(status && {
                ...condStatus[status],
            }),
            ...(activefilter === 'true' && {
                backgroundColor: '#10384F',
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: '#10384F !important',
                    color: '#ffffff',
                },
            }),
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.75rem',
            },
        })
    );
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];

    useEffect(() => {
        loadData();
        getLookup();
        setFirstPageLoad(false);
        if (
            urlSearchParams.get('geolevel0code') ||
            urlSearchParams.get('geolevel1code') ||
            urlSearchParams.get('geolevel2code') ||
            urlSearchParams.get('geolevel3code') ||
            urlSearchParams.get('geolevel4code')
        ) {
            getGeoLevelsFirst(setGeoLevelList, urlSearchParams, setcurrOptions);
        }
    }, []);

    useEffect(() => {
        if (isPageFiltered) {
            loadData();
            setIsPageFiltered(false);
        }
    }, [isPageFiltered]);

    useEffect(() => {
        if (firstPageLoad !== true) {
            loadData();
        }

        setFirstPageLoad(false);
    }, [params]);

    useEffect(() => {
        let selectedFilter: any = { ...filter };
        let temparr : any[] = []
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,
                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setFilter({ ...selectedFilter });
            setcurrOptions([...temparr])
        }
    }, [geoLevelList]);


    useEffect(() => {
        if (loadLookup) {
            getLookup();
            setLoadLookup(false);
        }
    }, [loadLookup]);

    const loadData = () => {

        setLoader(true);


        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let urlParams: any = {
            search: search || null,
            isfiltered: isFiltered,
        };

        let obj: any = {
            ...params,
            countrycode: filter?.countrycode,
            lob: filter?.lob,
            loggeduserrole: loggedUser?.roleid,
            search: search || null,
            messagetype: 'PEST_PREDICTION',
            isfiltered: isFiltered,
            sortby: 'createddate',
        };

        if (loggedUser?.roleid !== 'SUPER_ADMIN') {
            obj = {
                ...obj,
                'lob': loggedUser.lineofbusiness,
                'isfiltered': true,
            };
        }

       
        if (isFiltered) {
            let condFilter = assignedNull(filter);
            obj = { ...obj, ...condFilter };
            urlParams = { ...urlParams, ...filter };
        }

        let condPageFilter = assignedNull(pageFilter);
        obj = { ...obj, ...condPageFilter };
        urlParams = { ...urlParams, ...pageFilter };


    if (isFiltered) {
            let condFilter = assignedNull(filter);
            obj = { ...obj, ...condFilter, ['isfiltered']: true };

            if(loggedUser?.roleid !== 'SUPER_ADMIN' && loggedUser?.roleid !== 'ADMIN'){
            let location:string[] = [];

            if(filter.geolevel1code !== 'ALL' && filter.geolevel4code === 'ALL' ){
                location = loggedUser.assignedloc.filter((val:any)=>{
                    return filter.geolevel1code === val.lochiergeo1code
                }).map((loc:any)=>{
                  return loc.lochiergeocode
                })
            }else{
                location = loggedUser.assignedloc.map(
                    (loc: any) => loc.lochiergeocode
                );
            }

            obj = {
                ...obj,
                ...{
                    [obj.countrycode === 'TH'
                        ? 'geolevel3code'
                        : 'geolevel4code']: location.join(','),
                },
            };
        }

        }

        if (!isFiltered &&
            specificPersona.includes(loggedUser?.roleid) &&
            loggedUser.assignedloc.length > 0
        ) {

            let location:string[] = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );

            obj = {
                ...obj,
                ...{
                    'isfiltered': true,
                    [obj.countrycode === 'TH'
                        ? 'geolevel3code'
                        : 'geolevel4code']: location.join(','),
                },
            };
        } 



        if (messageID) {
            obj = { ...obj, 'messageid': messageID };
        }

        if (!firstPageLoad) {
            setSearchParams(createSearchParams(urlParams));
        }

        getRequest({
            url: 'alert/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    setData(response?.body?.data);
                    setTotalCount(response?.body?.totalcount);
                    setErrorMsg('');
                } else {
                    setData([]);
                    setErrorMsg(
                        'No data found for current filter. Please change the filters and try again'
                    );
                }
                
                setLoader(false)
               
            })
            .catch((error: any) => {
                setData([]);
                Alert('error', error?.message);
                setLoader(false)
                setData([]);
            });
    };

    const getLookup = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: filter.countrycode,
            lob: filter.lob,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    // const cropData = response?.body?.data?.croplist;

                    let vendorDataOrg = response?.body?.data?.vendor;

                    if (vendorDataOrg.length > 0) {
                        let vendorFilter = vendorDataOrg.map((vendor: any) => {
                            return {
                                label: vendor,
                                value: vendor,
                            };
                        });
                        setChipVendorData([
                            { label: 'ALL', value: 'ALL' },
                            ...vendorFilter,
                        ]);
                    } else {
                        setChipVendorData([...chipVendorData]);
                    }

                    let lobDataOrg = response?.body?.data?.lineofbusiness;

                    if (lobDataOrg.length > 0) {
                        let lobFilter = lobDataOrg.map((lob: any) => {
                            return {
                                label: lob,
                                value: lob,
                            };
                        });
                        setChipLobData([
                            { label: 'ALL', value: 'ALL' },
                            ...lobFilter,
                        ]);
                    } else {
                        setChipLobData([...chipLobData]);
                    }

                    let CountryDataOrg = response?.body?.data?.countries;

                    if (CountryDataOrg?.length > 0) {
                        const filterOption = optionList(
                            CountryDataOrg,
                            'countrycode',
                            'country'
                        );
                        setChipCountryData(filterOption);
                    } else {
                        setChipCountryData([...chipCountryData]);
                    }

                    const cropDataOriginal = response?.body?.data?.croplist;

                    if (cropDataOriginal?.length > 0) {
                        const filterOption = optionList(
                            cropDataOriginal,
                            'cropcode',
                            'cropname'
                        );
                        setChipCropData([
                            { label: 'ALL', value: 'ALL' },
                            ...filterOption,
                        ]);
                        setLookUpData(response.body.data.products);
                    } else {
                        setChipCropData([...chipCropData]);
                    }
                     let riskstatus = response.body.data.actualrisk;
                setRiskOptions(riskstatus);
                }
            })
            .catch((error: any) => {
                setChipCropData([...chipCropData]);
                setChipVendorData([...chipVendorData]);
                setChipLobData([...chipLobData]);
                setChipCountryData([...chipCountryData]);
                Alert('error', error?.message);
            });
    };

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const handlePageFilter = (value: any, name: string) => {
        setPageFilter({ ...pageFilter, [name]: value });
    };

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
    };

    const allChipChange = () => {
        setParams(tableParams());
        setSelectPageOne(true);
        if (currentAllChipStyle === 'pestAllChipStyle') {
            setCurrentAllChipStyle('pest_alert_all_risk_active');
            handlePageFilter('ALL', 'risktype');
            setCurrentHighChipStyle('pest_alert_high_risk');
            setCurrentMediumChipStyle('pest_alert_medium_risk');
            setCurrentLowChipStyle('pest_alert_low_risk');
            setCurrentNoneChipStyle('pest_alert_none_risk');
        } else {
            setCurrentAllChipStyle('pestAllChipStyle');
        }
    };

    const handleHighClick = () => {
        setParams(tableParams());
        setSelectPageOne(true);
        if (currentHighChipStyle === 'pest_alert_high_risk') {
            setCurrentHighChipStyle('pest_alert_high_risk_active');
            setCurrentMediumChipStyle('pest_alert_medium_risk');
            setCurrentLowChipStyle('pest_alert_low_risk');
            setCurrentAllChipStyle('pestAllChipStyle');
            setCurrentNoneChipStyle('pest_alert_none_risk');

            handlePageFilter('HIGH', 'risktype');
        } else {
            handlePageFilter('ALL', 'risktype');
            setCurrentHighChipStyle('pest_alert_high_risk');
            setCurrentAllChipStyle('pest_alert_all_risk_active');
        }
    };

    const handleMediumClick = () => {
        setParams(tableParams());
        setSelectPageOne(true);
        if (currentMediumChipStyle === 'pest_alert_medium_risk') {
            setCurrentMediumChipStyle('pest_alert_medium_risk_active');
            setCurrentHighChipStyle('pest_alert_high_risk');
            setCurrentAllChipStyle('pestAllChipStyle');
            setCurrentLowChipStyle('pest_alert_low_risk');
            setCurrentNoneChipStyle('pest_alert_none_risk');

            handlePageFilter('MEDIUM', 'risktype');
        } else {
            handlePageFilter('ALL', 'risktype');
            setCurrentMediumChipStyle('pest_alert_medium_risk');
            setCurrentAllChipStyle('pest_alert_all_risk_active');
        }
    };

    const handleLowClick = () => {
        setParams(tableParams());
        setSelectPageOne(true);
        if (currentLowChipStyle === 'pest_alert_low_risk') {
            setCurrentLowChipStyle('pest_alert_low_risk_active');
            setCurrentHighChipStyle('pest_alert_high_risk');
            setCurrentMediumChipStyle('pest_alert_medium_risk');
            setCurrentAllChipStyle('pestAllChipStyle');
            setCurrentNoneChipStyle('pest_alert_none_risk');

            handlePageFilter('LOW', 'risktype');
        } else {
            handlePageFilter('ALL', 'risktype');

            setCurrentLowChipStyle('pest_alert_low_risk');
            setCurrentAllChipStyle('pest_alert_all_risk_active');
        }
    };

    const handleNoneClick = () => {
        setParams(tableParams());
        setSelectPageOne(true);
        if (currentNoneChipStyle === 'pest_alert_none_risk') {
            setCurrentNoneChipStyle('pest_alert_none_risk_active');
            setCurrentHighChipStyle('pest_alert_high_risk');
            setCurrentMediumChipStyle('pest_alert_medium_risk');
            setCurrentAllChipStyle('pestAllChipStyle');
            setCurrentLowChipStyle('pest_alert_low_risk');

            handlePageFilter('NULL', 'risktype');
        } else {
            handlePageFilter('ALL', 'risktype');

            setCurrentNoneChipStyle('pest_alert_none_risk');
            setCurrentAllChipStyle('pest_alert_all_risk_active');
        }
    };

    const handleStatusBarChange = (
        event: React.SyntheticEvent,
        ordinal: number
    ) => {
        let status = 'ALL';

        if (ordinal === 1) {
            status = 'APPROVED';
        } else if (ordinal === 2) {
            status = 'PENDING';
        } else if (ordinal === 3) {
            status = 'REJECTED';
        }

        handlePageFilter(status, 'status');
        setParams(tableParams());
        setSelectPageOne(true);
        setStatusBarValue(ordinal);
    };

    const handleResetFilter = () => {
        setFirstPageLoad(true);
        setapplyflag(false);
        setIsClearGeoLevel(true);
        setIsFiltered(false);
        setIsPageFiltered(true);
        setFilter({
            vendor: 'ALL',
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
            countrycode: loggedUser?.countrycode,
            cropname: 'ALL',
            geolevel0code: loggedUser?.countrycode,
            geolevel1code: 'ALL',
            geolevel2code: 'ALL',
            geolevel3code: 'ALL',
            geolevel4code: 'ALL',
            geolevel5code: null,
            fromdate: null,
            todate: null,
        });
        closeToggle && closeToggle();
        setParams(tableParams());

        setSelectPageOne(true);
        setBadgeEnabled(loggedUser?.roleid === 'SUPER_ADMIN' ? true : false);
        setCountryval(loggedUser?.countrycode);
        setLobval(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser?.lineofbusiness
        );
        setLoadLookup(true);
    };

    const handleApplyFilter = (event: any) => {
        setapplyflag(true);
        setIsClearGeoLevel(false);
        setIsFiltered(true);
        closeToggle && closeToggle();
        setParams(tableParams());
        setSelectPageOne(true);
        setBadgeEnabled(true);
        setCropval(filter.cropname);
        setVendorval(filter.vendor);
        setFromDateVal(filter.fromdate);
        setToDateVal(filter.todate);
        setLobval(filter.lob);
        setCountryval(filter.geolevel0code);
        if (loggedUser?.roleid === 'SUPER_ADMIN') {
            setLobval(filter.lob);
        } else {
            setLobval(loggedUser.lineofbusiness);
        }
        let selectedFilter: any = { ...filter };
        let temparr: any = [];

        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,

                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setcurrOptions([...temparr]);
            setgeolevel({ ...selectedFilter });
        }
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                ...filter,
                'vendor': 'ALL',
                'lob':
                    loggedUser?.roleid === 'SUPER_ADMIN'
                        ? 'ALL'
                        : loggedUser.lineofbusiness,
                'cropname': 'ALL',
                'fromdate': '',
                'todate': '',
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: 'ALL',
                geolevel2code: 'ALL',
                geolevel3code: 'ALL',
                geolevel4code: 'ALL',
                geolevel5code: null,
            });
            setIsClosed(false);
            setGeoLoader(true);
        } else if (applyflag && isClosed) {
            setFilter({
                vendor: vendorval,
                lob: lobval,
                countrycode: countryval,
                cropname: cropval,
                geolevel0code: countryval,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
                fromdate: fromDateVal,
                todate: toDateVal,
            });
            geoLevelList.map((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            setIsClosed(false);
            setGeoLoader(true);
        }
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let search = event.target.value;
        setSearch(search);
        setRunHandleSearch(true);
        setSelectPageOne(true);
    };

    const enterKeyHandler = (event: any) => {
        setEvent(event);
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
    };

    if (runHandleSearch) {
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
        setRunHandleSearch(false);
    }

    const handleChange = (dateval: any, type: any, bool: any) => {
        setPageFilter({ ...pageFilter, [type]: dateval });
        if (bool) {
            setParams(tableParams());
            setSelectPageOne(true);
        } else {
            setIsPageFiltered(true);
        }
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div className='container_pest_div'>
                <div>
                    <ServiceAlertHeader
                        alertType='PEST_PREDICTION'
                        alertDescription='Pest Name'
                        placeHolder='Search Notification (min 3 letters)'
                        onCloseHandler={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        isFiltered={isFiltered}
                        isBadgeEnabled={badgeEnabled}
                        filter={{
                            ...assignedNull(filter),
                            ...assignedNull(pageFilter),
                        }}
                        children={
                            <div>
                                {!geoLoader && (
                                    <>
                                        {loggedUser?.roleid === 'SUPER_ADMIN' ? (
                                            <div className='pest_filter_topdiv'>
                                                <div className='row'>
                                                    <div className='title-label'>
                                                        Country
                                                    </div>
                                                    <div className='service-alerts-padding'>
                                                        <Select
                                                            className='pest_dropdown'
                                                            // variant='standard'
                                                            value={
                                                                filter.geolevel0code
                                                            }
                                                            // style={customStyles}
                                                            size='small'
                                                            onChange={(
                                                                evt: any
                                                            ) => {
                                                                setFilter({
                                                                    ...filter,
                                                                    ...{
                                                                        'geolevel0code':
                                                                            evt
                                                                                .target
                                                                                .value,
                                                                        'geolevel1code': 'ALL',
                                                                        'geolevel2code': 'ALL',
                                                                        'geolevel3code': 'ALL',
                                                                        'geolevel4code': 'ALL',
                                                                        'geolevel5code': null,
                                                                        'countrycode':
                                                                            evt
                                                                                .target
                                                                                .value,
                                                                        'lob':
                                                                            'ALL',
                                                                            'cropname':'ALL',
                                                                            'vendor':'ALL',
                                                                            'fromdate':null,
                                                                            'todate':null
                                                                    },
                                                                });
                                                                setLobval(
                                                                    'ALL'
                                                                );
                                                                setCountryChange(true)
                                                                setLoadLookup(
                                                                    true
                                                                );
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                  sx: {
                                                                    "& .MuiMenuItem-root.Mui-selected": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    },
                                                                    "& .MuiMenuItem-root:hover": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    },
                                                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    }
                                                                  }
                                                                }
                                                              }}>
                                                            {chipCountryData &&
                                                                chipCountryData.map(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.value !==
                                                                        filter?.countrycode ? (
                                                                            <MenuItem
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        ) : (
                                                                            <MenuItem
                                                                                disabled
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                )}
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='title-label'>
                                                        Business Unit
                                                    </div>
                                                    <div className='service-alerts-padding'>
                                                        <Select
                                                            className='pest_dropdown'
                                                            // variant='standard'
                                                            value={filter.lob}
                                                            // style={customStyles}
                                                            size='small'
                                                            onChange={(
                                                                evt: any
                                                            ) => {
                                                                handleFilter(
                                                                    evt.target
                                                                        .value,
                                                                    'lob'
                                                                );
                                                                setLobval(
                                                                    evt.target
                                                                        .value
                                                                );
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                  sx: {
                                                                    "& .MuiMenuItem-root.Mui-selected": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    },
                                                                    "& .MuiMenuItem-root:hover": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    },
                                                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                      backgroundColor: "#007aff",
                                                                      color:"white"
                                                                    }
                                                                  }
                                                                }
                                                              }}>
                                                          
                                                            {chipLobData &&
                                                                chipLobData.map(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.value !==
                                                                        filter?.lob ? (
                                                                            <MenuItem
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        ) : (
                                                                            <MenuItem
                                                                                disabled
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                )}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className='pest_filter_topdiv'>
                                            <div className='row'>
                                                <div className='title-label'>
                                                    Vendor
                                                </div>
                                                <div className='service-alerts-padding'>
                                                    <Select
                                                        className='pest_dropdown'
                                                        // variant='standard'
                                                        defaultValue={
                                                            filter.vendor
                                                        }
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            handleFilter(
                                                                evt.target
                                                                    .value,
                                                                'vendor'
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                              sx: {
                                                                "& .MuiMenuItem-root.Mui-selected": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                }
                                                              }
                                                            }
                                                          }}>
                                                        {chipVendorData &&
                                                            chipVendorData.map(
                                                                (option: any) =>
                                                                    option.label !==
                                                                    filter?.vendor ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='title-label'>
                                                    Crop
                                                </div>
                                                <div className='service-alerts-padding'>
                                                    <Select
                                                        className='pest_dropdown'
                                                        // variant='standard'
                                                        defaultValue={
                                                            filter.cropname
                                                        }
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            handleFilter(
                                                                evt.target
                                                                    .value,
                                                                'cropname'
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                              sx: {
                                                                "& .MuiMenuItem-root.Mui-selected": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                }
                                                              }
                                                            }
                                                          }}>
                                                        {chipCropData &&
                                                            chipCropData.map(
                                                                (option: any) =>
                                                                    option.label !==
                                                                    filter?.cropname ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <GeoLevelDropdown
                                    getGeoLevelData={handleGetGeoLevelData}
                                    geoLevelList={geoLevelList}
                                    isClearGeoLevel={isClearGeoLevel}
                                    filter={filter}
                                    handleFilter={handleFilter}
                                    handleLoader={(isLoader: boolean) =>
                                        setGeoLoader(isLoader)
                                    }
                                    lobSelected={filter.lob}
                                    countryChange = {countryChange}
                                    setCountryChange = {setCountryChange}
                                />
                                {!geoLoader && (
                                    <>
                                        <FromDateToDate
                                            handleFilter={handleFilter}
                                            filter={filter}
                                        />
                                        <div className='filter_buttondiv'>
                                            <CustomButton
                                                label={'Reset'}
                                                className='cus-btn-filter reset'
                                                handleClick={handleResetFilter}
                                            />
                                            <CustomButton
                                                label={'Apply'}
                                                style={{
                                                    borderRadius: '20px',
                                                    backgroundColor: '#7eb343',

                                                    border: 0,
                                                    boxShadow:
                                                        '0px 3px 6px #c7c7c729',
                                                }}
                                                className='cus-btn'
                                                handleClick={handleApplyFilter}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                        handleSearch={handleSearch}
                        searchText={search}
                        onKeyDown={enterKeyHandler}
                        handleOnClickDownload={() => {
                            downloadAlertPage(
                                search,
                                isFiltered,
                                'PEST_PREDICTION',
                                {
                                    ...assignedNull(filter),
                                    ...assignedNull(pageFilter),
                                }
                            );
                        }}
                    />
                </div>

                <div className='top_pest_alerts_div'>
                    <div>
                        <Box>
                            <Tabs
                                onChange={handleStatusBarChange}
                                value={statusBarValue}>
                                <Tab label='All' />
                                {/* <Divider
                                    orientation='vertical'
                                    variant='middle'
                                    flexItem
                                /> */}
                                <Tab label='Approved' />
                                {/* <Divider
                                    orientation='vertical'
                                    variant='middle'
                                    flexItem
                                /> */}
                                <Tab label='Pending' />
                                {/* <Divider
                                    orientation='vertical'
                                    variant='middle'
                                    flexItem
                                /> */}
                                <Tab label='Rejected' />
                            </Tabs>
                        </Box>
                    </div>
                    <div className='pest_alert_risk_type'>
                        <div>
                            <p>Risk</p>
                        </div>

                        <Chip
                            label='All'
                            variant='filled'
                            className={currentAllChipStyle}
                            onClick={allChipChange}
                        />

                        <Chip
                            label='High'
                            variant='filled'
                            className={currentHighChipStyle}
                            onClick={handleHighClick}
                        />

                        <Chip
                            label='Medium'
                            variant='filled'
                            className={currentMediumChipStyle}
                            onClick={handleMediumClick}
                        />

                        <Chip
                            label='Low'
                            variant='filled'
                            className={currentLowChipStyle}
                            onClick={handleLowClick}
                        />
                        <Chip
                            label='None'
                            variant='filled'
                            className={currentNoneChipStyle}
                            onClick={handleNoneClick}
                        />
                    </div>
                    <div>
                        <Calendar handleChange={handleChange} />
                        {/*  <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={['year', 'month']}
                                value={valueDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={handleCalendarChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        helperText={null}
                                    />
                                )}
                            />
                                </LocalizationProvider> */}
                    </div>
                </div>
                <div className='pest_alert_middle_section'>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 1, sm: 1, md: 9, lg: 12, xl: 15 }}>
                            {data.length ? (
                                data.map((item, index) => (
                                    <Grid
                                        item
                                        xs={1}
                                        sm={1}
                                        md={3}
                                        lg={4}
                                        xl={5}
                                        key={index}>
                                        <PestNotification
                                            msgId={item.messageid}
                                            type={item.messagetype}
                                            crop={item.cropname}
                                            risk={item.risktype}
                                            message={item.message}
                                            identifier={item.diseasename}
                                            status={item.status}
                                            farmer={item.farmers}
                                            consultants={item.consultants}
                                            approvers={item.approvers}
                                            createdtime={item.createddate}
                                            Location={
                                                loggedUser?.countrycode === 'TH' || filter.countrycode === 'TH'
                                                    ? `${item.devicegeolevel3}, ${item.devicegeolevel2}, ${item.devicegeolevel1}`.replace(
                                                          /\w+/g,
                                                          _.capitalize
                                                      )
                                                    : `${item.devicegeolevel4}, ${item.devicegeolevel3}, ${item.devicegeolevel2}, ${item.devicegeolevel1}`.replace(
                                                          /\w+/g,
                                                          _.capitalize
                                                      )
                                            }
                                            reas={item.reason}
                                            riskstatus={item.actualrisk}
                                            riskOptions={riskOptions}
                                            agrireas={item.agriculturecomment}
                                            lookUpData={lookUpData}
                                            product={item.bayerproductname}
                                            reloadData={loadData}
                                            lastaction={item.lastaction}
                                            rejecteddate={item.rejecteddate}
                                            approveddate={item.approveddate}
                                            sprayname={item.sprayname}
                                            vendor={item.vendor}
                                            selectedLob={
                                                lobval !== 'ALL'
                                                    ? lobval
                                                    : item.lineofbusiness
                                            }
                                            selectedCountry={countryval}
                                            filterdropdown={filter.countrycode}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <div className='pest_error_msg'>{errorMsg}</div>
                            )}
                        </Grid>
                    </Box>
                </div>
            </div>
            <div className='footer_pest'>
                {data.length ? (
                    <Footer>
                        <Pagination
                            postsTitle='Pest Alert(s)'
                            posts={data}
                            onIndex={toPagi}
                            postsPerPage={10}
                            // isFiltered={false}
                            onIndexPageNo={pageNoSelected}
                            totalCountAvailable={totalCount}
                            selectPageOne={selectPageOne}
                        />
                    </Footer>
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = ({ calendar: { calendarValue } }: any) => {
    return {
        calendarval: calendarValue,
    };
};
export default connect(mapStateToProps)(PestAlertNotification);
