/** @format */

import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete, Stack, TextField, InputLabel } from '@mui/material';
import { Dayjs } from 'dayjs';
import TopHeader from '../../components/layouts/TopHeader';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import './index.scss';
import { getRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { setType } from '../../utils/helper';
import { makeStyles } from '@material-ui/core/styles';
import WeatherGraph from './graph';
import Loader from '../../components/ui/Loader';
import ForecastGraph from './forecastGraph';
import Forecast14Days from './forecast14Days';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import html2canvas from 'html2canvas';


const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderRightStyle: 'solid',
        borderRightColor: 'lightgray',
        display: 'tableRowGroup',
    },
}));

const Styles = makeStyles(() => ({
    autoComplete: {
      fontSize: "13px",
      wordBreak:'break-word'
    }
  }));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: 1,
    cursor: 'pointer',
}));

function ReportScreenIndex() {
    const dayjs = require('dayjs');

    const classes = useStyles();
    const classe = Styles();

    const style = {
        width: '129px',
        // borderStyle: "border-box",
        padding: '6px',
        borderBottom: 'none',
    };

    const stylecrop = {
        width: '161px',
        // borderStyle: "border-box",
        padding: '0px',
        borderBottom: 'none',
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
            borderBottom: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableAllCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
            borderBottom: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: 'none',
            padding: 30,
        },
    }));

    const StyledTableALLCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
            borderBottom: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: 'none',
            padding: 30,
        },
    }));

    const StyledTableRejectedCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
            borderBottom: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: 'none',
            padding: 40,
        },
    }));


    
const capture = () => {
html2canvas (document.body,{ allowTaint: true, useCORS: true,height: window.outerHeight + window.innerHeight,
    windowHeight: window.outerHeight + window.innerHeight})
    .then( function( canvas ) {
    const a = document.createElement('a');
                a.href = canvas
                    .toDataURL('..assets/image/jpeg',1.0)
                    .replace('image/jpeg',1.0);
                a.download = reportData === 'Weather History Report' ? 'History_Report.jpeg' : reportData === 'Forecast-24 Hours Report' ? 'Forecast24HoursGraph.jpeg' :'Forecast14dayssGraph.jpeg'
                a.click();
} );
}

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [fromValue, setFromValue] = useState<any>(null);
    const [toValue, setToValue] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const [tableFlag, setTableFlag] = useState<boolean>(false);
    const [graphFlag, setGraphFlag] = useState<boolean>(false);
    const [forecastFlag, setForecastFlag] = useState<boolean>(false);

    const [reportData, setReportData] = useState<any>('select');
    const [reportName, setReportName] = useState<any>();
    const [typeMap, setTypeMap] = useState<Map<any, any>>();
    const [loading, setLoading] = useState(true);

    const [CountryData, setCountryData] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? 'select' : loggedUser?.countrycode
    );
    const [countryChange, setCountryChange] = useState<any[]>([]);

    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN'
            ? 'select'
            : loggedUser.lineofbusiness
    );
    const [businessUnitList, setBusinessUnitList] = useState<any[]>([]);

    const [selectedSeason, setSelectedSeason] = useState<any>('select');
    const [selectedDevice, setSelectedDevice] = useState<string[]>([]);

    const [seasonList, setSeasonList] = useState<any[]>([]);

    const [cropData, setCropData] = useState<any>('select');

    const [deviceDropDownData, setDeviceDropDownData] = useState<any>('select');

    const [deviceWeatherDropDownData, setDeviceWeatherDropDownData] = useState<
        any
    >('select');

    const [cropList, setCropList] = useState<string[]>([]);

    const [locationKey, setLocationKey] = useState<any>(Math.random());

    const [deviceArray, setDeviceArray] = useState<any[]>([]);
    const [fasalArray, setFasalArray] = useState<any[]>([]);

    const [errorMsg, setErrorMsg] = useState<any>();

    const [calendarSeasonYear, setCalendarSeasonYear] = useState<any>(null);

    const [alertTypeDropdown, setAlertTypeDropdown] = useState<any>([]);

    //table states

    const [selectedAlert, setSelectedAlert] = useState<string>('select');

    const [currentReport, setCurrentReport] = useState<any>('select');

    const [currentAlertType, setCurrentAlertType] = useState<any>('select');

    const [displayData, setDisplayData] = useState<any[]>([]);
    const [displayCropData, setDisplayCropData] = useState<any[]>([]);

    const [monthPlotData, setMonthPlotData] = useState<any[]>([]);
    const [temperaturePlotData, setTemperaturePlotData] = useState<any[]>([]);
    const [humidityPlotData, setHumidityPlotData] = useState<any[]>([]);
    const [rainfallPlotData, setRainfallPlotData] = useState<any[]>([]);
    const [windPlotData, setWindPlotData] = useState<any[]>([]);

    const [monthForecastData, setMonthForecastData] = useState<any[]>([]);
    const [temperatureForecastData, setTemperatureForecastData] = useState<
        any[]
    >([]);
    const [humidityForecastData, setHumidityForecastData] = useState<any[]>([]);
    const [rainfallForecastData, setRainfallForecastData] = useState<any[]>([]);
    const [windForecastData, setWindForecastData] = useState<any[]>([]);

    const [dayForecastData, setDayForecastData] = useState<any[]>([]);
    const [temperatureForecast14Days, setTemperatureForecast14Days] = useState<
        any[]
    >([]);
    const [humidityForecast14Days, setHumidityForecast14Days] = useState<any[]>(
        []
    );
    const [rainfallForecast14Days, setRainfallForecast14Days] = useState<any[]>(
        []
    );
    const [windForecast14Days, setWindForecast14Days] = useState<any[]>([]);

    const [minimumData, setMinimumData] = useState<any[]>([]);
    const [maxData, setMaxData] = useState<any[]>([]);
    const [avgData, setAvgData] = useState<any[]>([]);
    const [totalRainfall, setTotalRainfall] = useState<any[]>([]);
    const [monthData, setMonthData] = useState<any[]>([]);

    const [forecastData, setForecastData] = useState<any>();

    const [buttonFlag, setButtonFlag] = useState<boolean>(true);

    const [seasonErrorMsg, setSeasonErrorMsg] = useState<any>('');

    const [downloadReportName, setDownloadReportName] = useState<any>();
    const [downloadCountry, setDownloadCountry] = useState<any>();
    const [downloadBusinessUnit, setDownloadBusinessUnit] = useState<any>();
    const [downloadCalendaryr, setDownloadCalendaryr] = useState<any>();
    const [downloadSeason, setDownloadSeason] = useState<any>();
    const [downloadAlertType, setDownloadAlertType] = useState<any>();
    const [downloadFromDate, setDownloadFromDate] = useState<any>();
    const [downloadToDate, setDownloadToDate] = useState<any>();
    const [downloadCrop, setDownloadCrop] = useState<any>();
    const [downloadDevice, setDownloadDevice] = useState<any>();
    const [
        downloadDeviceWeatherDropDownData,
        setDownloadDeviceWeatherDropDownData,
    ] = useState<any>();

    const [minDate, setMinDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(6, 'month');
        return currentDate;
    });
    const [maxDate, setMaxDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs();
        return currentDate;
    });

    const [minDateSeason, setMinDateSeason] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [maxDateSeason, setMaxDateSeason] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(1, 'year');
        return currentDate;
    });

    const handleFromDate = (newValue: Dayjs | null) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);

        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setFromValue(newValue);
        setFromDate(dateFilterValue);
    };

    const handleToDate = (newValue: Dayjs | null) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);

        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setToValue(newValue);
        setToDate(dateFilterValue);
    };

    const handleSeasonCalendarChange = (newValue: any) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);

        const year = dayjs_date.year();

        setCalendarSeasonYear(newValue);
        setSelectedSeason('select');
        getReportMasterData(
            CountryData,
            false,
            cropData,
            selectedBusinessUnit,
            year,
            selectedSeason
        );

        setSelectedDevice([]);
        setDeviceArray([]);
        setFasalArray([]);
        setCropData('select');
        setSelectedSeason('select');
        setSelectedAlert('select');
    };

    const validateError = () => {
        let error = [];
        let errorMessage = '';

        if (reportData === 'Weather History Report') {
            if (
                reportData === 'select' ||
                CountryData === 'select' ||
                selectedBusinessUnit === 'select' ||
                selectedSeason === 'select' ||
                selectedAlert === 'select' ||
                cropData === 'select' ||
                fromValue === null ||
                toValue === null ||
                deviceWeatherDropDownData === 'select'
            ) {
                error.push('All Fields are Mandatory');
            }
        } else if (reportData === 'Forecast-24 Hours Report') {
            if (
                reportData === 'select' ||
                CountryData === 'select' ||
                selectedBusinessUnit === 'select' ||
                selectedSeason === 'select' ||
                cropData === 'select' ||
                deviceWeatherDropDownData === 'select'
            ) {
                error.push('All Fields are Mandatory');
            }
        } else if (reportData === 'Forecast-14 Days Report') {
            if (
                reportData === 'select' ||
                CountryData === 'select' ||
                selectedBusinessUnit === 'select' ||
                selectedSeason === 'select' ||
                cropData === 'select' ||
                deviceWeatherDropDownData === 'select'
            ) {
                error.push('All Fields are Mandatory');
            }
        } else {
            if (reportData === 'select') {
                error.push('Please select ReportName');
            }

            if (
                loggedUser?.roleid === 'SUPER_ADMIN' &&
                CountryData === 'select'
            ) {
                error.push('Please select CountryName');
            }

            if (
                loggedUser?.roleid === 'SUPER_ADMIN' &&
                selectedBusinessUnit === 'select'
            ) {
                error.push('Please select Business Unit');
            }

            if (selectedSeason === 'select') {
                error.push(
                    'Please select Season(can be selected only after selecting calendar year)'
                );
            }

            if (selectedAlert === 'select') {
                error.push('Please select AlertType');
            }
        }

        error.forEach((err: string) => {
            errorMessage += err + ', ';
        });

        return errorMessage;
    };

    const handleButtonClick = () => {
        let errorMessage = validateError();

        setCurrentReport(reportData);
        setCurrentAlertType(selectedAlert);

        if (errorMessage !== '') {
            setErrorMsg(errorMessage);
            setTableFlag(false);
            setGraphFlag(false);
            setForecastFlag(false);
            setLoading(false);
            return;
        }

        if (reportData === 'Device Level Accuracy Report') {
            setDownloadReportName(reportData);
            setDownloadCountry(CountryData);
            setDownloadBusinessUnit(selectedBusinessUnit);
            setDownloadCalendaryr(calendarSeasonYear);
            setDownloadSeason(selectedSeason);
            setDownloadAlertType(selectedAlert);
            setDownloadFromDate(fromDate);
            setDownloadToDate(toDate);
            setDownloadCrop(cropData);
            setDownloadDevice(selectedDevice);
            setDownloadDeviceWeatherDropDownData(deviceWeatherDropDownData);

            getDeviceLevelData();
            setTableFlag(true);
            setErrorMsg('');
            setButtonFlag(true);
        }

        if (reportData === 'Crop Level Accuracy Report') {
            setDownloadReportName(reportData);
            setDownloadCountry(CountryData);
            setDownloadBusinessUnit(selectedBusinessUnit);
            setDownloadCalendaryr(calendarSeasonYear);
            setDownloadSeason(selectedSeason);
            setDownloadAlertType(selectedAlert);
            setDownloadFromDate(fromDate);
            setDownloadToDate(toDate);
            setDownloadCrop(cropData);
            setDownloadDevice(selectedDevice);
            setDownloadDeviceWeatherDropDownData(deviceWeatherDropDownData);

            getCropLevelData();
            setTableFlag(true);
            setErrorMsg('');
            setButtonFlag(true);
        }

        if (reportData === 'Weather History Report') {
            setDownloadReportName(reportData);
            setDownloadCountry(CountryData);
            setDownloadBusinessUnit(selectedBusinessUnit);
            setDownloadCalendaryr(calendarSeasonYear);
            setDownloadSeason(selectedSeason);
            setDownloadAlertType(selectedAlert);
            setDownloadFromDate(fromDate);
            setDownloadToDate(toDate);
            setDownloadCrop(cropData);
            setDownloadDevice(selectedDevice);
            setDownloadDeviceWeatherDropDownData(deviceWeatherDropDownData);

            getWeatherAnalyticsData();
            setGraphFlag(true);
            setErrorMsg('');
            setButtonFlag(true);
        }

        if (reportData === 'Forecast-24 Hours Report') {
            setDownloadReportName(reportData);
            setDownloadCountry(CountryData);
            setDownloadBusinessUnit(selectedBusinessUnit);
            setDownloadCalendaryr(calendarSeasonYear);
            setDownloadSeason(selectedSeason);
            setDownloadAlertType(selectedAlert);
            setDownloadFromDate(fromDate);
            setDownloadToDate(toDate);
            setDownloadCrop(cropData);
            setDownloadDevice(selectedDevice);
            setDownloadDeviceWeatherDropDownData(deviceWeatherDropDownData);

            getForecastData();
            setForecastFlag(true);
            setErrorMsg('');
            setButtonFlag(true);
        }

        if (reportData === 'Forecast-14 Days Report') {
            setDownloadReportName(reportData);
            setDownloadCountry(CountryData);
            setDownloadBusinessUnit(selectedBusinessUnit);
            setDownloadCalendaryr(calendarSeasonYear);
            setDownloadSeason(selectedSeason);
            setDownloadAlertType(selectedAlert);
            setDownloadFromDate(fromDate);
            setDownloadToDate(toDate);
            setDownloadCrop(cropData);
            setDownloadDevice(selectedDevice);
            setDownloadDeviceWeatherDropDownData(deviceWeatherDropDownData);

            getForecastData();
            setForecastFlag(true);
            setErrorMsg('');
            setButtonFlag(true);
        }
    };

    const handleReportChange = (event: SelectChangeEvent) => {
        setReportData(event.target.value);
        getCountryList();
        setCountryData(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'select'
                : loggedUser?.countrycode
        );
        setSelectedBusinessUnit(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'select'
                : loggedUser?.lineofbusiness
        );
        setSelectedSeason('select');
        setCropData('select');
        setSelectedDevice([]);
        setDeviceWeatherDropDownData('select');
        setFasalArray([]);
        setSeasonList([]);
        setCropList([]);
        setBusinessUnitList([]);
        setDeviceArray([]);
        setSelectedAlert('select');
        setCalendarSeasonYear(null);
        setFromValue(null);
        setToValue(null);
        setFromDate(null);
        setToDate(null);
        setTableFlag(false);
        setGraphFlag(false);
        setForecastFlag(false);
        setErrorMsg('');
        setCurrentAlertType('select');
        setAlertTypeDropdown([]);
        setDownloadReportName('');
        setDownloadCountry('');
        setDownloadBusinessUnit('');
        setDownloadCalendaryr('');
        setDownloadSeason('');
        setDownloadAlertType('');
        setDownloadFromDate('');
        setDownloadToDate('');
        setDownloadCrop('');
        setDownloadDevice('');
        setDownloadDeviceWeatherDropDownData('');
        setCountryChange([]);
        setSeasonErrorMsg('');
    };

    const handleCountryChange = (event: any) => {
        setCountryData(event.target.value);
        setSelectedBusinessUnit(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'select'
                : loggedUser?.lineofbusiness
        );
        setSelectedSeason('select');
        setCropData('select');
        setSelectedDevice([]);
        setDeviceWeatherDropDownData('select');
        setSeasonList([]);
        setCropList([]);
        setDeviceArray([]);
        setFasalArray([]);
        setSelectedAlert('select');
        setCalendarSeasonYear(null);
        setFromValue(null);
        setToValue(null);
        setFromDate(null);
        setToDate(null);
        getReportMasterData(
            event.target.value,
            false,
            '',
            selectedBusinessUnit,
            calendarSeasonYear,
            selectedSeason
        );
    };

    const handleBusinessChange = (event: any) => {
        setSelectedBusinessUnit(event?.target.value);
        setLocationKey(Math.random());
        setSelectedSeason('select');
        setCropData('select');
        setSelectedAlert('select');
        setSelectedDevice([]);
        setDeviceWeatherDropDownData('select');
        setFasalArray([]);
        setSeasonList([]);
        setCropList([]);
        setDeviceArray([]);
        setCalendarSeasonYear(null);
        setFromValue(null);
        setToValue(null);
        setFromDate(null);
        setToDate(null);
    };

    const handleSeasonChange = (event: any) => {
        setSelectedSeason(event.target.value);
        setSelectedDevice([]);
        setDeviceArray([]);
        setFasalArray([]);
        setCropData('select');
        setSelectedAlert('select');
        if (
            reportData === 'Device Level Accuracy Report' ||
            reportData === 'Crop Level Accuracy Report'
        ) {
            setAlertTypeDropdown(typeMap?.get('REPORT_TYPE_ACCURACY'));
        } else if (reportData === 'Weather History Report') {
            setAlertTypeDropdown(typeMap?.get('REPORT_TYPE_ANALYTIC'));
        }
    };

    const handleCropChange = (event: any) => {
        setCropData(event.target.value);
        getReportMasterData(
            CountryData,
            true,
            event.target.value,
            selectedBusinessUnit,
            calendarSeasonYear,
            selectedSeason
        );
        setSelectedDevice([]);
        setDeviceWeatherDropDownData('select');
        setFasalArray([]);
    };

    const handleDeviceWeatherChange = (event: any) => {
        setDeviceWeatherDropDownData(event?.target.value);
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    useEffect(() => {
        getReportMasterData(
            CountryData,
            false,
            '',
            selectedBusinessUnit,
            calendarSeasonYear,
            selectedSeason
        );
    }, []);

    function getCountryList() {
        getRequest({
            url: 'lookup/country/config',
            method: 'GET',
        }).then((response: any) => {
            let tempCountryArray: any[] = [];
            let countries = response.body.data.countries;
            countries.forEach((country: any) => {
                tempCountryArray.push(country);
            });
            setCountryChange(tempCountryArray);
        });
    }

    const getReportMasterData = (
        country: any,
        setDeviceData: boolean,
        crop: any,
        lob: any,
        calendarSeasonYear: any,
        selectedSeason: any
    ) => {
        let obj: any = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? country
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            reportname: reportData,
        };

        if (crop && crop !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: crop },
            };
        }

        if (lob && lob !== 'select') {
            obj = {
                ...obj,
                ...{
                    lob:
                        loggedUser?.roleid === 'SUPER_ADMIN'
                            ? selectedBusinessUnit
                            : loggedUser.lineofbusiness,
                },
            };
        }

        if (calendarSeasonYear && calendarSeasonYear !== '') {
            obj = {
                ...obj,
                ...{ year: calendarSeasonYear },
            };
        }

        if (selectedSeason !== 'select') {
            obj = {
                ...obj,
                ...{ seasonid: selectedSeason },
            };
        }

        getRequest({
            url: 'report/lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                if (setDeviceData === false) {
                    setReportName(response.body.data.reports);
                    response.body.data.lineofbusiness.forEach(
                        (lobData: any) => {
                            if (lobData.countrycode === country) {
                                setBusinessUnitList(['ALL', ...lobData.value]);
                            }
                        }
                    );

                    if (selectedBusinessUnit !== 'select') {
                        if (
                            response?.body?.data?.seasons.length !== 0 &&
                            response?.body?.data?.seasons.length > 0
                        ) {
                            setSeasonErrorMsg('');
                            let seasonListTemp: any[] = [];
                            response.body.data.seasons.forEach(
                                (seasonData: any) => {
                                    seasonListTemp.push({
                                        seasonid: seasonData.cropseasonid,
                                        seasonname: seasonData.season,
                                    });
                                }
                            );
                            setSeasonList(seasonListTemp);
                        } else {
                            setSeasonErrorMsg(
                                'No seasons available for selected year'
                            );
                            setSeasonList([]);
                        }
                    }

                    let CropListTemp: any[] = [];
                    response.body.data.crops.forEach((cropData: any) => {
                        CropListTemp.push({
                            cropid: cropData.cropid,
                            cropname: cropData.cropname,
                        });
                    });
                    setCropList(CropListTemp);
                } else {
                    let tempDeviceArray: any[] = [];
                    let tempFasalArray: any[] = [];

                    response.body.data.devices.forEach((device: any) => {
                        tempDeviceArray.push(
                            device.devicelabel +
                                '__' +
                                device.deviceserialno +
                                '__' +
                                device.deviceid
                        );
                    });

                    response.body.data.devices.forEach((device: any) => {
                        tempFasalArray.push(
                            device.devicelabel +
                                '__' +
                                device.deviceserialno +
                                '__' +
                                device.deviceid +
                                '__' +
                                device.fasalplotid
                        );
                    });

                    setDeviceArray(tempDeviceArray);
                    setFasalArray(tempFasalArray);
                }

                let tempTypes = new Map();

                response.body.data.types.map((resp: any) => {
                    return tempTypes.set(resp.type, resp.value);
                });
                setTypeMap(tempTypes);
            } else {
                Alert('warning', 'Data not found');
            }
        });
    };

    function getDeviceLevelData() {
        setLoading(true);

        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? CountryData
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? selectedBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: selectedSeason,
            type: selectedAlert,
        };

        if (cropData && cropData !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: cropData },
            };
        }

        if (selectedDevice.length > 0) {
            let tempDeviceArr: string[] = [];
            selectedDevice.forEach((device: string) => {
                let deviceid = device.split('__')[2];
                tempDeviceArr.push(deviceid);
            });

            obj = {
                ...obj,
                ...{ deviceid: tempDeviceArr.join(',') },
            };
        }

        if (fromValue !== null) {
            obj = {
                ...obj,
                ...{ fromdate: fromDate },
            };
        }

        if (toValue !== null) {
            obj = {
                ...obj,
                ...{ todate: toDate },
            };
        }
        getRequest({
            url: 'report/devicelevel',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response.statusflag && response.code === 200) {
                    setDisplayData(response.body.data);


                    let tempCountryArray: any[] = [];
                    let countries = response.body.data.countries;
                    countries.forEach((country: any) => {
                        tempCountryArray.push(country);
                    });
                    setCountryChange(tempCountryArray);
                } else {
                    Alert('error', response.statusmessage);
                    setTableFlag(false);
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                setLoading(false);
            });
    }

    function getCropLevelData() {
        setLoading(true);

        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? CountryData
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? selectedBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: selectedSeason,
            type: selectedAlert,
        };

        if (cropData && cropData !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: cropData },
            };
        }

        if (selectedDevice.length > 0) {
            let tempDeviceArr: string[] = [];
            selectedDevice.forEach((device: string) => {
                let deviceid = device.split('__')[2];
                tempDeviceArr.push(deviceid);
            });
            obj = {
                ...obj,
                ...{ deviceid: tempDeviceArr.join(',') },
            };
        }

        if (fromDate !== null) {
            obj = {
                ...obj,
                ...{ fromdate: fromDate },
            };
        }

        if (toDate !== null) {
            obj = {
                ...obj,
                ...{ todate: toDate },
            };
        }
        getRequest({
            url: 'report/croplevel',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response.statusflag && response.code === 200) {
                    setDisplayCropData(response.body.data);
                    let tempCountryArray: any[] = [];
                    let countries = response.body.data.countries;
                    countries.forEach((country: any) => {
                        tempCountryArray.push(country);
                    });
                    setCountryChange(tempCountryArray);
                } else {
                    setLoading(false);
                    Alert('error', response?.statusmessage);

                }
            })
            .catch((error: any) => {
                setLoading(false);
            });
    }

    function getWeatherAnalyticsData() {
        setLoading(true);
        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? CountryData
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? selectedBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: selectedSeason,
            type: selectedAlert,
        };

        if (cropData && cropData !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: cropData },
            };
        }

        console.log('split',deviceWeatherDropDownData)
        if (deviceWeatherDropDownData !== 'select') {
            obj = {
                ...obj,
                ...{
                    deviceid: deviceWeatherDropDownData.split('__')[2],
                    plotid: deviceWeatherDropDownData.split('__')[3],
                },
            };
        }

        if (fromDate !== null) {
            obj = {
                ...obj,
                ...{ fromdate: fromDate },
            };
        }

        if (toDate !== null) {
            obj = {
                ...obj,
                ...{ todate: toDate },
            };
        }
        getRequest({
            url: 'report/analytics',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response && response.code === 200) {
                    let tempMonth: any[] = [];
                    let tempTemp: any[] = [];
                    let tempHumidity: any[] = [];
                    let tempRainfall: any[] = [];
                    let tempWind: any[] = [];
                    response.body.data.forEach((datadetails: any) => {
                        tempMonth.push(datadetails.month);
                        tempTemp.push(datadetails.temp);
                        tempHumidity.push(datadetails.humidity);
                        tempRainfall.push(datadetails.rainfall);
                        tempWind.push(datadetails.windspeed);
                    });

                    setMonthPlotData(tempMonth);
                    setTemperaturePlotData(tempTemp);
                    setHumidityPlotData(tempHumidity);
                    setRainfallPlotData(tempRainfall);
                    setWindPlotData(tempWind);

                    if (selectedAlert !== 'ALL') {
                        let Month: any[] = [];
                        let mini: any[] = [];
                        let max: any[] = [];
                        let avg: any[] = [];
                        let total: any[] = [];
                        response.body.data.forEach((value: any) => {
                            mini.push(value.min);
                            max.push(value.max);
                            avg.push(value.avg);
                            Month.push(value.month);
                            total?.push(value.total);
                        });

                        setMinimumData(mini);
                        setMaxData(max);
                        setAvgData(avg);
                        setMonthData(Month);
                        setTotalRainfall(total);
                    }
                } else {
                    setGraphFlag(false);
                    Alert('error', response?.statusmessage);
                }
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
            });
    }

    function getForecastDataDownload() {
        setType(
            currentReport === 'Forecast-24 Hours Report'
                ? 'Forecast-24 Hours Data'
                : 'Forecast-14 Days Data'
        );
        let obj: any = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadCountry
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadBusinessUnit
                    : loggedUser.lineofbusiness,
            isFiltered: true,
            seasonid: downloadSeason,
            reportname: downloadReportName,
        };

        if (downloadDeviceWeatherDropDownData !== 'select') {
            obj = {
                ...obj,
                ...{
                    deviceid: downloadDeviceWeatherDropDownData.split('__')[2],
                    plotid: downloadDeviceWeatherDropDownData.split('__')[3],
                },
            };
        }

        getRequest({
            url: 'report/forecast/download',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response.code === 200) {
                console.log(response.statusmessage);
            } else {
                Alert('error', response.statusmessage);
            }
        });
    }

    function getWeatherAnalyticsDataDownload() {
        setType('Weather_Analytics_Data');
        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadCountry
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: downloadSeason,
            type: downloadAlertType,
        };

        if (downloadCrop && downloadCrop !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: downloadCrop },
            };
        }
        if (downloadDeviceWeatherDropDownData !== 'select') {
            obj = {
                ...obj,
                ...{
                    deviceid: downloadDeviceWeatherDropDownData.split('__')[2],
                    plotid: downloadDeviceWeatherDropDownData.split('__')[3],
                },
            };
        }

        if (fromDate !== null) {
            obj = {
                ...obj,
                ...{ fromdate: downloadFromDate },
            };
        }

        if (toDate !== null) {
            obj = {
                ...obj,
                ...{ todate: downloadToDate },
            };
        }
        getRequest({
            url: 'report/analytics/download',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response.code === 200) {
                console.log(response.statusmessage);
            } else {
                Alert('error', response.statusmessage);
            }
        });
    }

    function getCropLevelDownloadData() {
        setType('Crop_data');
        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadCountry
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: downloadSeason,
            type: downloadAlertType,
        };

        if (downloadCrop && downloadCrop !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: downloadCrop },
            };
        }

        if (selectedDevice.length > 0) {
            let tempDeviceArr: string[] = [];
            downloadDevice.forEach((device: string) => {
                let deviceid = device.split('__')[2];
                tempDeviceArr.push(deviceid);
            });
            obj = {
                ...obj,
                ...{ deviceid: tempDeviceArr.join(',') },
            };
        }

        if (fromDate !== null) {
            obj = {
                ...obj,
                ...{ fromdate: downloadFromDate },
            };
        }

        if (toDate !== null) {
            obj = {
                ...obj,
                ...{ todate: downloadToDate },
            };
        }

        getRequest({
            url: 'report/croplevel/download',
            method: 'GET',
            queryParams: obj,
        }).then((res: any) => {
            if (res.code === 200) {
                console.log(res.statusmessage);
            } else {
                Alert('error', res.statusmessage);
            }
        });
    }

    const handleDeviceReportDownload = () => {
        if (reportData === 'Device Level Accuracy Report') {
            getDeviceReportData();
        } else if (reportData === 'Crop Level Accuracy Report') {
            getCropLevelDownloadData();
        } else if (reportData === 'Weather History Report') {
            getWeatherAnalyticsDataDownload();
        } else if (reportData === 'Forecast-24 Hours Report') {
            getForecastDataDownload();
        } else if (reportData === 'Forecast-14 Days Report') {
            getForecastDataDownload();
        }
    };

    const handleResetData = () => {
        setCountryData(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'select'
                : loggedUser?.countrycode
        );
        setSelectedBusinessUnit(
            loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'select'
                : loggedUser.lineofbusiness
        );
        setSelectedSeason('select');
        setCropData('select');
        setDeviceWeatherDropDownData('select');
        setFasalArray([]);
        setSelectedDevice([]);
        setReportData('select');
        setSelectedAlert('select');
        setSeasonList([]);
        setCropList([]);
        setDeviceArray([]);
        setBusinessUnitList([]);
        setCalendarSeasonYear(null);
        setFromValue(null);
        setToValue(null);
        setFromDate(null);
        setToDate(null);
        setTableFlag(false);
        setGraphFlag(false);
        setForecastFlag(false);
        setErrorMsg('');
        setCurrentReport('select');
        setCurrentAlertType('select');
        setAlertTypeDropdown([]);
        setDownloadReportName('');
        setDownloadCountry('');
        setDownloadBusinessUnit('');
        setDownloadCalendaryr('');
        setDownloadSeason('');
        setDownloadAlertType('');
        setDownloadFromDate('');
        setDownloadToDate('');
        setDownloadCrop('');
        setDownloadDevice('');
        setDownloadDeviceWeatherDropDownData('');
        setCountryChange([]);
        setSeasonErrorMsg('');
    };

    function getDeviceReportData(
        cropid?: any,
        deviceid?: any,
        fromdate?: any,
        todate?: any
    ) {
        setType('device_data');
        let obj = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadCountry
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? downloadBusinessUnit
                    : loggedUser.lineofbusiness,
            isfiltered: true,
            seasonid: downloadSeason,
            type: downloadAlertType,
        };

        if (downloadCrop && downloadCrop !== 'select') {
            obj = {
                ...obj,
                ...{ cropid: downloadCrop },
            };
        }

        if (selectedDevice.length > 0) {
            let tempDeviceArr: string[] = [];
            downloadDevice.forEach((device: string) => {
                let deviceid = device.split('__')[2];
                tempDeviceArr.push(deviceid);
            });
            obj = {
                ...obj,
                ...{ deviceid: tempDeviceArr.join(',') },
            };
        }

        if (fromDate !== null) {
            obj = {
                ...obj,
                ...{ fromdate: downloadFromDate },
            };
        }

        if (toDate !== null) {
            obj = {
                ...obj,
                ...{ todate: downloadToDate },
            };
        }
        getRequest({
            url: 'report/devicelevel/download',
            method: 'GET',
            queryParams: obj,
        }).then((res: any) => {
            if (res.code === 200) {
                console.log(res.statusmessage);
            } else {
                Alert('error', res.statusmessage);
            }
        });
    }

    const handleAlertChange = (event: any) => {
        setSelectedAlert(event.target.value);
        setSelectedDevice([]);
        setDeviceArray([]);
        setFasalArray([]);
        setCropData('select');
    };

    function getForecastData() {
        setLoading(true);
        let obj: any = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? CountryData
                    : loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? selectedBusinessUnit
                    : loggedUser.lineofbusiness,
        };

        if (deviceWeatherDropDownData !== 'select') {
            obj = {
                ...obj,
                ...{
                    deviceid: deviceWeatherDropDownData.split('__')[2],
                    fasalplotid: deviceWeatherDropDownData.split('__')[3],
                },
            };
        }

        getRequest({
            url: 'device/weatherinfo',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data && response.code === 200) {
                    setForecastData(response.body.data);
                    let forecastMonth: any[] = [];
                    let forecastTemp: any[] = [];
                    let forecastHumidity: any[] = [];
                    let forecastRainfall: any[] = [];
                    let forecastWind: any[] = [];
                    response.body.data[0].forecast24hours.forEach(
                        (forecast: any) => {
                            let time = '';
                            time = forecast.time.split(' ');
                            forecastMonth.push(time[1]);
                            forecastTemp.push(forecast.temp_c.toFixed(0));
                            forecastHumidity.push(forecast.humidity);
                            forecastRainfall.push(forecast.chance_of_rain);
                            forecastWind.push(forecast.wind_kph);
                        }
                    );

                    setMonthForecastData(forecastMonth);
                    setTemperatureForecastData(forecastTemp);
                    setHumidityForecastData(forecastHumidity);
                    setRainfallForecastData(forecastRainfall);
                    setWindForecastData(forecastWind);

                    let forecastDays: any[] = [];
                    let forecastDaysTemp: any[] = [];
                    let forecastDaysHumidity: any[] = [];
                    let forecastDaysRainfall: any[] = [];
                    let forecastDaysWind: any[] = [];
                    response.body.data[0].forecast14days.forEach(
                        (forecast: any) => {
                            forecastDays.push(forecast.date);
                            forecastDaysTemp.push(
                                forecast.day.avgtemp_c.toFixed(0)
                            );
                            forecastDaysHumidity.push(forecast.day.avghumidity);
                            forecastDaysRainfall.push(
                                forecast.day.chance_of_rain_derived
                            );
                            forecastDaysWind.push(forecast.day.maxwind_kph);
                        }
                    );

                    setDayForecastData(forecastDays);
                    setTemperatureForecast14Days(forecastDaysTemp);
                    setHumidityForecast14Days(forecastDaysHumidity);
                    setRainfallForecast14Days(forecastDaysRainfall);
                    setWindForecast14Days(forecastDaysWind);
                } else {
                    Alert('error', response.statusmessage);
                    setForecastFlag(false);
                }
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
            });
    }

    function getReport() {
        if (loading) return <Loader />;
        if (currentReport === 'Weather History Report') {
            return (
                <WeatherGraph
                    month={monthPlotData}
                    temp={temperaturePlotData}
                    rainfall={rainfallPlotData}
                    humidity={humidityPlotData}
                    windspeed={windPlotData}
                    mini={minimumData}
                    max={maxData}
                    avg={avgData}
                    totrain={totalRainfall}
                    Month={monthData}
                    currentAlertType={currentAlertType}
                    graphFlag={graphFlag}
                />
            );
        }

        if (currentReport === 'Forecast-24 Hours Report') {
            return (
                <ForecastGraph
                    month={monthForecastData}
                    temp={temperatureForecastData}
                    rainfall={rainfallForecastData}
                    humidity={humidityForecastData}
                    windspeed={windForecastData}
                    forecastFlag={forecastFlag}
                />
            );
        }

        if (currentReport === 'Forecast-14 Days Report') {
            return (
                <Forecast14Days
                    day={dayForecastData}
                    temp14days={temperatureForecast14Days}
                    rainfall14days={rainfallForecast14Days}
                    humidity14days={humidityForecast14Days}
                    windspeed14days={windForecast14Days}
                />
            );
        }

        if (
            currentReport === 'Device Level Accuracy Report' &&
            currentAlertType === 'DISEASE'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            height: '55vh',
                            borderRadius: '3px',
                            overflowX: 'scroll',
                        }}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        Device Owner
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Device Serial No
                                    </StyledTableCell>
                                    <StyledTableCell>Vendor</StyledTableCell>
                                    <StyledTableCell>Season</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Crop
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Received
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Approved
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Rejected
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Pending
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Accuracy (%)
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>

                            <TableBody>
                                {displayData &&
                                    displayData.map((data: any) => (
                                        <StyledTableRow>
                                            <TableCell align='center'>
                                                {data.deviceowner}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.deviceserialno}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.vendor}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.season}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.cropname}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.totaldiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.approveddiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.rejecteddiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pendingdiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.diseaseaccuracy}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        if (
            currentReport === 'Device Level Accuracy Report' &&
            currentAlertType === 'PEST'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            height: '55vh',
                            borderRadius: '3px',
                            overflowX: 'scroll',
                        }}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <TableRow>
                                    <StyledTableCell>
                                        Device Owner
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Device SerialNo
                                    </StyledTableCell>
                                    <StyledTableCell>Vendor</StyledTableCell>
                                    <StyledTableCell>Season</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Crop
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Received
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Approved
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Rejected
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Alerts Pending
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Accuracy (%)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {displayData &&
                                    displayData.map((data: any) => (
                                        <StyledTableRow>
                                            <TableCell align='center'>
                                                {data.deviceowner}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.deviceserialno}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.vendor}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.season}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.cropname}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.totalpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.approvedpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.rejectedpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pendingpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pestaccuracy}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        if (
            currentReport === 'Crop Level Accuracy Report' &&
            currentAlertType === 'DISEASE'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            height: '55vh',
                            borderRadius: '3px',
                            overflowX: 'scroll',
                        }}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <TableRow>
                                    <StyledTableCell align='center'>
                                        Crop Name
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Season
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Total Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Approved Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Rejected Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Pending Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                        Accuracy (%)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {displayCropData &&
                                    displayCropData.map((data: any) => (
                                        <StyledTableRow>
                                            <TableCell align='center'>
                                                {data.cropname}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.season}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.totaldiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.approveddiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.rejecteddiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pendingdiseasealerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.diseaseaccuracy}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        if (
            currentReport === 'Crop Level Accuracy Report' &&
            currentAlertType === 'PEST'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            height: '55vh',
                            borderRadius: '3px',
                            overflowX: 'scroll',
                        }}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <TableRow>
                                    <StyledTableCell align='center'>
                                        Crop Name
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Season
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Total Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Approved Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Rejected Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        Pending Alert
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                        Accuracy (%)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {displayCropData &&
                                    displayCropData.map((data: any) => (
                                        <StyledTableRow>
                                            <TableCell align='center'>
                                                {data.cropname}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.season}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.totalpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.approvedpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.rejectedpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pendingpestalerts}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {data.pestaccuracy}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        if (
            currentReport === 'Device Level Accuracy Report' &&
            currentAlertType === 'ALL'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            width: '100%',
                            overflowX: 'scroll',
                            borderRadius: '3px',
                        }}>
                        <Table aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <TableRow>
                                    <StyledTableCell sx={style}>
                                        Device Owner
                                    </StyledTableCell>
                                    <StyledTableCell sx={style}>
                                        Device SerialNo
                                    </StyledTableCell>
                                    <StyledTableCell sx={style}>
                                        Vendor
                                    </StyledTableCell>
                                    <StyledTableCell sx={style}>
                                        Season
                                    </StyledTableCell>
                                    <StyledTableCell align='center' sx={style}>
                                        Crop
                                    </StyledTableCell>
                                    <TableRow>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={style}>
                                            Alerts Received
                                            <StyledTableCell style={style}>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell style={style}>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={style}>
                                            Alerts Approved
                                            <StyledTableCell style={style}>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell style={style}>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={style}>
                                            Alerts Rejected
                                            <StyledTableCell style={style}>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell style={style}>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={style}>
                                            Alerts Pending
                                            <StyledTableCell style={style}>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell style={style}>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={style}>
                                            Accuracy (%)
                                            <StyledTableCell style={style}>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell style={style}>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {displayData &&
                                    displayData.map((data: any) => (
                                        <StyledTableRow>
                                            <StyledTableCell
                                                align='center'
                                                sx={style}>
                                                {data.deviceowner}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align='center'
                                                sx={style}>
                                                {data.deviceserialno}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align='center'
                                                sx={style}>
                                                {data.vendor}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align='center'
                                                sx={style}>
                                                {data.season}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align='center'
                                                sx={style}>
                                                {data.cropname}
                                            </StyledTableCell>

                                            <TableRow>
                                                <StyledTableCell
                                                    align='right'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={style}>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.totaldiseasealerts
                                                        }
                                                    </StyledTableAllCell>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {data.totalpestalerts}
                                                    </StyledTableAllCell>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align='right'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={style}>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.approveddiseasealerts
                                                        }
                                                    </StyledTableAllCell>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.approvedpestalerts
                                                        }
                                                    </StyledTableAllCell>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align='right'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={style}>
                                                    <StyledTableRejectedCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.rejecteddiseasealerts
                                                        }
                                                    </StyledTableRejectedCell>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.rejectedpestalerts
                                                        }
                                                    </StyledTableAllCell>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align='right'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={style}>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {
                                                            data.pendingdiseasealerts
                                                        }
                                                    </StyledTableAllCell>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {data.pendingpestalerts}
                                                    </StyledTableAllCell>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align='right'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={style}>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {data.diseaseaccuracy}
                                                    </StyledTableAllCell>
                                                    <StyledTableAllCell
                                                        style={style}
                                                        align='center'>
                                                        {data.pestaccuracy}
                                                    </StyledTableAllCell>
                                                </StyledTableCell>
                                            </TableRow>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        if (
            currentReport === 'Crop Level Accuracy Report' &&
            currentAlertType === 'ALL'
        ) {
            return (
                <div>
                    <TableContainer
                        sx={{
                            width: '100%',
                            overflowX: 'scroll',
                            borderRadius: '3px',
                        }}>
                        <Table aria-label='customized table'>
                            <TableHead
                                style={{
                                    backgroundColor: '#10384f',
                                    color: '#fff',
                                }}>
                                <TableRow>
                                    <StyledTableCell
                                        align='center'
                                        sx={stylecrop}>
                                        Crop Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align='center'
                                        sx={stylecrop}>
                                        Season
                                    </StyledTableCell>

                                    <TableRow>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={stylecrop}>
                                            Total Alert
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={stylecrop}>
                                            Approved Alert
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={stylecrop}>
                                            Rejected Alert
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={stylecrop}>
                                            Pending Alert
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align='center'
                                            className={classes.tableCell}
                                            sx={stylecrop}>
                                            Accuracy (%)
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Disease
                                            </StyledTableCell>
                                            <StyledTableCell
                                                sx={stylecrop}
                                                align='center'>
                                                Pest
                                            </StyledTableCell>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {displayCropData &&
                                    displayCropData.map((data: any) => (
                                        <StyledTableRow>
                                            <StyledTableCell
                                                align='center'
                                                sx={stylecrop}>
                                                {data.cropname}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align='center'
                                                sx={stylecrop}>
                                                {data.season}
                                            </StyledTableCell>
                                            <TableRow>
                                                <StyledTableCell
                                                    align='center'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={stylecrop}>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.totaldiseasealerts
                                                        }
                                                    </StyledTableALLCell>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {data.totalpestalerts}
                                                    </StyledTableALLCell>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align='center'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={stylecrop}>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.approveddiseasealerts
                                                        }
                                                    </StyledTableALLCell>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.approvedpestalerts
                                                        }
                                                    </StyledTableALLCell>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align='center'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={stylecrop}>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.rejecteddiseasealerts
                                                        }
                                                    </StyledTableALLCell>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.rejectedpestalerts
                                                        }
                                                    </StyledTableALLCell>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align='center'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={stylecrop}>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {
                                                            data.pendingdiseasealerts
                                                        }
                                                    </StyledTableALLCell>
                                                    <StyledTableALLCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {data.pendingpestalerts}
                                                    </StyledTableALLCell>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align='center'
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sx={stylecrop}>
                                                    <StyledTableAllCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {data.diseaseaccuracy}
                                                    </StyledTableAllCell>
                                                    <StyledTableAllCell
                                                        align='right'
                                                        sx={stylecrop}>
                                                        {data.pestaccuracy}
                                                    </StyledTableAllCell>
                                                </StyledTableCell>
                                            </TableRow>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        return '';
    }

    return (
        <div>
            <div>
                <TopHeader>
                    <h3>REPORTS</h3>
                </TopHeader>
            </div>

            <div style={{ paddingBottom: '1%' }}>
                <span
                    style={{
                        color: 'red',
                        fontFamily: 'appRegular',
                        fontSize: 13,
                        paddingBottom: 4,
                    }}>
                    {errorMsg}
                </span>
                {seasonErrorMsg.length > 0 && (
                    <div
                        style={{
                            color: 'red',
                            fontFamily: 'appRegular',
                            fontSize: 13,
                            paddingBottom: 4,
                        }}>
                        {seasonErrorMsg}
                    </div>
                )}
            </div>

            <div data-html2canvas-ignore="true">
                {loggedUser?.roleid === 'SUPER_ADMIN' ? (
                    <Stack direction='row' spacing={2} alignItems='baseline'>
                        <div className='stack_style'>
                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Report Name
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>

                                <Select
                                    value={reportData}
                                    onChange={handleReportChange}
                                    className='report-type-width'>
                                    <MenuItem value='select' disabled>
                                        <span className='select'>
                                            Select a Report
                                        </span>
                                    </MenuItem>
                                    {reportName &&
                                        reportName.map((report: any) =>
                                            report !== reportData ? (
                                                <MenuItem value={report}>
                                                    {report}
                                                </MenuItem>
                                            ) : (
                                                <MenuItem
                                                    disabled
                                                    value={report}>
                                                    {report}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </div>

                            {loggedUser?.roleid === 'SUPER_ADMIN' ? (
                                <div>
                                    <InputLabel className='label-report-header'>
                                        <div className='report-headerfont'>
                                            Country
                                        </div>
                                        <span className='compulsory'>*</span>
                                    </InputLabel>

                                    <Select
                                        value={CountryData}
                                        onChange={handleCountryChange}
                                        className='report-type-width'>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                Select a Country
                                            </span>
                                        </MenuItem>
                                        {countryChange &&
                                            countryChange.map((country: any) =>
                                                country.countrycode !==
                                                CountryData ? (
                                                    <MenuItem
                                                        value={
                                                            country.countrycode
                                                        }>
                                                        {country.country}
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        disabled
                                                        value={
                                                            country.countrycode
                                                        }>
                                                        {country.country}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </div>
                            ) : (
                                ''
                            )}
                            {loggedUser?.roleid === 'SUPER_ADMIN' ? (
                                <div>
                                    <InputLabel className='label-report-header'>
                                        <div className='report-headerfont'>
                                            Business Unit
                                        </div>
                                        <span className='compulsory'>*</span>
                                    </InputLabel>

                                    <Select
                                        value={selectedBusinessUnit}
                                        onChange={handleBusinessChange}
                                        className='report-type-width'>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                Select a BU
                                            </span>
                                        </MenuItem>
                                        {businessUnitList &&
                                            businessUnitList.map((bu: any) =>
                                                bu !== selectedBusinessUnit ? (
                                                    <MenuItem value={bu}>
                                                        {bu}
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        disabled
                                                        value={bu}>
                                                        {bu}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </div>
                            ) : (
                                ''
                            )}

                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Calendar Year
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>

                                <span>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        {loggedUser?.roleid === 'SUPER_ADMIN' &&
                                        selectedBusinessUnit === 'select' ? (
                                            <DatePicker
                                                disabled
                                                views={['year']}
                                                value={calendarSeasonYear}
                                                minDate={minDateSeason}
                                                maxDate={maxDateSeason}
                                                onChange={
                                                    handleSeasonCalendarChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='myDatePickeruser'
                                                        type='tel'
                                                        helperText={null}
                                                    />
                                                )}
                                            />
                                        ) : loggedUser?.roleid !==
                                              'SUPER_ADMIN' &&
                                          reportData === 'select' ? (
                                            <DatePicker
                                                disabled
                                                views={['year']}
                                                value={calendarSeasonYear}
                                                minDate={minDateSeason}
                                                maxDate={maxDateSeason}
                                                onChange={
                                                    handleSeasonCalendarChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='myDatePickeruser'
                                                        type='tel'
                                                        helperText={null}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <DatePicker
                                                views={['year']}
                                                value={calendarSeasonYear}
                                                minDate={minDateSeason}
                                                maxDate={maxDateSeason}
                                                onChange={
                                                    handleSeasonCalendarChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='myDatePickeruser'
                                                        type='tel'
                                                        helperText={null}
                                                    />
                                                )}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </span>
                            </div>

                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Season
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>
                                <Select
                                    value={selectedSeason}
                                    onChange={handleSeasonChange}
                                    className='report-type-width'>
                                    <MenuItem value='select' disabled>
                                        <span className='select'>
                                            Select a Season
                                        </span>
                                    </MenuItem>

                                    {calendarSeasonYear !== null &&
                                        seasonList.map((data: any) => (
                                            <MenuItem value={data.seasonid}>
                                                {data.seasonname}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </Stack>
                ) : (
                    <Stack direction='row' spacing={2} alignItems='baseline'>
                        <div className='stack_style'>
                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Report Name
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>

                                <Select
                                    value={reportData}
                                    onChange={handleReportChange}
                                    className='report-type-width'>
                                    <MenuItem value='select' disabled>
                                        <span className='select'>
                                            Select a Report
                                        </span>
                                    </MenuItem>
                                    {reportName &&
                                        reportName.map((report: any) =>
                                            report !== reportData ? (
                                                <MenuItem value={report}>
                                                    {report}
                                                </MenuItem>
                                            ) : (
                                                <MenuItem
                                                    disabled
                                                    value={report}>
                                                    {report}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </div>
                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Calendar Year
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>

                                <span>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        {reportData !== 'select' ? (
                                            <DatePicker
                                                views={['year']}
                                                value={calendarSeasonYear}
                                                minDate={minDateSeason}
                                                maxDate={maxDateSeason}
                                                onChange={
                                                    handleSeasonCalendarChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        helperText={null}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <DatePicker
                                                disabled
                                                views={['year']}
                                                value={calendarSeasonYear}
                                                minDate={minDateSeason}
                                                maxDate={maxDateSeason}
                                                onChange={
                                                    handleSeasonCalendarChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        helperText={null}
                                                    />
                                                )}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </span>
                            </div>

                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        Season
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>
                                <Select
                                    value={selectedSeason}
                                    onChange={handleSeasonChange}
                                    className='report-type-width'>
                                    <MenuItem value='select' disabled>
                                        <span className='select'>
                                            Select a Season
                                        </span>
                                    </MenuItem>

                                    {calendarSeasonYear !== null &&
                                        seasonList.map((data: any) => (
                                            <MenuItem value={data.seasonid}>
                                                {data.seasonname}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        {reportData !== 'Forecast-24 Hours Report' &&
                        reportData !== 'Forecast-14 Days Report' ? (
                            <div>
                                <InputLabel className='label-report-header'>
                                    <div className='report-headerfont'>
                                        {reportData ===
                                        'Weather History Report'
                                            ? 'Weather Parameter'
                                            : 'Alert Type'}
                                    </div>
                                    <span className='compulsory'>*</span>
                                </InputLabel>

                                <Select
                                    value={selectedAlert}
                                    className='alert-style-report'
                                    onChange={handleAlertChange}>
                                    <MenuItem value='select' disabled>
                                        <span className='select'>
                                            {reportData ===
                                            'Weather History Report'
                                                ? 'Select a Parameter'
                                                : 'Select a Alert Type'}
                                        </span>
                                    </MenuItem>

                                    {selectedSeason !== 'select' && alertTypeDropdown &&
                                        alertTypeDropdown.map((alert: any) =>
                                            alert !== selectedAlert ? (
                                                <MenuItem value={alert}>
                                                    {alert}
                                                </MenuItem>
                                            ) : (
                                                <MenuItem
                                                    disabled
                                                    value={alert}>
                                                    {alert}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </div>
                        ) : (
                            ''
                        )}
                    </Stack>
                )}
                {reportData !== 'Forecast-24 Hours Report' &&
                reportData !== 'Forecast-14 Days Report' ? (
                    loggedUser?.roleid === 'SUPER_ADMIN' ? (
                        <Stack
                            direction='row'
                            spacing={2}
                            alignItems='baseline'
                            pb={3}
                            pt={2}>
                            <div className='report_topdiv'>
                                <div>
                                    <InputLabel className='label-report-header'>
                                        <div className='report-headerfont'>
                                            {reportData ===
                                            'Weather History Report'
                                                ? 'Weather Parameter'
                                                : 'Alert Type'}
                                        </div>
                                        <span className='compulsory'>*</span>
                                    </InputLabel>

                                    <Select
                                        value={selectedAlert}
                                        className='alert-style-report'
                                        onChange={handleAlertChange}>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                {reportData ===
                                                'Weather History Report'
                                                    ? 'Select a Parameter'
                                                    : 'Select a Alert Type'}
                                            </span>
                                        </MenuItem>
                                        {selectedSeason !== 'select' && alertTypeDropdown &&
                                            alertTypeDropdown.map(
                                                (alert: any) =>
                                                    alert !== selectedAlert ? (
                                                        <MenuItem value={alert}>
                                                            {alert}
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            disabled
                                                            value={alert}>
                                                            {alert}
                                                        </MenuItem>
                                                    )
                                            )}
                                    </Select>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-season-crop-device'>
                                                From Date
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-season-crop-device'>
                                            From Date
                                        </div>
                                    )}

                                    <span>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={fromValue}
                                                minDate={minDate}
                                                maxDate={
                                                    toValue ? toValue : maxDate
                                                }
                                                onChange={handleFromDate}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        title='From date'
                                                        placeholder='mm/dd/yyyy'
                                                        helperText={null}
                                                        sx={{
                                                            width: '13rem',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </span>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-season-crop-device'>
                                                To Date
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-season-crop-device'>
                                            To Date
                                        </div>
                                    )}
                                    <span>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={toValue}
                                                onChange={handleToDate}
                                                minDate={
                                                    fromValue
                                                        ? fromValue
                                                        : minDate
                                                }
                                                maxDate={maxDate}
                                                views={['year', 'month', 'day']}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        title='To date'
                                                        placeholder='mm/dd/yyyy'
                                                        helperText={null}
                                                        sx={{
                                                            width: '13.1rem',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </span>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-device'>
                                                Crop
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-device'>
                                            Crop
                                        </div>
                                    )}

                                    <Select
                                        value={cropData}
                                        onChange={handleCropChange}
                                        className='reports-type-width'>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                Select a Crop
                                            </span>
                                        </MenuItem>

                                        {reportData ===
                                            'Weather History Report' &&
                                        toValue !== null
                                            ? cropList &&
                                              cropList.map((crop: any) =>
                                                  crop.cropid !== cropData ? (
                                                      <MenuItem
                                                          value={crop.cropid}>
                                                          {crop.cropname}
                                                      </MenuItem>
                                                  ) : (
                                                      <MenuItem
                                                          disabled
                                                          value={crop.cropid}>
                                                          {crop.cropname}
                                                      </MenuItem>
                                                  )
                                              )
                                            : ''}

                                        {reportData !==
                                            'Weather History Report' &&
                                            selectedAlert !== 'select' &&
                                            selectedSeason !== 'select' &&
                                            cropList &&
                                            cropList.map((crop: any) =>
                                                crop.cropid !== cropData ? (
                                                    <MenuItem
                                                        value={crop.cropid}>
                                                        {crop.cropname}
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        disabled
                                                        value={crop.cropid}>
                                                        {crop.cropname}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </div>

                                {reportData !== 'Weather History Report' ? (
                                    <div>
                                        <div className='report-headerfont-device'>
                                            Device
                                        </div>

                                        <Autocomplete
                                         classes={{
                                            input: classe.autoComplete,
                                            option: classe.autoComplete
                                          }}
                                            multiple
                                            limitTags={1}
                                            id='tags-standard-device'
                                            options={deviceArray}
                                            value={selectedDevice}
                                            getOptionLabel={(
                                                device: string
                                            ) => {
                                                return (
                                                    device.split('-')[0] +
                                                    '-' +
                                                    device.split('-')[1]
                                                );
                                            }}
                                            onChange={(
                                                event: any,
                                                newValue: any | null
                                            ) => {
                                                setSelectedDevice(newValue);
                                            }}
                                            disableCloseOnSelect
                                            sx={{
                                                width: '18.5%',
                                                paddingLeft: '2px',
                                                verticalAlign: 'middle',
                                            }}
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li {...props} >
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={
                                                            checkedIcon
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{
                                                        verticalAlign: 'unset',
                                                        width: '12.3rem',
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div>
                                            {reportData ===
                                            'Weather History Report' ? (
                                                <InputLabel className='label-report-header'>
                                                    <div className='report-headerfont-device'>
                                                        Device
                                                    </div>
                                                    <span className='compulsory'>
                                                        *
                                                    </span>
                                                </InputLabel>
                                            ) : (
                                                <div className='report-headerfont-device'>
                                                    Device
                                                </div>
                                            )}
                                            <Select
                                                value={
                                                    deviceWeatherDropDownData
                                                }
                                                onChange={
                                                    handleDeviceWeatherChange
                                                }
                                                className='reports-type-width-fasal'>
                                                <MenuItem
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Device
                                                    </span>
                                                </MenuItem>

                                                {fasalArray &&
                                                    fasalArray.map(
                                                        (device: any) =>
                                                            deviceWeatherDropDownData !==
                                                            device ? (
                                                                <MenuItem
                                                                    value={
                                                                        device
                                                                    }>
                                                                    {device.split(
                                                                        '__'
                                                                    )[0] +
                                                                        '-' +
                                                                        device.split(
                                                                            '__'
                                                                        )[1]}
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    disabled
                                                                    value={
                                                                        device
                                                                    }>
                                                                    {device.split(
                                                                        '__'
                                                                    )[0] +
                                                                        '-' +
                                                                        device.split(
                                                                            '__'
                                                                        )[1]}
                                                                </MenuItem>
                                                            )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Stack>
                    ) : (
                        <Stack
                            direction='row'
                            spacing={2}
                            alignItems='baseline'
                            pb={1}
                            pt={1}>
                            <div className='report_topdiv'>
                                <div>
                                    {reportData ===
                                    'Weather Analytics Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-season-crop-device'>
                                                From Date
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-season-crop-device'>
                                            From Date
                                        </div>
                                    )}
                                    <span>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={fromValue}
                                                minDate={minDate}
                                                maxDate={
                                                    toValue ? toValue : maxDate
                                                }
                                                onChange={handleFromDate}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        title='From date'
                                                        placeholder='mm/dd/yyyy'
                                                        helperText={null}
                                                        sx={{
                                                            width: '13.1rem',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </span>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-season-crop-device'>
                                                To Date
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-season-crop-device'>
                                            To Date
                                        </div>
                                    )}
                                    <span>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={toValue}
                                                onChange={handleToDate}
                                                minDate={
                                                    fromValue
                                                        ? fromValue
                                                        : minDate
                                                }
                                                maxDate={maxDate}
                                                views={['year', 'month', 'day']}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type='tel'
                                                        title='To date'
                                                        placeholder='mm/dd/yyyy'
                                                        helperText={null}
                                                        sx={{
                                                            width: '12.2rem',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </span>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <InputLabel className='label-report-header'>
                                            <div className='report-headerfont-device'>
                                                Crop
                                            </div>
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        </InputLabel>
                                    ) : (
                                        <div className='report-headerfont-device'>
                                            Crop
                                        </div>
                                    )}
                                    <Select
                                        value={cropData}
                                        onChange={handleCropChange}
                                        className='report-nonsuperadmin-type-width'>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                Select a Crop
                                            </span>
                                        </MenuItem>

                                        {reportData ===
                                            'Weather History Report' &&
                                        toValue !== null
                                            ? cropList &&
                                              cropList.map((crop: any) =>
                                                  crop.cropid !== cropData ? (
                                                      <MenuItem
                                                          value={crop.cropid}>
                                                          {crop.cropname}
                                                      </MenuItem>
                                                  ) : (
                                                      <MenuItem
                                                          disabled
                                                          value={crop.cropid}>
                                                          {crop.cropname}
                                                      </MenuItem>
                                                  )
                                              )
                                            : ''}

                                        {reportData !==
                                            'Weather History Report' &&
                                            selectedAlert !== 'select' &&
                                            selectedSeason !== 'select' &&
                                            cropList &&
                                            cropList.map((crop: any) =>
                                                crop.cropid !== cropData ? (
                                                    <MenuItem
                                                        value={crop.cropid}>
                                                        {crop.cropname}
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        disabled
                                                        value={crop.cropid}>
                                                        {crop.cropname}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </div>

                                <div>
                                    {reportData ===
                                    'Weather History Report' ? (
                                        <div>
                                            {reportData ===
                                            'Weather History Report' ? (
                                                <InputLabel className='label-report-header'>
                                                    <div className='report-headerfont-device'>
                                                        Device
                                                    </div>
                                                    <span className='compulsory'>
                                                        *
                                                    </span>
                                                </InputLabel>
                                            ) : (
                                                <div className='report-headerfont-device'>
                                                    Device
                                                </div>
                                            )}
                                            <Select
                                                value={
                                                    deviceWeatherDropDownData
                                                }
                                                onChange={
                                                    handleDeviceWeatherChange
                                                }
                                                className='reports-type-width-fasal'>
                                                <MenuItem
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Device
                                                    </span>
                                                </MenuItem>

                                                {fasalArray &&
                                                    fasalArray.map(
                                                        (device: any) =>
                                                            deviceWeatherDropDownData !==
                                                            device ? (
                                                                <MenuItem
                                                                    value={
                                                                        device
                                                                    }>
                                                                    {device.split(
                                                                        '__'
                                                                    )[0] +
                                                                        '-' +
                                                                        device.split(
                                                                            '__'
                                                                        )[1]}
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    disabled
                                                                    value={
                                                                        device
                                                                    }>
                                                                    {device.split(
                                                                        '__'
                                                                    )[0] +
                                                                        '-' +
                                                                        device.split(
                                                                            '__'
                                                                        )[1]}
                                                                </MenuItem>
                                                            )
                                                    )}
                                            </Select>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className='report-headerfont-device'>
                                                Device
                                            </div>

                                            <Autocomplete
                                             classes={{
                                                input: classe.autoComplete,
                                                option: classe.autoComplete
                                              }}
                                                multiple
                                                limitTags={1}
                                                id='tags-standard-device'
                                                options={deviceArray}
                                                value={selectedDevice}
                                                getOptionLabel={(
                                                    device: string
                                                ) => {
                                                    return (
                                                        device.split('-')[0] +
                                                        '-' +
                                                        device.split('-')[1]
                                                    );
                                                }}
                                                onChange={(
                                                    event: any,
                                                    newValue: any | null
                                                ) => {
                                                    setSelectedDevice(newValue);
                                                }}
                                                disableCloseOnSelect
                                                sx={{
                                                    width: '18.5%',
                                                    paddingLeft: '2px',
                                                    verticalAlign: 'middle',
                                                }}
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                                checkedIcon
                                                            }
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{
                                                            verticalAlign:
                                                                'unset',
                                                            width: '12.7rem',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Stack>
                    )
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '1.5%',
                            paddingTop: '1%',
                        }}>
                        <div>
                            <InputLabel className='label-report-header'>
                                <div className='report-headerfont-device'>
                                    Crop
                                </div>
                                <span className='compulsory'>*</span>
                            </InputLabel>

                            <Select
                                value={cropData}
                                onChange={handleCropChange}
                                className='reports-type'>
                                <MenuItem value='select' disabled>
                                    <span className='select'>
                                        Select a Crop
                                    </span>
                                </MenuItem>

                                {selectedSeason !== 'select' && cropList &&
                                    cropList.map((crop: any) =>
                                        crop.cropid !== cropData ? (
                                            <MenuItem value={crop.cropid}>
                                                {crop.cropname}
                                            </MenuItem>
                                        ) : (
                                            <MenuItem
                                                disabled
                                                value={crop.cropid}>
                                                {crop.cropname}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </div>

                        <div>
                            <InputLabel className='label-report-header'>
                                <div className='report-headerfont-device'>
                                    Device
                                </div>
                                <span className='compulsory'>*</span>
                            </InputLabel>

                            <Select
                                value={deviceWeatherDropDownData}
                                onChange={handleDeviceWeatherChange}
                                className={
                                    loggedUser?.roleid === 'SUPER_ADMIN'
                                        ? 'reports-type-width-fasal'
                                        : 'reports-type-width-fasl'
                                }>
                                <MenuItem value='select' disabled>
                                    <span className='select'>
                                        Select a Device
                                    </span>
                                </MenuItem>

                                {fasalArray &&
                                    fasalArray.map((device: any) =>
                                        deviceWeatherDropDownData !== device ? (
                                            <MenuItem value={device}>
                                                {device.split('__')[0] +
                                                    '-' +
                                                    device.split('__')[1]}
                                            </MenuItem>
                                        ) : (
                                            <MenuItem disabled value={device}>
                                                {device.split('__')[0] +
                                                    '-' +
                                                    device.split('__')[1]}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </div>
                    </div>
                )}

                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='baseline'
                    pb={1}
                    justifyContent='center'>
                    <Button
                        className='button-reset-report'
                        variant='contained'
                        color='success'
                        onClick={handleButtonClick}>
                        Get Data
                    </Button>

                    <Button
                        variant='contained'
                        onClick={handleResetData}
                        className='button-reset-report'>
                        Clear
                    </Button>
                    {tableFlag === true ||
                    graphFlag === true ||
                    forecastFlag === true ? (
                        <Item>
                            <FileDownloadOutlinedIcon
                                sx={{ height: '0.8em', fontSize: 'larger' }}
                                onClick={handleDeviceReportDownload}
                            />
                        </Item>
                    ) : (
                        ''
                    )}
                    {graphFlag === true ||
                    forecastFlag === true ? (
                        <Item>
                            <CameraEnhanceIcon
                                sx={{ height: '0.8em', fontSize: 'larger' }}
                                onClick={capture}
                            />
                        </Item>
                    ) : (
                        ''
                    )}
                </Stack>
            </div>

            {currentReport === 'Weather History Report'
                ? graphFlag && getReport()
                : currentReport === 'Forecast-24 Hours Report'
                ? forecastFlag && getReport()
                : currentReport === 'Forecast-14 Days Report'
                ? forecastFlag && getReport()
                : tableFlag && getReport()}
        </div>
    );
}

export default ReportScreenIndex;
