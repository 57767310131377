/** @format */

import FormHelperText from '@mui/material/FormHelperText';
import ErrorImg from '../assets/img/svg/actions/error_outline.svg';
import ChilliSvg from '../assets/img/crops/chilli.svg';
import TomatoSvg from '../assets/img/crops/tomato.svg';
import RiceSvg from '../assets/img/crops/rice.svg';
import CornSvg from '../assets/img/crops/corn.svg';
import { getLocalStorageData } from './useLocalStorage';
import { getRequest } from '../services/apiUtils';
import { Alert } from './toastr';
import dayjs from 'dayjs';

/**
 * Function to display the input errors
 * @param {*} object
 */
export const displayError = (message: any) => {
    return (
        message && (
            <FormHelperText className='error-txt'>
                <img src={ErrorImg} height='15' alt='error' />
                <label>{message}</label>
            </FormHelperText>
        )
    );
};

/**
 * Default table params
 */
export function tableParams() {
    return { limit: 10, offset: 0 };
}

export function findCropImg(value: string) {
    switch (value) {
        case 'TOMATO':
            return TomatoSvg;
        case 'CHILLI':
            return ChilliSvg;
        case 'CORN':
            return CornSvg;
        case 'RICE':
            return RiceSvg;
        default:
            return 'NA';
    }
}

export const optionList = (array: Array<any>, value: string, label: string) => {
    const data: any =
        array?.length > 0 &&
        array.map((val: any) => {
            return { value: val[value], label: val[label] };
        });
    return data;
};

/**
 * If getting ALL value in dropdown option and replace the null value
 * @param filters
 */
export const assignedNull = (filters: any) => {
    let obj = {};
    for (let key in filters) {
        obj = {
            ...obj,
            [key]:
                filters[key] === 'ALL' && key !== 'lob' ? null : 
                filters[key],
        };
    }
    return { ...obj };
};

const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
];

const getFormattedTime = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = monthsArray[today.getMonth()];
    const date = ('0' + today.getDate()).slice(-2);
    const hours = today.getHours();
    const min = ('0' + today.getMinutes()).slice(-2);
    const seconds = ('0' + today.getSeconds()).slice(-2);

    return `${date}${month}${year} ${hours}${min}${seconds}`;
};

let type: any = null;

export const toDownload = (res: any) => {
    res.blob().then((blob: any) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${type} ${getFormattedTime()}`;
        alink.click();
    });
};

export function setType(typeVal: string) {
    type = typeVal
}

export const download = (
    search: string | null,
    isFiltered?: boolean,
    listType?: string,
    alertType?: string,
    filter?: any,
    status?: string,
    customerType?: string,
    id?: any,
    year?: any
) => {
    type = listType?.toUpperCase();
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    //let params = tableParams();
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    let obj: any = {
        countrycode: filter?.country ? filter.country : loggeduser?.countrycode,
        loggeduserrole: loggeduser?.roleid,
        lob: filter?.lob ? filter.lob : loggeduser?.lineofbusiness,
        search: search?.length ? search : null,
        isfiltered: isFiltered,
    };


    
    if (loggeduser?.roleid === 'SUPER_ADMIN' && !isFiltered) {
        obj = {
            ...obj,
            'lob': 'ALL',
            'isfiltered':false,
        };
    } else if (loggeduser?.roleid === 'SUPER_ADMIN' && isFiltered) {
        obj = {
            ...obj,
            'lob': filter.lob,
            'isfiltered':true,
        };
    }

    if(loggeduser?.roleid !== 'SUPER_ADMIN'){
        obj = {
            ...obj,
            'lob': loggeduser.lineofbusiness,
            'isfiltered':true,
        };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            isfiltered: true,
            [obj.countrycode === 'TH'
                ? 'geolevel3code'
                : 'geolevel4code']: location.join(','),
        };
    }

    if (listType === 'user') {
        obj = {
            ...params,
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lob,
            role: loggeduser?.roleid,
            search: search?.length ? search : null,
            isfiltered: isFiltered,
        };
    }

    if (isFiltered) {
        let condFilter = assignedNull(filter);
        obj = { ...obj, ...condFilter };
    }

    if (listType === 'alert') {
        obj = { ...obj, alerttype: alertType, status: status };
        type = alertType?.toUpperCase();
    }

    if (listType === 'customer') {
        obj = {
            ...obj,
            customertype: customerType,
            // status: status
        };
        if (status !== 'ALL') {
            obj = { ...obj, status };
        }
        // getSpecialType(customerType)
        type = customerType?.toUpperCase();
    }

    if (listType === 'cropstage') {
        obj = {
            countrycode: loggeduser?.countrycode,
            cropid: id && id,
            lineofbusiness: loggeduser?.lineofbusiness,
            year: year && year,
        };

        type = `${loggeduser?.countrycode}-${loggeduser?.lineofbusiness}-CropStageDetail-Report`;
    }

    if (listType === 'dashboard') {
        obj = {
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lob,
            role: loggeduser?.roleid,
        };
    }

    getRequest({
        url:
            listType === 'dashboard'
                ? `${listType}/view/download`
                : listType === 'cropstage'
                ? `${listType}/details/download`
                : `${listType}/list/download`,
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

export const downloadUserManagement = (
    search: string | null,
    isFiltered?: boolean,
    listType?: string,
    Role?: any,
    filter?:any
) => {
    type = listType?.toUpperCase();
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    type = `${loggeduser?.countrycode}-${loggeduser?.lineofbusiness}-UserManagement-Report`;
    let obj: any = {
        countrycode: filter?.country ? filter.country : loggeduser?.countrycode,
        lob: filter?.lob ? filter.lob : loggeduser?.lineofbusiness,
        loggeduserrole: loggeduser?.roleid,
        search: search?.length ? search : null,
        isfiltered: isFiltered,
    };

    if (Role && Role !== 'ALL') {
        obj = {
            ...obj,
            role: Role,
        };
    }

    if (isFiltered) {
        let condFilter = assignedNull(filter);
        obj = { ...obj, ...condFilter };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            isfiltered: true,
            [obj.countrycode === 'TH'
                ? 'geolevel3code'
                : 'geolevel4code']: location.join(','),
        };
    }
    
    getRequest({
        url: 'user/list/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];


export const downloadMapDashboard = (
    isFiltered: boolean,
    searchTerm: string | null,
    filter?: any
) => {
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    type = 'MapDashbaord-Report';

    let obj: any = {
        countrycode: filter?.country ? filter.country : loggeduser?.countrycode,
        loggeduserrole: loggeduser?.roleid,
        search: searchTerm,
    };

    if (searchTerm) {
        obj = {
            ...obj,
            search: searchTerm,
            ...{ type: 'map' },
        };
    }

    if (isFiltered) {
        const filterValues = assignedNull(filter);
        obj = {
            ...obj,
            isfiltered: true,
            ...filterValues,
        };
    } else {
        obj = {
            ...obj,
            lob: loggeduser?.roleid === 'SUPER_ADMIN' ? 'ALL' : filter.lob,
        };
    }

    if(loggeduser?.roleid !== 'SUPER_ADMIN'){
        obj = {
            ...obj,
            'lob': loggeduser.lineofbusiness,
            'isfiltered':true,
        };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            ...{
                'isfiltered': true,
                [obj.countrycode === 'TH'
                    ? 'geolevel3code'
                    : 'geolevel4code']: location.join(','),
            },
        };
    }

    if (isFiltered) {
        let condFilter = assignedNull(filter);

        obj = { ...obj, ...condFilter };
    }

    getRequest({
        url: 'dashboard/mapview/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

export const downloadSuperAdmin = (
    search: string | null,
    listType?: string,
    Role?: any
) => {
    type = listType?.toUpperCase();
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = 'SUPER_ADMIN';
    type = `${loggeduser?.countrycode}-${loggeduser?.lineofbusiness}-SuperAdmin-Report`;
    let obj: any = {
        countrycode: 'NA',
        loggeduserrole: loggeduser?.roleid,
        search: search?.length ? search : null,
    };

    getRequest({
        url: 'superadmin/list/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

export const downloadAlertPage = (
    search: string | null,
    isFiltered: boolean,
    alertType?: string,
    filter?: any
) => {
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    //let params = tableParams();
    type =
        alertType === 'DISEASE_PREDICTION'
            ? 'Disease_Alert'
            : alertType === 'PEST_PREDICTION'
            ? 'Pest_Alert'
            : 'Weather_Alert';

    let obj: any = {
        countrycode: loggeduser?.countrycode,
        lob: loggeduser?.lineofbusiness,
        loggeduserrole: loggeduser?.roleid,
        search: search || null,
        messagetype: alertType,
        isfiltered: isFiltered,
        sortby: 'createddate',
    };

    
    if (loggeduser?.roleid === 'SUPER_ADMIN' && !isFiltered) {
        obj = {
            ...obj,
            'lob': 'ALL',
            'isfiltered':false,
        };
    } else if (loggeduser?.roleid === 'SUPER_ADMIN' && isFiltered) {
        obj = {
            ...obj,
            'lob': filter.lob,
            'isfiltered':true,
        };
    }

    if(loggeduser?.roleid !== 'SUPER_ADMIN'){
        obj = {
            ...obj,
            'lob': loggeduser.lineofbusiness,
            'isfiltered':true,
        };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            isfiltered: true,
        };
        obj.countrycode === 'TH'
            ? (filter.geolevel3code = location.join(','))
            : (filter.geolevel4code = location.join(','));
    }

    obj = { ...obj, ...filter };
    getRequest({
        url: 'alert/list/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

export const downloadAdvisoryPage = (
    searchTerm?: any,
    dateFilter?: any,
    isFiltered?: boolean,
    filter?: any
) => {
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];

    //let params = tableParams();
    type = 'Custom_Advisory';
    let customAdvisoryParams: any = {
        countrycode: loggeduser?.countrycode,
        lob: loggeduser?.lineofbusiness,
        loggeduserrole: loggeduser?.roleid,
        sortby: 'createddate',
    };

    if (dateFilter) {
        customAdvisoryParams = {
            ...customAdvisoryParams,
            monthyearfilter: dateFilter,
        };
    }

    if (searchTerm) {
        customAdvisoryParams = {
            ...customAdvisoryParams,
            search: searchTerm,
        };
    }

    if (isFiltered) {
        const filterValues = assignedNull(filter);
        customAdvisoryParams = {
            ...customAdvisoryParams,
            isfiltered: true,
            ...filterValues,
        };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        customAdvisoryParams = {
            ...customAdvisoryParams,
            ['isfiltered']: true,
            [customAdvisoryParams.countrycode === 'TH'
                ? 'geolevel3code'
                : 'geolevel4code']: location.join(','),
        };
    }

    getRequest({
        url: 'advisory/list/download',
        method: 'GET',
        queryParams: customAdvisoryParams,
    }).then((res: any) => {
        if (res.code === 200) {
        } else {
            Alert('error', res.statusmessage);
        }
    });
};

export const getCropCalendarDownoad = (
    cropid: any,
    search: any,
    cropCalenID:any,
    monthyearfilter:any,
    fromdate?:any,
    todate?:any
) => {
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];


    type = 'Crop_Calendar';
    let obj: any = {
        countrycode: loggeduser?.countrycode,
        lob: loggeduser?.roleid === 'SUPER_ADMIN' ? 
                'ALL' : loggeduser?.lineofbusiness,
        loggeduserrole: loggeduser?.roleid,
        isfiltered: true,
        search: search,
    };
    if (cropid && cropid !== null) {
        obj = {
            ...obj,
            ...{ cropid: cropid },
        };
    }

if(fromdate === null && todate === null){
    obj = {
        ...obj,
        'monthyearfilter':monthyearfilter
    };
}else {
    obj = {
        ...obj,
        'fromdate':fromdate,
        'todate':todate,
    };
}


    if (cropCalenID && cropCalenID !== null) {
        obj = {
            ...obj,
            ...{ cropcalendarid : cropCalenID },
        };
    }
    

    
    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            ['isfiltered']: true,
            [obj.countrycode === 'TH'
                ? 'geolevel3code'
                : 'geolevel4code']: location.join(','),
        };
    }

    getRequest({
        url: 'cropcalendar/list/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if (res.code === 200) {
            console.log(res.statusmessage);
        } else {
            Alert('error', res.statusmessage);
        }
    })
};

export const downloadWeatherReport = (fasalplotid: any, deviceid: any) => {
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];

    type = `${loggeduser?.countrycode}-${loggeduser?.lineofbusiness}-WeatherInfo-Report`;
    let obj: any = {
        countrycode: loggeduser?.countrycode,
        lob: loggeduser?.lineofbusiness,
        loggeduserrole: loggeduser?.roleid,
    };

    if (fasalplotid && fasalplotid !== null) {
        obj = {
            ...obj,
            ...{ fasalplotid: fasalplotid },
        };
    }

    if (deviceid && deviceid !== null) {
        obj = {
            ...obj,
            ...{ deviceid: deviceid },
        };
    }

    if (
        specificPersona.includes(loggeduser?.roleid) &&
        loggeduser.assignedloc.length > 0
    ) {
        let location = loggeduser.assignedloc.map(
            (loc: any) => loc.lochiergeocode
        );
        obj = {
            ...obj,
            ['isfiltered']: true,
            [obj.countrycode === 'TH'
                ? 'geolevel3code'
                : 'geolevel4code']: location.join(','),
        };
    }
    
    getRequest({
        url: 'device/weatherinfo/download',
        method: 'GET',
        queryParams: obj,
    }).then((res: any) => {
        if(res === undefined){
        Alert('success', 'Download Successful');
        }
        else{
            Alert('error','Cannot download data for INACTIVE device');
        }
    });
};





export const anyKey = (
    event: any,
    search: string,
    setIsApplyFilter: any,
    setSearchParams: any,
    searchParams: any,
    setParams?: any,
    status?: string,
    setMakeAPICall?: any
) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        if (search.length === 0) {
            searchParams.delete('search');
            // setSearchParams({...searchParams})
            setSearchParams(Object.fromEntries([...searchParams]));
            // setSearchParams({status})

            // setIsApplyFilter(true);
            setParams(tableParams());
        }
    } else if (event.key === 'Enter') {
        event.preventDefault();
        // setSearchParams({search})
        if (search.length >= 3) {
            // setSearchParams({...searchParams, search: search})
            searchParams.append('search', search);
            // setSearchParams({...searchParams})
            setSearchParams({ ...Object.fromEntries([...searchParams]) });

            // setIsApplyFilter(true);
            setParams(tableParams());
        }
    }
};

export function titleCase(str: any) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}
