import React from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

function Forecast14Days(props:any) {
    const forecast14day = {
        labels: props.day,
        datasets: [
            {
                label: 'Temperature (in ℃)',
                data: props.temp14days,
                fill: true,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor:'rgba(255, 205, 86, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,

            },
            {
                label: 'Rainfall (in mm)',
                data: props.rainfall14days,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
            {
                label: 'Humidity (in %)',
                data: props.humidity14days,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor:'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
            {
                label: 'Windspeed (in km/h)',
                data: props.windspeed14days,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
        ],
    };


      
    const options14day = {
        
        plugins: {
            tooltip: {
              callbacks: {
                label: function(item:any) {
                    var datasetIndex = item.datasetIndex;
                    const units:any = {
                      0: " ℃",
                      1: " mm",
                      2: "%",
                      3:' km/h'
                    }
          
                    var unit = units[datasetIndex];
                    return `${item.formattedValue}${unit}`;
                  },
              }
            },
        
    }
      };
   

      
    return (
        <div>
           
             <div className='App'>
             <Line data={forecast14day} options={options14day} />
         </div>
        </div>
    )
  
}

export default Forecast14Days;