/** @format */
import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    styled,
    InputLabel,
    InputBase,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
    FormHelperText,
    Button,
} from '@mui/material';
import { ReactComponent as RightNext } from '../../../assets/img/svg/country-config/right_next.svg';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
//Redux actions
import {
    basicInfoProvided,
    getCountryDetails,
    locationInputValuesProvided,
    resetBasicInfo,
    resetLocationInputs,
} from '../../../redux/actions/createUser/create';
import { connect } from 'react-redux';
//Import CSS
import './BasicInfo.scss';
import { getRequest } from '../../../services/apiUtils';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { Alert } from '../../../utils/toastr';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '0.5px solid #919191',
        fontSize: 13,
        // color: '#1A1A1A',
        width: '170.67px',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: 'appRegular',
    },
}));
const BasicInfo = (props: any) => {
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const userList = ['Farmer'];

    const channelModes = ['SMS', 'WhatsApp'];

    const forecastModes = ['Daily Forecast Alert', 'Yesterday Forecast Alert'];

    const [countryData, setCountryData] = useState<any>([]);
    const [countryToggler, setCountryToggler] = useState<boolean>(false);

    const singleSelectInitialState = [
        {
            type: 'gender',
            options: ['Male', 'Female', 'Others'],
            label: 'Gender',
        },
        {
            type: 'language',
            options: [],
            label: 'Preferred Language',
        },
    ];

    const [singleSelect, setSingleSelect] = useState(singleSelectInitialState);

    const [roleToggler, setRoleToggler] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [genderToggler, setGenderToggler] = useState(false);

    const [channelModeToggler, setChannelModeToggler] = useState<boolean>(
        false
    );

    const [countrySelected, setCountrySelected] = useState<string>(
        props.basicInfo.countryCode !== ''
            ? props.basicInfo.countryCode
            : loggedUser?.countrycode
    );
    const [loadDropDown, setLoadDropDown] = useState<any>(false);
    const [makeApiCall, setMakeApiCall] = useState<any>(false);

    const [forecastToggler, setForecastToggler] = useState<boolean>(false);

    const [errorFieldsArray, setErrorFieldsArray] = useState<string[]>([]);

    const [err, setErr] = useState<string>('');

    useEffect(() => {
        getLookup();
    }, []);

    useEffect(() => {
        if (makeApiCall) {
            getLookup();
            setMakeApiCall(false);
        }
    }, [makeApiCall]);

    const getLookup = () => {
        let obj: any = {
            countrycode:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? countrySelected
                    : loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
        };
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    var tempSingleSelect = singleSelect;

                    tempSingleSelect[1].options =
                        response?.body?.data.languages;

                    setSingleSelect(tempSingleSelect);

                    setCountryData(response?.body?.data?.countries);

                    response?.body?.data?.countries.forEach((country: any) => {
                        if (country.countrycode === countrySelected) {
                            props.basicInfoChanged(
                                'countrycode',
                                country.countrycode
                            );

                            props.basicInfoChanged(
                                'countryname',
                                country.country
                            );
                        }
                    });
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getLanguages = (country: any) => {
        let obj: any = {
            countrycode: country,
            lob: loggedUser?.lineofbusiness,
        };
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    var tempSingleSelect = singleSelect;

                    tempSingleSelect[1].options =
                        response?.body?.data.languages;

                    setSingleSelect(tempSingleSelect);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    let nextButtonClicked = false;

    const handlePageNext = () => {
        let valid = false;
        let compulsoryInputFieldsArr = props.basicInfo.inputFields.filter(
            (inputField: any) => inputField.optional === false
        );
        let phoneNoLength = 0;
        for (let i = 0; i < compulsoryInputFieldsArr.length; i++) {
            if (compulsoryInputFieldsArr[i].id === 'phoneNo') {
                phoneNoLength = compulsoryInputFieldsArr[i].value.length;
            }
        }
        let errorInputFields: any = compulsoryInputFieldsArr.filter(
            (inputField: any) => inputField.value.trim().length === 0
        );

        props.basicInfo.role === '' && errorInputFields.push('role');
        props.basicInfo.languageSelected === '' &&
            errorInputFields.push('language');

        if (props.basicInfo.countryCode === 'TH') {
            errorInputFields.length !== 0 || phoneNoLength !== 9
                ? (valid = false)
                : (valid = true);
        } else {
            errorInputFields.length !== 0 || phoneNoLength !== 10
                ? (valid = false)
                : (valid = true);
        }

        if (valid) {
            setErr('');
            nextButtonClicked = true;
            props.handleGoToNextPage(1, nextButtonClicked);
        } else {
            setErr(
                '* Except email id,forecast mode and channel mode all other fields are mandatory'
            );
        }
        setErrorFieldsArray([...errorInputFields]);
    };

    return (
        <Fragment>
            <div className='sub-header'>Basic Information</div>
            <label className='basicinfo-subheader'>
                Enter your personal information of the user you are trying to
                create.
            </label>
            <div className='containerDiv'>
                <div className='country-role-align'>
                    <div style={{ marginBottom: '10px' }}>
                        <InputLabel className='label-header'>
                            Select Role <span className='compulsory'>*</span>
                        </InputLabel>

                        <div
                            className='selected-value-container'
                            onClick={() => {
                                setRoleToggler(!roleToggler);
                            }}>
                            <span
                                className={
                                    props.basicInfo.role === ''
                                        ? 'notSelected'
                                        : 'selected'
                                }>
                                {props.basicInfo.role === ''
                                    ? 'Please select a role'
                                    : props.basicInfo.role}
                            </span>

                            <RightNext />
                        </div>
                        {roleToggler && (
                            <div className='dropdown-container'>
                                <FormControl>
                                    <FormGroup>
                                        {userList.map(
                                            (role: string, index: number) => (
                                                <FormControlLabel
                                                    key={role}
                                                    sx={{
                                                        color:
                                                            role ===
                                                            props.basicInfo.role
                                                                ? '#1F4359'
                                                                : '#A3A3A3',
                                                    }}
                                                    label={role}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                role ===
                                                                props.basicInfo
                                                                    .role
                                                            }
                                                            onChange={(e) => {
                                                                props.basicInfoChanged(
                                                                    'role',
                                                                    role
                                                                );
                                                                setRoleToggler(
                                                                    !roleToggler
                                                                );
                                                            }}
                                                            value={role}
                                                            checkedIcon={
                                                                <CheckBoxOutlinedIcon />
                                                            }
                                                            sx={{
                                                                p: 0,
                                                                m: 1,
                                                                color:
                                                                    '#A3A3A3',
                                                                '&.Mui-checked': {
                                                                    color:
                                                                        '#1F4359',
                                                                },
                                                            }}
                                                        />
                                                    }></FormControlLabel>
                                            )
                                        )}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        )}
                    </div>

                    <div className='containercountryDiv'>
                        {loggedUser?.roleid !== 'SUPER_ADMIN' ? (
                            <div style={{ marginBottom: '50px' }}>
                                <InputLabel className='label-header'>
                                    Country
                                </InputLabel>

                                <BootstrapInput
                                    value={props.basicInfo.countryName}
                                    disabled
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    marginBottom: '50px',
                                    display: 'flex',
                                    columnGap: '35%',
                                    width: '50%',
                                }}>
                                <div>
                                    <InputLabel className='label-header'>
                                        Country
                                        <span className='compulsory'>*</span>
                                    </InputLabel>
                                    <div
                                        className='selected-value-container'
                                        onClick={() => {
                                            setCountryToggler(!countryToggler);
                                        }}>
                                        <span
                                            className={
                                                props.basicInfo.countryName ===
                                                ''
                                                    ? 'notSelected'
                                                    : 'selected'
                                            }>
                                            {props.basicInfo.countryName === ''
                                                ? 'Please select a country'
                                                : props.basicInfo.countryName}
                                        </span>
                                        <RightNext />
                                    </div>
                                    {countryToggler && (
                                        <div className='dropdown-container'>
                                            <FormControl>
                                                <FormGroup>
                                                    {countryData.map(
                                                        (
                                                            countryElem: any,
                                                            index: any
                                                        ) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={index}>
                                                                    <div
                                                                        style={{
                                                                            color:
                                                                                props
                                                                                    .basicInfo
                                                                                    .countryCode ===
                                                                                countryElem.countrycode
                                                                                    ? '#1F4359'
                                                                                    : '#A3A3A3',
                                                                        }}
                                                                        onClick={() => {
                                                                            setCountryToggler(
                                                                                !countryToggler
                                                                            );

                                                                            setCountrySelected(
                                                                                countryElem.countrycode
                                                                            );

                                                                            setLoadDropDown(
                                                                                true
                                                                            );
                                                                            props.basicInfoChanged(
                                                                                'countrycode',
                                                                                countryElem.countrycode
                                                                            );

                                                                            props.basicInfoChanged(
                                                                                'countryname',
                                                                                countryElem.country
                                                                            );
                                                                            props.basicInfoChanged(
                                                                                'language',
                                                                                ''
                                                                            );

                                                                            props.changeLocationInputValues(
                                                                                'lineofbusiness',
                                                                                ''
                                                                            );

                                                                            props.resetLocationInputs();

                                                                            getLanguages(
                                                                                countryElem.countrycode
                                                                            );
                                                                        }}>
                                                                        {
                                                                            countryElem.country
                                                                        }
                                                                    </div>
                                                                    {index !==
                                                                        countryData.length -
                                                                            1 && (
                                                                        <Divider />
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='containerDiv'>
                <div>
                    <Grid container spacing={1}>
                        {props.basicInfo.inputFields.map(
                            (inputFieldObj: any) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={inputFieldObj.label}>
                                    <div>
                                        <InputLabel className='label-header'>
                                            {inputFieldObj.label}{' '}
                                            {inputFieldObj.optional ? (
                                                <span className='optional'>
                                                    (Optional)
                                                </span>
                                            ) : (
                                                <span className='compulsory'>
                                                    *
                                                </span>
                                            )}
                                        </InputLabel>
                                        <BootstrapInput
                                            value={inputFieldObj.value}
                                            placeholder={`Enter ${inputFieldObj.label.toLowerCase()}`}
                                            onChange={(e) => {
                                                props.basicInfoChanged(
                                                    'typeInput',
                                                    e.target.value,
                                                    inputFieldObj.label
                                                );
                                            }}
                                            className='dropdown-values'
                                            type={inputFieldObj.type}
                                        />
                                        {loggedUser?.countrycode === 'TH' ||
                                        countrySelected === 'TH'
                                            ? inputFieldObj.id === 'phoneNo' &&
                                              inputFieldObj.value.length !==
                                                  9 &&
                                              inputFieldObj.value.length >
                                                  0 && (
                                                  <FormHelperText
                                                      sx={{
                                                          color: '#FF0000',
                                                      }}>
                                                      {`Phone Number should have 9 digits`}
                                                  </FormHelperText>
                                              )
                                            : inputFieldObj.id === 'phoneNo' &&
                                              inputFieldObj.value.length !==
                                                  10 &&
                                              inputFieldObj.value.length >
                                                  0 && (
                                                  <FormHelperText
                                                      sx={{
                                                          color: '#FF0000',
                                                      }}>
                                                      {`Phone Number should have 10 digits`}
                                                  </FormHelperText>
                                              )}

                                        {}
                                    </div>
                                </Grid>
                            )
                        )}

                        {singleSelect.map((individualObj: any) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                key={individualObj.type}>
                                <InputLabel className='label-header'>
                                    {individualObj.label}{' '}
                                    <span className='compulsory'>*</span>
                                </InputLabel>
                                <div
                                    className='selected-value-container'
                                    onClick={() => {
                                        individualObj.type === 'gender'
                                            ? setGenderToggler(!genderToggler)
                                            : setIsOpen(!isOpen);
                                    }}>
                                    <span
                                        className={
                                            individualObj.type === 'gender'
                                                ? props.basicInfo
                                                      .genderSelected !== ''
                                                    ? 'selected'
                                                    : 'notSelected'
                                                : props.basicInfo
                                                      .languageSelected !== ''
                                                ? 'selected'
                                                : 'notSelected'
                                        }>
                                        {individualObj.type === 'gender'
                                            ? props.basicInfo.genderSelected !==
                                              ''
                                                ? props.basicInfo.genderSelected
                                                : 'Please select a gender'
                                            : props.basicInfo
                                                  .languageSelected !== ''
                                            ? props.basicInfo.languageSelected
                                            : 'Please select a language'}
                                    </span>
                                    <RightNext />
                                </div>
                                {(individualObj.type === 'gender'
                                    ? genderToggler
                                    : isOpen) && (
                                    <div className='dropdown-container'>
                                        <FormControl>
                                            <FormGroup>
                                                {individualObj.options.map(
                                                    (
                                                        individualOption: string,
                                                        index: number
                                                    ) => (
                                                        <React.Fragment
                                                            key={
                                                                individualOption
                                                            }>
                                                            <FormControlLabel
                                                                sx={{
                                                                    color:
                                                                        individualOption ===
                                                                        (individualObj.type ===
                                                                        'gender'
                                                                            ? props
                                                                                  .basicInfo
                                                                                  .genderSelected
                                                                            : props
                                                                                  .basicInfo
                                                                                  .languageSelected)
                                                                            ? '#1F4359'
                                                                            : '#A3A3A3',
                                                                }}
                                                                label={
                                                                    individualOption
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            individualOption ===
                                                                            (individualObj.type ===
                                                                            'gender'
                                                                                ? props
                                                                                      .basicInfo
                                                                                      .genderSelected
                                                                                : props
                                                                                      .basicInfo
                                                                                      .languageSelected)
                                                                        }
                                                                        onChange={() => {
                                                                            props.basicInfoChanged(
                                                                                individualObj.type,
                                                                                individualOption
                                                                            );
                                                                            individualObj.type ===
                                                                            'gender'
                                                                                ? setGenderToggler(
                                                                                      !genderToggler
                                                                                  )
                                                                                : setIsOpen(
                                                                                      !isOpen
                                                                                  );
                                                                        }}
                                                                        value={
                                                                            individualOption
                                                                        }
                                                                        checkedIcon={
                                                                            <CheckBoxOutlinedIcon />
                                                                        }
                                                                        sx={{
                                                                            p: 0,
                                                                            m: 1,
                                                                            color:
                                                                                '#A3A3A3',
                                                                            '&.Mui-checked': {
                                                                                color:
                                                                                    '#1F4359',
                                                                            },
                                                                        }}
                                                                    />
                                                                }></FormControlLabel>
                                                            {index !==
                                                                individualObj
                                                                    .options
                                                                    .length -
                                                                    1 && (
                                                                <Divider />
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                        ))}

                        <Grid item xs={12} sm={6} md={3}>
                            <InputLabel className='label-header'>
                                Channel Mode
                            </InputLabel>
                            <div
                                className='selected-value-container'
                                onClick={() => {
                                    setChannelModeToggler(!channelModeToggler);
                                }}>
                                <span
                                    className={
                                        props.basicInfo.channelModesSelected
                                            .length === 0
                                            ? 'notSelected'
                                            : 'selected'
                                    }>
                                    {props.basicInfo.channelModesSelected
                                        .length === 0
                                        ? 'No mode selected'
                                        : props.basicInfo.channelModesSelected
                                              .length === 1
                                        ? props.basicInfo
                                              .channelModesSelected[0]
                                        : `${props.basicInfo.channelModesSelected.length} selected`}
                                </span>
                                <RightNext />
                            </div>
                            {channelModeToggler && (
                                <div className='dropdown-container'>
                                    <FormControl>
                                        <FormGroup>
                                            {channelModes.map(
                                                (
                                                    channelMode: string,
                                                    index: number
                                                ) => (
                                                    <React.Fragment
                                                        key={channelMode}>
                                                        <FormControlLabel
                                                            sx={{
                                                                color: props.basicInfo.channelModesSelected.includes(
                                                                    channelMode
                                                                )
                                                                    ? '#1F4359'
                                                                    : '#A3A3A3',
                                                            }}
                                                            label={channelMode}
                                                            control={
                                                                <Checkbox
                                                                    checked={props.basicInfo.channelModesSelected.includes(
                                                                        channelMode
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        props.basicInfoChanged(
                                                                            'channelMode',
                                                                            channelMode
                                                                        );
                                                                        setChannelModeToggler(
                                                                            !channelModeToggler
                                                                        );
                                                                    }}
                                                                    value={
                                                                        channelMode
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxOutlinedIcon />
                                                                    }
                                                                    sx={{
                                                                        p: 0,
                                                                        m: 1,
                                                                        color:
                                                                            '#A3A3A3',
                                                                        '&.Mui-checked': {
                                                                            color:
                                                                                '#1F4359',
                                                                        },
                                                                    }}
                                                                />
                                                            }></FormControlLabel>
                                                        {index !==
                                                            channelModes.length -
                                                                1 && (
                                                            <Divider />
                                                        )}
                                                    </React.Fragment>
                                                )
                                            )}
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <InputLabel className='label-header'>
                                Forecast Report
                            </InputLabel>
                            <div
                                className='selected-value-container'
                                onClick={() => {
                                    setForecastToggler(!forecastToggler);
                                }}>
                                <span
                                    className={
                                        props.basicInfo.forecastSelected
                                            .length === 0
                                            ? 'notSelected'
                                            : 'selected'
                                    }>
                                    {props.basicInfo.forecastSelected.length ===
                                    0
                                        ? 'No alert selected'
                                        : props.basicInfo.forecastSelected
                                              .length === 1
                                        ? props.basicInfo.forecastSelected[0]
                                        : `${props.basicInfo.forecastSelected.length} selected`}
                                </span>
                                <RightNext />
                            </div>
                            {forecastToggler && (
                                <div className='dropdown-container'>
                                    <FormControl>
                                        <FormGroup>
                                            {forecastModes.map(
                                                (
                                                    forecastMode: string,
                                                    index: number
                                                ) => (
                                                    <React.Fragment
                                                        key={forecastMode}>
                                                        <FormControlLabel
                                                            sx={{
                                                                color: props.basicInfo.forecastSelected.includes(
                                                                    forecastMode
                                                                )
                                                                    ? '#1F4359'
                                                                    : '#A3A3A3',
                                                            }}
                                                            label={forecastMode}
                                                            control={
                                                                <Checkbox
                                                                    checked={props.basicInfo.forecastSelected.includes(
                                                                        forecastMode
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        props.basicInfoChanged(
                                                                            'forecastMode',
                                                                            forecastMode
                                                                        );

                                                                        setForecastToggler(
                                                                            !forecastToggler
                                                                        );
                                                                    }}
                                                                    value={
                                                                        forecastMode
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxOutlinedIcon />
                                                                    }
                                                                    sx={{
                                                                        p: 0,
                                                                        m: 1,
                                                                        color:
                                                                            '#A3A3A3',
                                                                        '&.Mui-checked': {
                                                                            color:
                                                                                '#1F4359',
                                                                        },
                                                                    }}
                                                                />
                                                            }></FormControlLabel>
                                                        {index !==
                                                            forecastModes.length -
                                                                1 && (
                                                            <Divider />
                                                        )}
                                                    </React.Fragment>
                                                )
                                            )}
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                {err && <div className='compulsory'>{err}</div>}
            </div>
            <div className='buttonsContainer'>
                {/* <span style={{ paddingLeft: '60px' }}> */}
                <span>
                    <Button
                        // disabled={props.disableFields}
                        style={{
                            width: '181px',
                        }}
                        onClick={() => {
                            props.reset();
                            setMakeApiCall(true);
                            setCountrySelected(loggedUser?.countrycode)
                        }}
                        className='roleDef_reset_btn'>
                        Reset
                    </Button>
                </span>

                <span>
                    <Button
                        onClick={handlePageNext}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: '181px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Next
                    </Button>
                </span>
            </div>
            {/* </footer> */}
        </Fragment>
    );
};

const mapStateToProps = ({
    createUser: { basicInfo, locationMapping },
}: any) => {
    return {
        basicInfo,
        locationMapping,
    };
};

const mapDispatchToProps = {
    basicInfoChanged: basicInfoProvided,
    changeLocationInputValues: locationInputValuesProvided,
    reset: resetBasicInfo,
    resetLocationInputs: resetLocationInputs,
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
