/** @format */

import { experimentalStyled as styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FarmerInfo from './FarmInfo';
import CropYield from './CropYield';
import FarmTabs from './FarmTabs';
import FarmerImg from '../../../../assets/img/svg/farmer-page-icons/farmer.svg';
import CropYieldImage from '../../../../assets/img/png/farmer-page/crop_yield.png';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import _, { toNumber } from 'lodash';

//scss
import './index.scss';
import { getLocalStorageData } from '../../../../utils/useLocalStorage';
import { getRequest, saveOrUpdateRequest } from '../../../../services/apiUtils';
import { Alert } from '../../../../utils/toastr';
import { tableParams } from '../../../../utils/helper';
import Stack from '@mui/material/Stack';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface Props {
    selectedUser: any;
    setIsAPICall: any;
}

var farmLocationObj: any = [];

function toTitleCase(str: any) {
    let firstLetter = str.substr(0, 1).toUpperCase();
    let remainingLetter = str.substr(1).toLowerCase();
    return firstLetter + remainingLetter;
}

const geoLocationMap = new Map();

/**
 *Farmer Details Functional Component
 * @param props
 * @returns
 */
const FarmerDetails: React.FC<Props> = ({ selectedUser, setIsAPICall }) => {
    const [flag, setFlag] = React.useState(true);
    const [locationFlag, setLocationFlag] = React.useState(true);
    const [buttonText, setButtonText] = React.useState('edit details');
    const [locationButtonText, setLocationButtonText] = React.useState(
        'edit details'
    );

    const [status, setStatus] = useState<string>('');
    const [language, setLanguage] = useState<any>();
    const [farmerStatus, setFarmerStatus] = useState<any>();
    const [farmerGender, setFarmerGender] = useState<any>();

  

    const [stateMap, setStateMap] = useState<Map<any, any>>();

    const [stateDistrictMap, setStateDistrictMap] = useState<
        Map<any, Map<any, any>>
    >();
    const [districtSubDistrictMap, setDistrictSubDistrictMap] = useState<
        Map<any, Map<any, any>>
    >();
    const [subDistrictVillageMap, setSubDistrictVillageMap] = useState<
        Map<any, Map<any, any>>
    >();
    const [acre,setacre] = useState<any>(selectedUser?.cropsgrown.map((ac:any) => {
        return ac.acerage
    }))
    
    const getSum = (total:any, num:any) => {
        return total + num;
    }
    const totalacre = acre.reduce(getSum, 0);

    const [finalacre, setFinalacre] = useState<any>(totalacre)
    const specificPersona = ['FS', 'PO'];
    const showPersona = ['TPL', 'TM_SAM', 'ADMIN', 'TM_PL', 'SUPER_ADMIN'];
    let lsData: any = getLocalStorageData('userData');
    let loggeduser = JSON.parse(lsData);



    useEffect(() => {
        
        farmLocationObj = selectedUser?.cropsgrown;
        
    }, []);
    const handleEditLocationClick = () => {
        let mode = locationButtonText;

        let error: any = [];
        
        if (mode === 'save') {
            var reg = new RegExp('^[0-9]+$');
            var regdot = new RegExp(/^[0-9]*\.?[0-9]*$/);
            // var reg = new RegExp('/^\d*\.?\d*$/');
            var regex = new RegExp('^[a-zA-Z ]*$');
            farmLocationObj &&
                farmLocationObj.map((cropdata: any, i: any) => {
                    if (cropdata.variety.length === 0) {
                        error.push('Variety cannot be empty');
                    } else if (cropdata.season.length === 0) {
                        error.push('Season cannot be empty');
                    } else if (cropdata.cropname.length === 0) {
                        error.push('Crop name cannot be empty');
                    } else if (cropdata.sowingdate.length === 0) {
                        error.push('Sowing Date cannot be empty');
                    } else if (cropdata.acerage === '') {
                        error.push('Acreage cannot be empty');
                    } else if (cropdata.latitude.length === 0) {
                        error.push('Latitude cannot be empty');
                    } else if (cropdata.longitude.length === 0) {
                        error.push('Longitude cannot be empty');
                    } else if (cropdata.variety.length === 0) {
                        error.push('Enter valid Variety');
                    } else if (!regdot.test(cropdata.acerage)) {
                        error.push('Enter valid Acreage');
                    }else if(cropdata.acerage === 0) {
                        error.push('Enter valid Acreage');
                    }
                    // else if (!reg.test(cropdata.latitude.trim())) {
                    //     error.push('Enter valid Latitude');
                    // }else if (!reg.test(cropdata.longitude.trim())) {
                    //     error.push('Enter valid Longitude');
                    // }
                });
            
            if (error.length === 0) {
                setFinalacre(totalacre)
                var param = {
                    'countrycode': loggeduser?.roleid === 'SUPER_ADMIN' ? selectedUser.countrycode : loggeduser?.countrycode,
                    'farmerid': selectedUser?.farmerid,
                    'firstname': toTitleCase(selectedUser?.firstname),
                    'lastname': toTitleCase(selectedUser?.lastname),
                    'phonenumber': selectedUser?.phonenumber,
                    'gender': selectedUser?.gender,
                    'address': selectedUser?.address,
                    'geolevel0code': loggeduser?.roleid === 'SUPER_ADMIN' ? selectedUser.countrycode : loggeduser?.countrycode,
                    'geolevel1code': selectedUser?.geolevel1code,
                    'geolevel2code': selectedUser?.geolevel2code,
                    'geolevel3code': selectedUser?.geolevel3code,
                    'geolevel4code': selectedUser.countrycode === 'IN' ?  selectedUser?.geolevel4code :'',
                    'geolevel5code': selectedUser?.geolevel5code,
                    'status': selectedUser?.status,
                    'defaultlanguage': toTitleCase(
                        selectedUser?.defaultlanguage
                    ),
                    'totalacerage': totalacre,
                    'smssubscription': selectedUser?.smssubscription,
                    'whatsappsubscription': selectedUser?.whatsappsubscription,
                    'dailyforecastalert': selectedUser?.dailyforecastalert,
                    'yesterdayforecastalert':
                        selectedUser?.yesterdayforecastalert,
                    'dataconsentreceived': selectedUser?.dataconsentreceived,
                    'privacyconsentreceived':
                        selectedUser?.privacyconsentreceived,
                    'cropsinfo':
                        farmLocationObj &&
                        farmLocationObj.map((loc: any, i: any) => ({
                            'plotcropid': loc.plotcropid,
                            'cropid': loc.cropid,
                            'acerage': loc.acerage,
                            'variety': loc.variety,
                            'season': loc.season,
                            'latitude': loc.latitude,
                            'longitude': loc.longitude,
                            'sowingdate': loc.sowingdate,
                        })),
                };
                saveOrUpdateRequest({
                    url: 'customer/update',
                    method: 'PUT',
                    data: param,
                })
                    .then((response: any) => {
                        selectedUser.firstname = param.firstname;
                        selectedUser.lastname = param.lastname;
                        selectedUser.phonenumber = param.phonenumber;
                        selectedUser.gender = param.gender;
                        selectedUser.status = param.status;
                        selectedUser.defaultlanguage = param.defaultlanguage;
                        selectedUser.whatsappsubscription =
                            param.whatsappsubscription;
                        selectedUser.smssubscription = param.smssubscription;
                        selectedUser.dailyforecastalert =
                            param.dailyforecastalert;
                        selectedUser.yesterdayforecastalert =
                            param.yesterdayforecastalert;
                        selectedUser.cropsgrown = farmLocationObj;
                        setIsAPICall(true);
                    })
                    .catch((error: any) => {
                        Alert(
                            'error',
                            'Error in saving the data . Please contact adminstrator'
                        );
                    });
            }
        }

        if (error.length === 0) {
            setLocationButtonText(
                locationButtonText === 'save' ? 'edit details' : 'save'
            );
            setLocationFlag(!locationFlag);
        } else {
            Alert('error', error.join(' , '));
        }
    };
   
    useEffect(() => {
        getGeoLevelsFirst();
    }, []);

    const setSaveFarmLocationObj = (val: any, field: any, ind: any) => {
        
        farmLocationObj &&
            farmLocationObj.map((cropdata: any, i: any) => {
                if (i === ind) {
                    if (field === 'Variety') {
                        cropdata.variety = val;
                    } else if (field === 'Acreage') {
                        acre[ind] = parseFloat(val)
                        setacre([...acre])
                        cropdata.acerage = parseFloat(val);
                    } else if (field === 'Crop Name') {
                        let cropDetails = val.split("-")
                        cropdata.cropname = cropDetails[1];
                        cropdata.cropid = parseInt(cropDetails[0]);
                    } else if (field === 'Season') {
                        cropdata.season = val;
                    } else if (field === 'Sowing Date') {
                        cropdata.sowingdate = val;
                    } else if (field === 'Latitude') {
                        cropdata.latitude = val;
                    } else if (field === 'Longitude') {
                        cropdata.longitude = val;
                    }
                }
            });
    };
    
    const getGeoLevelsFirst = () => {
        let obj: any = {
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lineofbusiness,
        };

        if (
            loggeduser?.roleid !== 'ADMIN' &&
            loggeduser?.roleid !== 'SUPER_ADMIN'
        ) {
            obj = {
                ...obj,
                userid: loggeduser?.userid,
                roleid: loggeduser?.roleid,
            };
        }


        if(loggeduser?.roleid === 'SUPER_ADMIN' && selectedUser.countrycode !== 'IN'){
            obj= {...obj,
                lob:selectedUser.lineofbusiness,
                countrycode:selectedUser.countrycode
            }
        }

        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    const data = response?.body?.data;
                    let tempStateMap = new Map();
                    let tempStateDistrict = new Map<any, Map<any, any>>();
                    let tempDistrictSubDistrictMap = new Map<
                        any,
                        Map<any, any>
                    >();
                    let tempSubDistrictVillageMap = new Map<
                        any,
                        Map<any, any>
                    >();

                    data?.length > 0 &&
                        data.forEach((level: any) => {
                            var levelCodes =
                                level.locationhierlevel1code +
                                ',' +
                                level.locationhierlevel2code +
                                ',' +
                                level.locationhierlevel3code;

                            tempStateMap.set(level.locationhierlevel1code, {
                                stateCode: level.locationhierlevel1code,
                                stateDesc: level.locationhierlevel1desc,
                            });

                            if (
                                tempStateDistrict?.has(
                                    level.locationhierlevel1code
                                )
                            ) {
                                let tempMap = tempStateDistrict?.get(
                                    level.locationhierlevel1code
                                );
                                tempMap.set(level.locationhierlevel2code, {
                                    districtCode: level.locationhierlevel2code,
                                    districtDesc: level.locationhierlevel2desc,
                                });
                                tempStateDistrict.set(
                                    level.locationhierlevel1code,
                                    tempMap
                                );
                            } else {
                                let tempMap = new Map();
                                tempMap.set(level.locationhierlevel2code, {
                                    districtCode: level.locationhierlevel2code,
                                    districtDesc: level.locationhierlevel2desc,
                                });
                                tempStateDistrict.set(
                                    level.locationhierlevel1code,
                                    tempMap
                                );
                            }

                            if (
                                tempDistrictSubDistrictMap.has(
                                    level.locationhierlevel2code
                                )
                            ) {
                                let tempMap = tempDistrictSubDistrictMap.get(
                                    level.locationhierlevel2code
                                );
                                tempMap?.set(level.locationhierlevel3code, {
                                    subDistrictCode:
                                        level.locationhierlevel3code,
                                    subDistrictDesc:
                                        level.locationhierlevel3desc,
                                });
                                tempDistrictSubDistrictMap.set(
                                    level.locationhierlevel2code,
                                    tempMap
                                );
                            } else {
                                let tempMap = new Map();
                                tempMap.set(level.locationhierlevel3code, {
                                    subDistrictCode:
                                        level.locationhierlevel3code,
                                    subDistrictDesc:
                                        level.locationhierlevel3desc,
                                });
                                tempDistrictSubDistrictMap.set(
                                    level.locationhierlevel2code,
                                    tempMap
                                );
                            }

                            tempSubDistrictVillageMap.set(
                                level.locationhierlevel3code,
                                new Map()
                            );

                            geoLocationMap.set(
                                level.locationhierlevel1desc + 'geolevel1',
                                level.locationhierlevel1code
                            );
                            geoLocationMap.set(
                                level.locationhierlevel2desc + 'geolevel2',
                                level.locationhierlevel2code
                            );
                            geoLocationMap.set(
                                level.locationhierlevel3desc + 'geolevel3',
                                level.locationhierlevel3code
                            );

                            getGeoLevelLast(
                                levelCodes,
                                tempSubDistrictVillageMap,
                                level.locationhierlevel3code
                            );
                        });

                    setStateMap(tempStateMap);
                    setStateDistrictMap(tempStateDistrict);
                    setDistrictSubDistrictMap(tempDistrictSubDistrictMap);
                }
                else{
                    console.log('error','session expired')
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });

        const getGeoLevelLast = (
            level: any,
            tempSubDistrictVillageMap: any,
            subDistrict: any
        ) => {
            let obj: any = {
                countrycode: loggeduser?.countrycode,
                level: level,
                lob: loggeduser?.lineofbusiness,
            };


            if(loggeduser?.roleid === 'SUPER_ADMIN' && selectedUser.countrycode !== 'IN'){
                obj= {...obj,
                    lob:selectedUser.lineofbusiness,
                    countrycode:selectedUser.countrycode
                }
            }

            if (specificPersona.includes(loggeduser?.roleid)) {
                obj['userid'] = loggeduser?.userid;
                obj['roleid'] = loggeduser?.roleid;
            }
            getRequest({
                url: 'lookup/geolevel45',
                method: 'GET',
                queryParams: obj,
            })
                .then((response: any) => {
                    if (response?.body?.data?.length > 0) {
                        const data = response?.body?.data;
                        data?.length > 0 &&
                            data.forEach((level: any) => {
                                geoLocationMap.set(
                                    level.locationhierlevel4desc + 'geolevel4',
                                    level.locationhierlevel4code !== null
                                        ? level.locationhierlevel4code
                                        : ''
                                );

                                let tempMap = tempSubDistrictVillageMap.get(
                                    subDistrict
                                );
                                tempMap.set(level.locationhierlevel4code, {
                                    villageCode: level.locationhierlevel4code,
                                    villageDesc: level.locationhierlevel4desc,
                                });
                                tempSubDistrictVillageMap.set(
                                    subDistrict,
                                    tempMap
                                );
                            });
                        setSubDistrictVillageMap(tempSubDistrictVillageMap);
                    }
                })
                .catch((error: any) => {
                    Alert('error', error?.message);
                });
        };
    };

    const masterLookup = () => {

        let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.roleid === 'SUPER_ADMIN'
            ? 'ALL'
            : loggedUser?.lineofbusiness,
        };

        if(loggedUser?.roleid === 'SUPER_ADMIN' && selectedUser.countrycode !== 'IN'){
            obj= {...obj,
                lob:selectedUser.lineofbusiness,
                countrycode:selectedUser.countrycode
            }
        }

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                setLanguage(response.body.data.languages);
                setFarmerStatus(response.body.data.farmerstatus);
                setFarmerGender(response.body.data.gender);
            }
        });
    };

    const [geolevelvalues, setgeolevelvalues] = useState<any>({
        state: selectedUser.geolevel1,
        district: selectedUser.geolevel2,
        subDistrict: selectedUser.geolevel3,
        village: selectedUser.geolevel4
    })

    const geolevelupdate = (val:any) => {
        setgeolevelvalues({
            state: val.state,
            district : val.district,
            subDistrict: val.subDistrict,
            village: val.village
        })
    }

    return (
        <div className='farmer-info'>
            <div>
                <Stack>
                    <div className='row-two'>
                        <div className='farmer-row'>
                            <div className='farmer-image-row'>
                                <img src={FarmerImg} height={30} alt='Farmer' />
                            </div>
                            <div className='farmer_Detail_row'>
                                Farmer Details
                            </div>
                        </div>
                    </div>

                    <Item elevation={3}>
                        <FarmerInfo
                            user={selectedUser}
                            language={language}
                            status={farmerStatus}
                            gender={farmerGender}
                            masterLookup={masterLookup}
                            farmerStatus={farmerStatus}
                            stateMap={stateMap}
                            stateDistrictMap={stateDistrictMap}
                            districtSubDistrictMap={districtSubDistrictMap}
                            subDistrictVillageMap={subDistrictVillageMap}
                            setIsAPICall={setIsAPICall}
                            totalacre = {finalacre}
                            geolevelupdate={geolevelupdate}
                        />
                    </Item>
                </Stack>

                <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction='row'
                    className='farmer-main-stack'>
                    <div>
                        <div className='farmer-loc-align'>
                            <div className='farmerloc-title'>Farm Location</div>
                            <div>
                                {showPersona.includes(
                                    loggeduser?.roleid.toUpperCase()
                                ) && (
                                    <Button
                                        onClick={handleEditLocationClick}
                                        variant='contained'
                                        color={
                                            locationFlag
                                                ? 'primary'
                                                : 'secondary'
                                        }>
                                        {locationFlag ? (
                                            <EditIcon />
                                        ) : (
                                            <DoneIcon />
                                        )}
                                        &nbsp; {locationButtonText}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className='farmertabs'>
                        <FarmTabs 
                            user={selectedUser}
                            editOrsave={locationButtonText}
                            updateObj={setSaveFarmLocationObj}
                            geolevelvalues = {geolevelvalues}
                        />
                        </div>
                    </div>

                    <div className='farmer-yield'>
                        <div className='row-one'>
                            <div>
                                <img src={CropYieldImage} alt='Crop-Yield' />
                            </div>
                            <div className='title'>Crop Yield</div>
                        </div>
                        <Item sx={{ height: '87%', mt: 2 }} elevation={3}>
                            <CropYield user={selectedUser} />
                        </Item>
                    </div>
                </Stack>
            </div>
        </div>
    );
};

export default FarmerDetails;
