/** @format */

import { MenuItem, Select, styled, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import ServiceAlertHeader from '../../service-alerts/service-alerts-header/index';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CustomButton } from '../../../components/form/CustomButton';
import GeoLevelDropdown from '../../../components/ui/GeoLevelDropdown';
import { getGeoLevelsFirst } from '../../service-alerts/service-alert-utils/Utils';
import {
    assignedNull,
    downloadAdvisoryPage,
    optionList,
    tableParams,
} from '../../../utils/helper';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { getRequest } from '../../../services/apiUtils';
import Footer from '../../../components/layouts/Footer';
import Pagination from '../../../components/ui/Pagination';
import {
    createSearchParams,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import { Alert } from '../../../utils/toastr';
import CustomAdvisoryModal from './CustomAdvisoryModal';
import FromDateToDate from '../service-alerts-header/fromDateToDate';
import Loader from '../../../components/ui/Loader';
import { Filter } from '@mui/icons-material';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #919191',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #919191',
            padding: '10px 26px 10px 12px',
        },
    },
}));

const CustomAdvisory = () => {
    const dayjs = require('dayjs');
    let closeToggle: any;

    const queryParamsFromURL = useLocation().search;
    let urlSearchParams = new URLSearchParams(queryParamsFromURL);
    let [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();

    interface ChipProps extends MuiChipProps {
        status?: string;
        activefilter?: string;
    }

    interface ChipData {
        value: string;
        label: string;
    }

    interface FilterTypes {
        alerttype: any;
        lob: any;
        countrycode: any;
        cropname: any;
        geolevel0code: any;
        geolevel1code: any;
        geolevel2code: any;
        geolevel3code: any;
        geolevel4code: any;
        geolevel5code: any;
        fromdate: any;
        todate: any;
    }

    const condStatus: any = {
        'ACTIVE': {
            color: '#89D329',
            border: '1px solid #E3F1D0',
            backgroundColor: '#E3F1D0',
        },
        'INACTIVE': {
            color: '#ff9d00',
            border: '1px solid #ff9d00',
            backgroundColor: '#ffebcc',
        },
    };

    const Chip = styled(MuiChip)<ChipProps>(
        ({ status, activefilter, theme }) => ({
            ...(status && {
                ...condStatus[status],
            }),
            ...(activefilter === 'true' && {
                backgroundColor: '#10384F',
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: '#10384F !important',
                    color: '#ffffff',
                },
            }),
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.75rem',
            },
        })
    );

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);


    const [valueDate, setValueDate] = useState<Dayjs | null>(() => {
        if (urlSearchParams.get('monthyearfilter')) {
            var dateArr = urlSearchParams.get('monthyearfilter').split('-');
            const dayjs = require('dayjs');
            const month: number = +dateArr[0];
            var date = dayjs()
                .year(dateArr[1])
                .month(month - 1);
            return date;
        }
    });

    const [dataPerPage, setDataPerPage] = useState<any[]>([]);
    const [countryChange, setCountryChange] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [geoLoader, setGeoLoader] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [params, setParams] = useState(tableParams());
    const [errorMsg, setErrorMsg] = useState<string>();
    const [selectPageOne, setSelectPageOne] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(
        urlSearchParams.get('search') !== '' &&
            urlSearchParams.get('search') !== null
            ? urlSearchParams.get('search')
            : ''
    );
    const showPersona = ['TPL', 'TM_SAM', 'ADMIN', 'SUPER_ADMIN', 'TM_PL'];

    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [currOptions, setcurrOptions] = useState<any>([]);

    const [geoLevelList, setGeoLevelList] = useState<any>([]);
    const [chipLobData, setChipLobData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);


    const [filter, setFilter] = useState<FilterTypes>({
        alerttype: urlSearchParams.get('alerttype')
            ? urlSearchParams.get('alerttype')
            : 'ALL',
        lob: urlSearchParams.get('lob') 
            ? urlSearchParams.get('lob') 
            : loggedUser?.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser?.lineofbusiness,
        cropname: urlSearchParams.get('cropname')
            ? urlSearchParams.get('cropname')
            : 'ALL',
        countrycode: urlSearchParams.get('countrycode')
            ? urlSearchParams.get('countrycode')
            : loggedUser?.countrycode,
        fromdate: null,
        todate: null,
        geolevel0code: urlSearchParams.get('geolevel0code')
            ? urlSearchParams.get('geolevel0code') 
            : loggedUser?.countrycode,
        geolevel1code: urlSearchParams.get('geolevel1code')
            ? urlSearchParams.get('geolevel1code')
            : 'ALL',
        geolevel2code: urlSearchParams.get('geolevel2code')
            ? urlSearchParams.get('geolevel2code')
            : 'ALL',
        geolevel3code: urlSearchParams.get('geolevel3code')
            ? urlSearchParams.get('geolevel3code')
            : 'ALL',
        geolevel4code: urlSearchParams.get('geolevel4code')
            ? urlSearchParams.get('geolevel4code')
            : 'ALL',
        geolevel5code: null,
    });



    const [lobval, setLobval] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? filter.lob : loggedUser?.lineofbusiness
    );
    const [countryval, setCountryval] = useState<any>(filter.countrycode);
    const [fromDateVal, setFromDateVal] = useState<any>(null);
    const [toDateVal, setToDateVal] = useState<any>(null);
    const [cropVal, setCropVal] = useState<any>(filter.cropname);
    const [alertVal, setAlertVal] = useState<any>(filter.alerttype);

    const [makeAPICall, setMakeAPICall] = useState<boolean>(false);
   
    const [geolevel, setgeolevel] = useState<any>({ ...filter });

    const [loadLookup, setLoadLookup] = useState<boolean>(false);

    const [chipCountryData, setChipCountryData] = useState<readonly ChipData[]>(
        [{ value: loggedUser?.countrycode, label: loggedUser?.country }]
    );

    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];

    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(
        loggedUser?.roleid !== 'SUPER_ADMIN' && urlSearchParams.has('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : loggedUser?.roleid === 'SUPER_ADMIN' ? true : false
    );

    const [loader, setLoader] = useState(true);
    const [isClosed, setIsClosed] = useState<any>(false);

    const [isFiltered, setIsFiltered] = useState<boolean>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const handleResetFilter = () => {
        setIsClearGeoLevel(true);
        setFirstPageLoad(true);
        setFilter({
            alerttype: 'ALL',
            cropname: 'ALL',
            lob:loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
            countrycode: loggedUser?.countrycode,
            geolevel0code: loggedUser?.countrycode,
            fromdate: '',
            todate: '',
            geolevel1code: 'ALL',
            geolevel2code: 'ALL',
            geolevel3code: 'ALL',
            geolevel4code: 'ALL',
            geolevel5code: null,
        });
        setIsFiltered(false);
        setMakeAPICall(true);
        setBadgeEnabled(loggedUser?.roleid === 'SUPER_ADMIN' ? true : false);
        urlSearchParams.delete('cropname');
        urlSearchParams.delete('isfiltered');
        urlSearchParams.delete('geolevel0code');
        urlSearchParams.delete('geolevel1code');
        urlSearchParams.delete('geolevel2code');
        urlSearchParams.delete('geolevel3code');
        urlSearchParams.delete('geolevel4code');
        setParams(tableParams());
        setParamsBackToUrl(createSearchParams(urlSearchParams));
        setLoadLookup(true);
        closeToggle && closeToggle();
    };

    const handleApplyFilter = () => {
        setIsClearGeoLevel(false);
        setIsFiltered(true);
        setBadgeEnabled(true);
        setLobval(filter.lob);
        setCountryval(filter.countrycode);
        urlSearchParams.set('cropname', filter.cropname);
        urlSearchParams.set('isfiltered', 'true');
        urlSearchParams.set('geolevel0code', filter.geolevel0code);
        urlSearchParams.set('geolevel1code', filter.geolevel1code);
        urlSearchParams.set('geolevel2code', filter.geolevel2code);
        urlSearchParams.set('geolevel3code', filter.geolevel3code);
        urlSearchParams.set('geolevel4code', filter.geolevel4code);
        setParams(tableParams());
        setParamsBackToUrl(createSearchParams(urlSearchParams));
        setMakeAPICall(true);
        setFromDateVal(filter.fromdate);
        setToDateVal(filter.todate);
        setCropVal(filter.cropname);
        setAlertVal(filter.alerttype);
        let selectedFilter: any = { ...filter };
        let temparr: any = [];
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,

                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setcurrOptions([...temparr]);
            setgeolevel({ ...selectedFilter });
        }
        closeToggle && closeToggle();
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;

        if (!isFiltered && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                alerttype: 'ALL',
                lob: loggedUser?.roleid === 'SUPER_ADMIN'
                        ? 'ALL'
                        : loggedUser.lineofbusiness,
                countrycode:loggedUser?.countrycode,
                cropname: 'ALL',
                fromdate: '',
                todate: '',
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: 'ALL',
                geolevel2code: 'ALL',
                geolevel3code: 'ALL',
                geolevel4code: 'ALL',
                geolevel5code: null,
            });
            setIsClosed(false);
            setGeoLoader(true);
        } else if (isFiltered && isClosed) {
            setFilter({
                alerttype: alertVal,
                lob: lobval,
                countrycode: countryval,
                cropname: cropVal,
                fromdate: fromDateVal,
                todate: toDateVal,
                geolevel0code: countryval,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
            });

            geoLevelList.forEach((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            setGeoLoader(true);
            setIsClosed(false);
        }
    };

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const [cropListLookup, setCropListLookup] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [alerttypeLookup, setAlerttypeLookup] = useState<any[]>([
        { value: 'ALL', label: 'ALL' },
    ]);

    const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);

    const [dateFilter, setDateFilter] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs(valueDate);

        const month = currentDate.month() + 1;
        const year = currentDate.year();
        const dateFilterValue = month + '-' + year;

        return dateFilterValue;
    });

    const [
        runSearchForCustomAdvisory,
        setRunSearchForCustomAdvisory,
    ] = useState<boolean>(
        urlSearchParams.get('search') !== null ||
            urlSearchParams.get('search') !== ''
            ? true
            : false
    );

    const [searchFeildKeyboardEvent, setSearchFeildKeyboardEvent] = useState<
        any
    >(new Event('Enter'));

    const minDate = dayjs().subtract(2, 'year').toDate();

    const maxDate = dayjs();

    useEffect(() => {
        loadCustomAdvisoryData();
        cropFilterLookup();
        setFirstPageLoad(false);
        if (
            urlSearchParams.get('geolevel0code') ||
            urlSearchParams.get('geolevel1code') ||
            urlSearchParams.get('geolevel2code') ||
            urlSearchParams.get('geolevel3code') ||
            urlSearchParams.get('geolevel4code')
        ) {
            getGeoLevelsFirst(setGeoLevelList, urlSearchParams, setcurrOptions);
        }
    }, []);

    useEffect(() => {
        if (makeAPICall) {
            loadCustomAdvisoryData();
            setMakeAPICall(false);
        }
    }, [makeAPICall]);

    useEffect(() => {
        let selectedFilter: any = { ...filter };
        let temparr : any[] = []
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,
                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setFilter({ ...selectedFilter });
            setcurrOptions([...temparr])
        }
    }, [geoLevelList]);


    useEffect(() => {
        if (loadLookup) {
            cropFilterLookup();
            setLoadLookup(false);
        }
    }, [loadLookup]);

    const handleCalendarChange = (newValue: Dayjs | null) => {
        const dayjs_date = dayjs(newValue);
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = month + '-' + year;

        if (!isNaN(month) && !isNaN(year)) {
            setDateFilter(dateFilterValue);
            setValueDate(newValue);
        } else {
            setValueDate(null);
            setDateFilter(null);
        }

        urlSearchParams.set('monthyearfilter', dateFilterValue);
        setParamsBackToUrl(createSearchParams(urlSearchParams));

        setMakeAPICall(true);
    };

    const cropFilterLookup = () => {
        let obj: any = {
            countrycode: filter.countrycode,
            lob: filter.lob,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                const cropDataOriginal = response?.body?.data?.croplist;
                if (cropDataOriginal?.length > 0) {
                    const filterOption = optionList(
                        cropDataOriginal,
                        'cropcode',
                        'cropname'
                    );
                    setCropListLookup([
                        { label: 'ALL', value: 'ALL' },
                        ...filterOption,
                    ]);
                } else {
                    setCropListLookup([...cropListLookup]);
                }

                let lobDataOrg = response?.body?.data?.lineofbusiness;

                if (lobDataOrg.length > 0) {
                    let lobFilter = lobDataOrg.map((lob: any) => {
                        return {
                            label: lob,
                            value: lob,
                        };
                    });
                    setChipLobData([
                        { label: 'ALL', value: 'ALL' },
                        ...lobFilter,
                    ]);
                } else {
                    setChipLobData([...chipLobData]);
                }

                let CountryDataOrg = response?.body?.data?.countries;

                if (CountryDataOrg?.length > 0) {
                    const filterOption = optionList(
                        CountryDataOrg,
                        'countrycode',
                        'country'
                    );
                    setChipCountryData(filterOption);
                } else {
                    setChipCountryData([...chipCountryData]);
                }

                const alertTypeOriginal =
                    response?.body?.data?.customalerttypes;
                if (alertTypeOriginal?.length > 0) {
                    const filterOption = alertTypeOriginal.map((alert: any) => {
                        return {
                            label: alert,
                            value: alert,
                        };
                    });
                    setAlerttypeLookup([{ label: 'ALL', value: 'ALL' }, ...filterOption]);
                } else {
                    setAlerttypeLookup([...alerttypeLookup]);
                    setChipLobData([...chipLobData]);
                }
            }
        });
    };

    const loadCustomAdvisoryData = () => {
        let customAdvisoryParams: any = {
            countrycode: filter?.countrycode,
            lob: filter?.lob,
            loggeduserrole: loggedUser?.roleid,
            ...params,
            sortby: 'createddate',
            isfiltered: isFiltered,
        };

        if (loggedUser?.roleid !== 'SUPER_ADMIN') {
            customAdvisoryParams = {
                ...customAdvisoryParams,
                'lob': loggedUser.lineofbusiness,
                'isfiltered': true,
            };
        }

        if (dateFilter) {
            customAdvisoryParams = {
                ...customAdvisoryParams,
                monthyearfilter: dateFilter,
            };
        }

        if (searchTerm) {
            customAdvisoryParams = {
                ...customAdvisoryParams,
                search: searchTerm,
            };
        }

       

        if (isFiltered) {
            let condFilter = assignedNull(filter);

            customAdvisoryParams = {
                ...customAdvisoryParams,
                ...condFilter,
            };
        }

        if (isFiltered) {
            let condFilter = assignedNull(filter);
            customAdvisoryParams = { ...customAdvisoryParams, ...condFilter, ['isfiltered']: true };


            if(loggedUser?.roleid !== 'SUPER_ADMIN' && loggedUser?.roleid !== 'ADMIN'){
            let location:string[] = [];

            if(filter.geolevel1code !== 'ALL' && filter.geolevel4code === 'ALL' ){
                location = loggedUser.assignedloc.filter((val:any)=>{
                    return filter.geolevel1code === val.lochiergeo1code
                }).map((loc:any)=>{
                  return loc.lochiergeocode
                })
            }else{
                location = loggedUser.assignedloc.map(
                    (loc: any) => loc.lochiergeocode
                );
            }

            customAdvisoryParams = {
                ...customAdvisoryParams,
                ...{
                    [customAdvisoryParams.countrycode === 'TH'
                        ? 'geolevel3code'
                        : 'geolevel4code']: location.join(','),
                },
            };
        }

        }

        if (!isFiltered &&
            specificPersona.includes(loggedUser?.roleid) &&
            loggedUser.assignedloc.length > 0
        ) {

            let location:string[] = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );

            customAdvisoryParams = {
                ...customAdvisoryParams,
                ...{
                    'isfiltered': true,
                    [customAdvisoryParams.countrycode === 'TH'
                        ? 'geolevel3code'
                        : 'geolevel4code']: location.join(','),
                },
            };
        } 



        setLoader(true);

        getRequest({
            url: 'advisory/list',
            method: 'GET',
            queryParams: customAdvisoryParams,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    setData(response?.body?.data);
                    setTotalCount(response?.body?.totalcount);
                } else {
                    setErrorMsg(
                        'No data found for current filter. Please change the filters and try again'
                    );
                    setData([]);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setData([]);
                setLoader(false);
                Alert('error', error?.message);
            });
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let search = event.target.value;
        setSearchTerm(search);
        setRunSearchForCustomAdvisory(true);
    };

    const enterKeyHandler = (event: KeyboardEvent) => {
        setSearchFeildKeyboardEvent(event);
        if (event.key === 'Backspace' || event.key === 'Delete') {
            if (searchTerm.length === 0 || searchTerm === '') {
                urlSearchParams.delete('search');
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setFirstPageLoad(true);
                setMakeAPICall(true);
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (searchTerm.length >= 3) {
                urlSearchParams.set('search', searchTerm);
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setMakeAPICall(true);
            }
        }
    };

    if (runSearchForCustomAdvisory) {
        if (
            searchFeildKeyboardEvent.key === 'Backspace' ||
            searchFeildKeyboardEvent.key === 'Delete'
        ) {
            if (searchTerm.length === 0 || searchTerm === '') {
                urlSearchParams.delete('search');
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setFirstPageLoad(true);
                setMakeAPICall(true);
            }
        } else if (searchFeildKeyboardEvent.key === 'Enter') {
            searchFeildKeyboardEvent.preventDefault();
            if (searchTerm.length >= 3) {
                urlSearchParams.set('search', searchTerm);
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setMakeAPICall(true);
            }
        }

        setRunSearchForCustomAdvisory(false);
    }

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
        setMakeAPICall(true);
    };

    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };

    return (
        <div className='header-advisory'>
            <div className='header-container'>
                <ServiceAlertHeader
                    alertType={'CUSTOM_ADVISORY'}
                    alertDescription={'Device Serial No'}
                    alertDescription2={'Location'}
                    placeHolder='Search Action (min 3 letters)'
                    onCloseHandler={onCloseHandler}
                    closeFlag={closeFlagHandler}
                    isFiltered={isFiltered}
                    isBadgeEnabled={badgeEnabled}
                    filter={filter}
                    isDownloadIcon={true}
                    isFilterIcon={true}
                    isCreateAdvisory={showPersona.includes(loggedUser?.roleid)}
                    searchText={searchTerm}
                    handleSearch={handleSearch}
                    onKeyDown={enterKeyHandler}
                    handleOnClickDownload={() => {
                        downloadAdvisoryPage(
                            searchTerm,
                            dateFilter,
                            isFiltered,
                            filter
                        );
                    }}
                    children={
                        <div className='row-advisory'>
                            {!geoLoader && (
                                <>
                                    {loggedUser?.roleid === 'SUPER_ADMIN' ? (
                                        <div className='advisory_filter_topdiv'>
                                            <div className='row'>
                                                <div className='title-label'>
                                                    Country
                                                </div>
                                                <div className='service-alerts-padding'>
                                                    <Select
                                                        className='advisory_dropdown'
                                                        // variant='standard'
                                                        value={
                                                            filter.countrycode
                                                        }
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            setFilter({
                                                                ...filter,
                                                                ...{
                                                                    'geolevel0code':
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                    'geolevel1code': 'ALL',
                                                                    'geolevel2code': 'ALL',
                                                                    'geolevel3code': 'ALL',
                                                                    'geolevel4code': 'ALL',
                                                                    'geolevel5code': null,
                                                                    'countrycode':
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                    'lob':
                                                                        'ALL',
                                                                        'alerttype':'ALL',
                                                                        'cropname':'ALL',
                                                                        fromdate:'',
                                                                        todate:'',
                                                                        
                                                                },
                                                            });
                                                            setLobval('ALL');
                                                            setCountryChange(true)
                                                            setLoadLookup(true);
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                              sx: {
                                                                "& .MuiMenuItem-root.Mui-selected": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                }
                                                              }
                                                            }
                                                          }}>
                                                        {chipCountryData &&
                                                            chipCountryData.map(
                                                                (option: any) =>
                                                                    option.value !==
                                                                    filter?.countrycode ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='title-label'>
                                                    Business Unit
                                                </div>
                                                <div className='service-alerts-padding'>
                                                    <Select
                                                        className='advisory_dropdown'
                                                        // variant='standard'
                                                        value={filter.lob}
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            handleFilter(
                                                                evt.target
                                                                    .value,
                                                                'lob'
                                                            );
                                                            setLobval(
                                                                evt.target.value
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                              sx: {
                                                                "& .MuiMenuItem-root.Mui-selected": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                },
                                                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                  backgroundColor: "#007aff",
                                                                  color:"white"
                                                                }
                                                              }
                                                            }
                                                          }}>
                                                        {chipLobData &&
                                                            chipLobData.map(
                                                                (option: any) =>
                                                                    option.value !==
                                                                    filter?.lob ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div className='advisory_filter_topdiv'>
                                        <div className='row'>
                                            <div className='title-label'>
                                                AlertType
                                            </div>
                                            <div className='service-alerts-padding'>
                                                <Select
                                                    className='advisory_dropdown'
                                                    // variant='standard'
                                                    defaultValue={
                                                        filter.alerttype
                                                    }
                                                    // style={customStyles}
                                                    size='small'
                                                    onChange={(evt: any) => {
                                                        handleFilter(
                                                            evt.target.value,
                                                            'alerttype'
                                                        );
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                          sx: {
                                                            "& .MuiMenuItem-root.Mui-selected": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            },
                                                            "& .MuiMenuItem-root.Mui-selected:hover": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            }
                                                          }
                                                        }
                                                      }}>
                                                    {alerttypeLookup &&
                                                        alerttypeLookup.map(
                                                            (option: any) =>
                                                                option.label !==
                                                                filter?.alerttype ? (
                                                                    <MenuItem
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                ) : (
                                                                    <MenuItem
                                                                        disabled
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                        )}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='title-label'>
                                                Crop
                                            </div>
                                            <div className='service-alerts-padding'>
                                                <Select
                                                    className='advisory_dropdown'
                                                    // variant='standard'
                                                    defaultValue={
                                                        filter.cropname
                                                    }
                                                    // style={customStyles}
                                                    size='small'
                                                    onChange={(evt: any) => {
                                                        handleFilter(
                                                            evt.target.value,
                                                            'cropname'
                                                        );
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                          sx: {
                                                            "& .MuiMenuItem-root.Mui-selected": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            },
                                                            "& .MuiMenuItem-root.Mui-selected:hover": {
                                                              backgroundColor: "#007aff",
                                                              color:"white"
                                                            }
                                                          }
                                                        }
                                                      }}>
                                                    {cropListLookup &&
                                                        cropListLookup.map(
                                                            (option: any) =>
                                                                option.label !==
                                                                filter?.cropname ? (
                                                                    <MenuItem
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                ) : (
                                                                    <MenuItem
                                                                        disabled
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                        )}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <GeoLevelDropdown
                                getGeoLevelData={handleGetGeoLevelData}
                                geoLevelList={geoLevelList}
                                isClearGeoLevel={isClearGeoLevel}
                                filter={filter}
                                handleFilter={handleFilter}
                                handleLoader={(isLoader: boolean) =>
                                    setGeoLoader(isLoader)
                                }
                                lobSelected={filter.lob}
                                countryChange = {countryChange}
                                setCountryChange = {setCountryChange}
                            />
                            {!geoLoader && (
                                <>
                                    <FromDateToDate
                                        handleFilter={handleFilter}
                                        filter={filter}
                                    />
                                    <div className='filter_buttondiv'>
                                        <CustomButton
                                            label={'Reset'}
                                            className='cus-btn-filter reset'
                                            handleClick={handleResetFilter}
                                        />
                                        <CustomButton
                                            label={'Apply'}
                                            style={{
                                                borderRadius: '20px',
                                                backgroundColor: '#7eb343',

                                                border: 0,
                                                boxShadow:
                                                    '0px 3px 6px #c7c7c729',
                                            }}
                                            className='cus-btn'
                                            handleClick={handleApplyFilter}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    setMakeAPICall={setMakeAPICall}
                />
            </div>
            {loader ? <Loader /> : null}
            <div className='calender-advisory'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year', 'month']}
                        value={valueDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={handleCalendarChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='myDatePicker'
                                variant='standard'
                                helperText={null}
                                sx={{ borderBottom: 1 }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <div className='advisory-middle-section'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{
                            xs: 1,
                            sm: 1,
                            md: 9,
                            lg: 12,
                            xl: 15,
                        }}>
                        {data.length ? (
                            data.map((item, index) => (
                                <Grid
                                    item
                                    xs={1}
                                    sm={1}
                                    md={3}
                                    lg={4}
                                    xl={5}
                                    key={index}>
                                    <CustomAdvisoryModal item={item} countryCode={filter.geolevel0code} />
                                </Grid>
                            ))
                        ) : (
                            <div className='advisory_error_msg'>{errorMsg}</div>
                        )}
                    </Grid>
                </Box>
            </div>
            <div className='footer_advisory'>
                {data.length ? (
                    <Footer>
                        <Pagination
                            postsTitle='Custom Advisory(s)'
                            posts={data}
                            onIndex={toPagi}
                            postsPerPage={10}
                            onIndexPageNo={pageNoSelected}
                            totalCountAvailable={totalCount}
                            selectPageOne={selectPageOne}
                        />
                    </Footer>
                ) : null}
            </div>
        </div>
    );
};

export default CustomAdvisory;
