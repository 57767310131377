/** @format */

import CloseIcon from '@mui/icons-material/Close';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import {
    Autocomplete,
    Button,
    Chip,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import './Modal.scss';
import RejectPopup from './RejectPopup';
import { svgIconName, renderSvg } from '../../assets/img/imageExt';
import generateRandomColor from './HelperFunction';
import _ from 'lodash';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { Farmers } from '../../pages/service-alerts/ServiceAlertDataTypes/ServiceAlertDataTypes';
import { Alert } from '../../utils/toastr';
import ChildModal from './ChildModal';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface ModalProps {
    messageId: string;
    status: string;
    farmers: Farmers[];
    consultants: any;
    alertMessage: string;
    notificationType: string;
    comm?: string;
    prod?: any;
    handleOnClose: () => void;
    loadData: () => void;
    lookUpData: string[];
    approvers: any;
    lastaction?: any;
    rejecteddate?: any;
    approveddate?: any;
    sprayname?: any;
    selectedLob?: any;
    selectedCountry?: any;
    agricomm?: any;
    riskstatus:any;
    riskOptions:any;
    filterdropdown:any;
}

const Modal: React.FC<ModalProps> = ({
    messageId,
    status,
    farmers,
    consultants,
    alertMessage,
    notificationType,
    comm,
    prod,
    handleOnClose,
    loadData,
    lookUpData,
    approvers,
    lastaction,
    rejecteddate,
    approveddate,
    sprayname,
    selectedLob,
    selectedCountry,
    agricomm,
    riskstatus,
    riskOptions,
    filterdropdown,
}) => {

    
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);


    const dayjs = require('dayjs');

    var utc = require('dayjs/plugin/utc');
    var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const updatedDateTime = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss');
    
    let tz = filterdropdown === 'TH'?'Asia/Bangkok':'Asia/Kolkata'
    const dayjs_approveddate = dayjs(approveddate).tz(tz).format('DD MMM | LT');
    const dayjs_rejecteddate = dayjs(rejecteddate).tz(tz).format('DD MMM | LT');

    let otherusers = loggedUser?.countrycode === 'TH'?'Asia/Bangkok':'Asia/Kolkata'

    const otheruserapproved = dayjs(approveddate).tz(otherusers).format('DD MMM | LT');
    const otheruserrejected = dayjs(rejecteddate).tz(otherusers).format('DD MMM | LT');

    const [reject, setReject] = useState<any>(
        status === 'REJECTED' ? true : false
    );
    const [acceptAlert, setAcceptAlert] = useState<any>(
        status === 'APPROVED' ? true : false
    );

    const [comments, setComments] = useState<any>(comm);
    const [agricultureComment, setAgricultureComment] = useState<any>(agricomm);
    const [products, setProducts] = useState<any>(prod);
    const [buttonText, setButtonText] = React.useState('Remind Approvers');
    const [isClicked, setIsClicked] = useState(false);

    const [respFlag, setRespFlag] = useState<any>(
        status === 'REJECTED' || status === 'APPROVED' ? true : false
    );
    const [showRejectPopup, setShowRejectPopup] = useState<any>(false);
    const [farmerList, setFarmerList] = useState<Array<Farmers>>(farmers);
    const [consultantsList, setConsultantsList] = useState<any>(consultants);
    const [approversList, setApproversList] = useState<any>(approvers);
    const subscriberCount = farmerList.length + consultantsList.length;
    const approverCount = approversList.length;

    const specificPersona = ['FS', 'PO'];

    const [filtered, setFiltered] = useState<any>([farmerList]);
    const [filt, setFilt] = useState<any>([consultantsList]);
    const [filter, setFilter] = useState<any>([approversList]);

    const [disableFields, setDisableFields] = useState(false);
    const [showClose, setShowClose] = useState(true);
    const [productList, setProductList] = useState<any[]>([]);

    const [selectedProductList, setSelectedProductList] = useState<any[]>([]);

    const [showAgriCommentBox, setShowAgriCommentBox] = useState(() => {
        return status === 'REJECTED' || status === 'APPROVED';
    });

    const [selectedRisk, setSelectedRisk] = useState<any>(riskstatus);

    const [errorMsg, setErrorMsg] = useState<string>('');

    const handleRiskChange = (event: any) => {
        setSelectedRisk(event.target.value);
        setErrorMsg('');
    };


    const handleSwitchChange = (event: any) => {
        setShowAgriCommentBox(event.target.checked);
    };


    const validateError = () => {
        let error=[];
        if(selectedRisk === null){
            error.push('empty')
        }
    }

    const postUpdatedCard = (
        updatedStatus: any,
        msgId: any,
        selectedRisk:any,
        dateTime?: any,
        reason?: any,
        agricultureComment?: any,
        
      
    ) => {
        setShowClose(false);
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let passData: any = {
            messageid: msgId,
            status: updatedStatus,
            userid: loggedUser?.userid,
            actualrisk:selectedRisk
        };

        if (dateTime && updatedStatus === 'APPROVED')
            passData['approveddate'] = dateTime;
        if (dateTime && updatedStatus === 'REJECTED')
            passData['rejecteddate'] = dateTime;
        if (reason) passData['message'] = reason;

        if (productList.length > 0) passData['bayerproductname'] = productList;
        if (agricultureComment) passData['isadditionalcomment'] = true;
        if (agricultureComment)
            passData['agriculturecomment'] = agricultureComment;

        let object: any = {
            countrycode: selectedCountry,
            loggeduserrole: loggedUser?.roleid,
            lob: selectedLob,
        };

        saveOrUpdateRequest({
            url: 'alert/approve',
            method: 'POST',
            queryParams: object,
            data: passData,
        }).then((response: any) => {
            if (response.code === 200) {
                setRespFlag(true);
                //loadData();
                setShowClose(true);
            } else {
                Alert('error', response.statusmessage);
                setAcceptAlert(false);
                setReject(false);
                setShowClose(true);
            }
        });
    };

    function sendReminder() {
        let queryParams: any = {
            messageid: messageId,
            countrycode: selectedCountry,
            loggeduserrole: loggedUser?.roleid,
            lob: selectedLob,
        };
        saveOrUpdateRequest({
            url: 'alert/reminder',
            method: 'GET',
            queryParams: queryParams,
        })
            .then((response: any) => {
                if (response.code === 200) {
                    Alert('success', response.statusmessage);
                    setButtonText(
                        buttonText === 'Remind Approvers'
                            ? 'Reminder Sent'
                            : 'Remind Approvers'
                    );
                    setIsClicked(true);
                } else {
                    Alert('error', 'Failed to send reminder');
                    setIsClicked(false);
                }
            })
            .catch((error) => {
                Alert('error', 'Failed to send reminder');
                setIsClicked(false);
            });
    }


    const getInitial = (name: string) => {
        let split = name.split(' ');
        const arr: any = split.map((item: string) => {
            return item.charAt(0).toUpperCase();
        });
        return arr.join('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComments(e.target.value);
    };

    const handleAgriCommentChange = (event: any) => {
        setAgricultureComment(event.target.value);
    };

    const handleReject = () => {
        if(selectedRisk !== null) {
        setShowRejectPopup(true);
        }else{
            setErrorMsg('Please select Actual Field Level Risk')
        }
    };

    const confirmReject = (bool: any, reason: any) => {
        setReject(bool);
        setComments(reason);
        setProducts(productList);
        postUpdatedCard(
            'REJECTED',
            messageId,
            selectedRisk,
            updatedDateTime,
            reason,
            agricultureComment,
        );
    };

    function getApproversLabel() {
        if (status === 'PENDING') {
            return (
                <div className='approver_body_list'>
                    <div>List of Approvers ({approverCount})</div>
                </div>
            );
        }
        return (
            <div className='approver_body_list'>
                <div>
                    {status === 'APPROVED'
                        ? 'Approved by : '
                        : 'Rejected by : '}
                </div>
            </div>
        );
    }

    const handleAccept = () => {
        if(selectedRisk !== null) {
        postUpdatedCard(
            'APPROVED',
            messageId,
            selectedRisk,
            updatedDateTime,
            comments,
            agricultureComment,
            
        );
        setProducts(productList);
        setAcceptAlert(true);
        setErrorMsg('');
        }else{
            setErrorMsg('Please select Actual Field Level Risk')
        }
    };

    function displayUpdatedNotification() {
        if (!reject && !acceptAlert) {
            return alertMessage;
        } else if ((reject || acceptAlert) && productList.length > 0) {
            return getSprayName();
        }
        return getSprayNameWithProductList();
    }


    useEffect(() => {
        setFiltered(
            farmerList.map((data: any) => {
                let x: any = generateRandomColor();
                data['color'] = x;
            })
        );
        setFilt(
            consultantsList.map((item: any) => {
                let c: any = generateRandomColor();
                item['color'] = c;
            })
        );
        setFilter(
            approversList.map((item: any) => {
                let a: any = generateRandomColor();
                item['color'] = a;
            })
        );
    }, [farmerList]);

    useEffect(() => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        if (specificPersona.includes(loggedUser?.roleid)) {
            setDisableFields(true);
        }
    }, []);

    function getSprayName() {
        let message = alertMessage.replace(
            sprayname,
            '=' + productList.join(',') + '='
        );
        let messageArr = message.split('=');

        return (
            <div>
                {messageArr[0]}

                <b>
                    <u>{messageArr[1]}</u>
                </b>
                {messageArr[2]}
            </div>
        );
    }

    function getSprayNameWithProductList() {
        let prodList = prod?.join(',');
        let message = alertMessage?.replace(prodList, '=' + prodList + '=');
        let messageArr = message?.split('=');

        return (
            <div>
                {messageArr[0]}
                <b>
                    <u>{messageArr[1]}</u>
                </b>
                {messageArr[2]}
            </div>
        );
    }

    const mockHover = [
        {
            Rating: 'No Risk',
            Score: '0',
            Remarks: 'No Visible symptoms on field',
        },
        {
            Rating: 'Low Risk',
            Score: '1',
            Remarks: 'Visible symptoms upto 5-10% plants',
        },
        {
            Rating: 'Medium Risk',
            Score: '2',
            Remarks: 'Visible symptoms upto 15-30% plants',
        },
        {
            Rating: 'High Risk',
            Score: '3',
            Remarks: 'Visible symptoms more than 30% plants',
        },
    ];

    const [hover, setHover] = useState<boolean>(false);

    const InfoHandler = () => {
        setHover(true);
    };

    const LeaveHandler = () => {
        setHover(false);
    };

    return (
        <>
            {showRejectPopup && (
                <RejectPopup
                    closePopup={setShowRejectPopup}
                    confirmReject={confirmReject}
                />
            )}
            <div
                style={
                    showRejectPopup
                        ? {
                              position: 'fixed',
                              zIndex: '1',
                              height: '100vh',
                              width: '100%',
                              filter: 'brightness(50%)',
                          }
                        : {
                              position: 'fixed',
                              zIndex: '1',
                              height: '100vh',
                              width: '100%',
                          }
                }>
                <div className='modal_container'>
                    <div className='modal_header_div'>
                        <div className='title_status_header_div'>
                            <span className='modal_header'>
                                APPROVE & SEND {notificationType} NOTIFICATION
                            </span>
                            <div style={{
                                    color: 'red',
                                    fontFamily: 'appRegular',
                                    fontSize: 13,
                                    paddingBottom: 4,
                                }}>{errorMsg}</div>
                            {reject &&
                                (respFlag ? (
                                    <span className='modal_reject_tag'>
                                        {renderSvg(svgIconName.cancel, '12')}
                                        <p
                                            style={{
                                                paddingLeft: '8px',
                                            }}>
                                            Rejected
                                        </p>
                                    </span>
                                ) : (
                                    <span className='modal_process_tag'>
                                        <p
                                            style={{
                                                paddingLeft: '8px',
                                            }}>
                                            Processing
                                        </p>
                                    </span>
                                ))}
                            {acceptAlert &&
                                (respFlag ? (
                                    <span className='modal_approve_tag'>
                                        {renderSvg(svgIconName.approve, '12')}
                                        <p
                                            style={{
                                                paddingLeft: '8px',
                                            }}>
                                            Approved
                                        </p>
                                    </span>
                                ) : (
                                    <span className='modal_process_tag'>
                                        <p
                                            style={{
                                                paddingLeft: '8px',
                                            }}>
                                            Processing
                                        </p>
                                    </span>
                                ))}
                        </div>
                        {showClose && (
                            <span>
                                <CloseIcon
                                    className='modal_close_icon'
                                    onClick={handleOnClose}
                                />
                            </span>
                        )}
                    </div>
                    <div className='modal_body_div'>
                        <div className='modal_body_message'>
                            {displayUpdatedNotification()}
                        </div>
                       
                        <div className='modal_farmer_consultant_div'>
                            <div>
                                <div className='modal_body_list'>
                                    <div>
                                        List of Subscribers and their Location (
                                        {subscriberCount})
                                    </div>
                                </div>
                                <div className='farmer_list_container2'>
                                    {farmerList &&
                                        farmerList.map((farmer: any) => {
                                            return (
                                                <div
                                                    className='farmer_list_div'
                                                    key={farmer.farmerid}>
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                farmer.color,
                                                        }}
                                                        className='famer_list_acr'>
                                                        {getInitial(
                                                            farmer.firstname +
                                                                ' ' +
                                                                farmer.lastname
                                                        )}
                                                    </span>
                                                    <span className='famer_list_detail'>
                                                        <div className='farmername-align'>
                                                            <div
                                                                className='famer_list_name'
                                                                title={
                                                                    farmer.firstname +
                                                                    ' ' +
                                                                    farmer.lastname
                                                                }>
                                                                {farmer.firstname +
                                                                    ' ' +
                                                                    farmer.lastname}
                                                            </div>
                                                            <div className='farmer_list_title'>
                                                                , Farmer
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='famer_list_address'
                                                            title={`${farmer.geolevel4}, ${farmer.geolevel3}, ${farmer.geolevel2}, ${farmer.geolevel1}`.replace(
                                                                /\w+/g,
                                                                _.capitalize
                                                            )}>
                                                            {loggedUser?.countrycode ===
                                                                'TH' ||
                                                            selectedCountry ===
                                                                'TH'
                                                                ? `${farmer.geolevel3}, ${farmer.geolevel2}, ${farmer.geolevel1}`.replace(
                                                                      /\w+/g,
                                                                      _.capitalize
                                                                  )
                                                                : `${farmer.geolevel4}, ${farmer.geolevel3}, ${farmer.geolevel2}, ${farmer.geolevel1}`.replace(
                                                                      /\w+/g,
                                                                      _.capitalize
                                                                  )}
                                                        </div>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    {consultantsList &&
                                        consultantsList.map(
                                            (consultant: any) => {
                                                return (
                                                    <div
                                                        className='farmer_list_div'
                                                        key={consultant.userid}>
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    consultant.color,
                                                            }}
                                                            className='famer_list_acr'>
                                                            {getInitial(
                                                                consultant.firstname +
                                                                    ' ' +
                                                                    consultant.lastname
                                                            )}
                                                        </span>
                                                        <span className='famer_list_detail'>
                                                            <div
                                                                className='consultant_name'
                                                                title={
                                                                    consultant.firstname +
                                                                    ' ' +
                                                                    consultant.lastname
                                                                }>
                                                                {consultant.firstname +
                                                                    ' ' +
                                                                    consultant.lastname}
                                                            </div>
                                                            <div
                                                                className='famer_list_address'
                                                                title={
                                                                    consultant.roledescription
                                                                }>
                                                                {
                                                                    consultant.roledescription
                                                                }
                                                            </div>
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </div>

                            {getApproversLabel()}

                            <div className='approvers_list_container'>
                                {status === 'PENDING' &&
                                    approversList &&
                                    approversList.map((approver: any) => {
                                        return (
                                            <div
                                                key={approver.userid}
                                                className={
                                                    approver.isapproved
                                                        ? 'approver_list_div approver_list_approved'
                                                        : approver.isrejected
                                                        ? 'approver_list_div approver_list_rejected'
                                                        : 'approver_list_div approver_list_normal'
                                                }>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            approver.color,
                                                    }}
                                                    className='approver_list_acr'>
                                                    {getInitial(
                                                        approver.firstname +
                                                            ' ' +
                                                            approver.lastname
                                                    )}
                                                </span>
                                                <div className='approver_list_detail'>
                                                    <div className='approvers-display'>
                                                        <div
                                                            title={
                                                                approver.firstname +
                                                                ' ' +
                                                                approver.lastname
                                                            }>
                                                            {approver.firstname +
                                                                ' ' +
                                                                approver.lastname}
                                                        </div>
                                                        <div>
                                                            {approver.isapproved ? (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1cce60',
                                                                    }}>
                                                                    Approved
                                                                </span>
                                                            ) : approver.isrejected ? (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#fd513b',
                                                                    }}>
                                                                    Rejected
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='approver_list_role'
                                                        title={
                                                            approver.roledescription
                                                        }>
                                                        {
                                                            approver.roledescription
                                                        }
                                                    </div>
                                                    <div
                                                        className='approver_list_email'
                                                        title={approver.email}>
                                                        {approver.email}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                {status !== 'PENDING' && lastaction && (
                                    <div
                                        className={
                                            lastaction.isapproved
                                                ? 'approver_list_div approver_list_approved'
                                                : lastaction.isrejected
                                                ? 'approver_list_div approver_list_rejected'
                                                : 'approver_list_div approver_list_normal'
                                        }>
                                        <span
                                            style={{
                                                backgroundColor: generateRandomColor(),
                                            }}
                                            className='approver_list_acr'>
                                            {getInitial(
                                                lastaction.firstname +
                                                    ' ' +
                                                    lastaction.lastname
                                            )}
                                        </span>
                                        <div className='approver_list_detail'>
                                            <div className='approvers-display'>
                                                <div
                                                    title={
                                                        lastaction.firstname +
                                                        ' ' +
                                                        lastaction.lastname
                                                    }>
                                                    {lastaction.firstname +
                                                        ' ' +
                                                        lastaction.lastname}
                                                </div>
                                                <div>
                                                    {lastaction.isapproved ? (
                                                        <span
                                                            style={{
                                                                color:
                                                                    '#1cce60',
                                                            }}>
                                                            Approved
                                                        </span>
                                                    ) : lastaction.isrejected ? (
                                                        <span
                                                            style={{
                                                                color:
                                                                    '#fd513b',
                                                            }}>
                                                            Rejected
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className='approver_list_role'
                                                title={
                                                    lastaction.roledescription
                                                }>
                                                {lastaction.roledescription}
                                            </div>
                                            <div className='approver-mail-date'>
                                                <div
                                                    className='approver_list_email'
                                                    title={lastaction.email}>
                                                    {lastaction.email}
                                                </div>
                                                <div>
                                                    {loggedUser.roleid === 'SUPER_ADMIN' ? 
                                                    approveddate && (
                                                        <div
                                                            className='approver_list_date'
                                                            title={
                                                                dayjs_approveddate
                                                            }>


                                                            {dayjs_approveddate}
                                                        </div>
                                                    ):
                                                    approveddate && (
                                                        <div
                                                            className='approver_list_date'
                                                            title={
                                                                otheruserapproved
                                                            }>


                                                            {otheruserapproved}
                                                        </div>
                                                    )}
                                                    {loggedUser.roleid === 'SUPER_ADMIN' ? 

                                                    rejecteddate && (
                                                        <div
                                                            className='approver_list_date'
                                                            title={
                                                                dayjs_rejecteddate
                                                            }>
                                                            {dayjs_rejecteddate}
                                                        </div>
                                                    )
                                                        :
                                                        rejecteddate && (
                                                            <div
                                                                className='approver_list_date'
                                                                title={
                                                                    otheruserrejected
                                                                }>
                                                                {otheruserrejected}
                                                            </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='productname-align'>
                                <div>
                                    {!acceptAlert && !reject ? 
                                    <div className='modal-prod-name'>
                                    <span className='modal_comment_title'>
                                        Product Name
                                    </span>
                                    <span className='modal_comment_title2'>
                                        (Optional)
                                    </span>
                                </div>:(acceptAlert || reject) && products !== null ? <div className='modal-prod-name'>
                                        <span className='modal_comment_title'>
                                            Product Name :
                                        </span>
                                    </div>:(acceptAlert || reject) && products === null ? '':'' }
                                    
                                </div>

                                <div>
                                    {!reject && !acceptAlert ? (
                                        <Autocomplete
                                            multiple
                                            id='tags-standard'
                                            options={lookUpData}
                                            onChange={(
                                                event: any,
                                                value: any[],
                                                reason: string
                                            ) => {
                                                let tempSelectedProdList:any = [];
                                                let quantityMap = new Map();

                                                let containsList : any[]=  []
                                                
                                                selectedProductList.forEach((prod)=>{
                                                    containsList.push(prod.productname)
                                                    quantityMap.set(prod.productname,prod.dosage)
                                                })

                                                value.forEach(
                                                    (element) => {
                                                        if(containsList.includes(element)){
                                                            tempSelectedProdList.push({
                                                                productname: element,
                                                                dosage: quantityMap.get(element)
                                                            });
                                                        }else{
                                                            tempSelectedProdList.push({
                                                                productname: element,
                                                                dosage: 0
                                                            })
                                                        }
                                                    }
                                                );
                                                setSelectedProductList(
                                                    tempSelectedProdList
                                                );
                                                                                                let temporaryArray = tempSelectedProdList.map(
                                                    (prod: any) => (
                                                        prod.dosage !== 0 && prod.dosage !== ''  ? 
                                                        
                                                            prod.productname +
                                                            '-' +
                                                            prod.dosage +
                                                            ' g/ml per acre'
                                                        
                                                    :
                                                     
                                                    prod.productname 
                                                    )
                                                );
                                                setProductList(temporaryArray);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    disabled={disableFields}
                                                    className={
                                                        disableFields
                                                            ? 'product-area-comment product-textarea-comment-disabled'
                                                            : 'product-area-comment'
                                                    }
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Stack direction='row' spacing={1}>
                                            {products !== null &&
                                                products.map(
                                                    (
                                                        product: any,
                                                        index: any
                                                    ) => {
                                                        return (
                                                            <div
                                                                key={product}
                                                                style={{
                                                                    fontFamily:
                                                                        'appRegular',
                                                                }}>
                                                                <Chip
                                                                    label={
                                                                        product
                                                                    }
                                                                    variant='outlined'
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </Stack>
                                    )}
                                </div>

                                {!reject && !acceptAlert && (
                                    <ChildModal
                                        productList={productList}
                                        displayUpdatedNotification={
                                            displayUpdatedNotification
                                        }
                                        reject={reject}
                                        acceptAlert={acceptAlert}
                                        getSprayName={getSprayName}
                                        setProductList={setProductList}
                                        setSelectedProductList={
                                            setSelectedProductList
                                        }
                                        selectedProductList={
                                            selectedProductList
                                        }
                                    />
                                )}
                            </div>
                            <div className='modal_comment_title'>
                                {!reject && !acceptAlert
                                    ? 'Updated Notification : '
                                    : ''}
                            </div>
                            <div className='modal_body_message'>
                                {!reject &&
                                    !acceptAlert &&
                                    productList.length > 0 &&
                                    getSprayName()}
                            </div>



                            <div className='risk-align'>
                                <div>
                                    <div className='modal-prod-name'>
                                        <span className='modal_comment_title'>
                                            Actual Field Level Risk
                                        </span>
                                        {!acceptAlert && !reject ? (
                                            <span className='compulsory'>
                                                *
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                        <div>
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            color:
                                                                'transparent',
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                    },
                                                }}
                                                title={
                                                    <TableContainer
                                                        sx={{
                                                            width: 550,
                                                            height: 200,
                                                        }}
                                                        component={Paper}>
                                                        <Table aria-label='simple table'>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Rating
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Score
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Remarks
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            {mockHover.map(
                                                                (hover) => (
                                                                    <TableBody>
                                                                        <TableCell>
                                                                            {
                                                                                hover.Rating
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {
                                                                                hover.Score
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                hover.Remarks
                                                                            }
                                                                        </TableCell>
                                                                    </TableBody>
                                                                )
                                                            )}
                                                        </Table>
                                                    </TableContainer>
                                                }>
                                                <span>
                                                    {renderSvg(
                                                        svgIconName.information,
                                                        30
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {!specificPersona.includes(loggedUser?.roleid)
                                    ? !acceptAlert && !reject
                                        ? !hover && (
                                              <Select
                                                  className='risk-width-height'
                                                  value={selectedRisk}
                                                  onChange={handleRiskChange}>
                                                  {riskOptions &&
                                                      riskOptions.map(
                                                          (risk: any) => (
                                                              <MenuItem
                                                                  value={risk}>
                                                                  {risk}
                                                              </MenuItem>
                                                          )
                                                      )}
                                              </Select>
                                          )
                                        : !hover && (
                                              <div
                                                  style={{
                                                      fontFamily: 'appRegular',
                                                      fontSize: 13,
                                                  }}>
                                                  {' '}
                                                  : {selectedRisk}
                                              </div>
                                          )
                                    : !acceptAlert && !reject
                                    ? !hover && (
                                          <Select
                                              disabled
                                              className='risk-width-height'
                                              value={selectedRisk}
                                              onChange={handleRiskChange}>
                                              {riskOptions &&
                                                  riskOptions.map(
                                                      (risk: any) => (
                                                          <MenuItem
                                                              value={risk}>
                                                              {risk}
                                                          </MenuItem>
                                                      )
                                                  )}
                                          </Select>
                                      )
                                    : !hover && (
                                          <div
                                              style={{
                                                  fontFamily: 'appRegular',
                                                  fontSize: 13,
                                              }}>
                                              {' '}
                                              : {selectedRisk}
                                          </div>
                                      )}
                            </div>

                            <div
                                style={{
                                    paddingTop: '10px',
                                    paddingBottom: '5px',
                                }}>
                                <span className='modal_comment_title'>
                                    {!reject && !acceptAlert
                                        ? 'Approver Comments'
                                        : reject
                                        ? 'Reject reason : '
                                        : acceptAlert && comments
                                        ? 'Approver Comments : '
                                        : ''}
                                </span>
                                <span className='modal_comment_title2'>
                                    {!reject && !acceptAlert
                                        ? '(Optional)'
                                        : ''}
                                </span>
                            </div>
                            <div>
                                {!reject && !acceptAlert ? (
                                    <textarea
                                        disabled={disableFields}
                                        placeholder='Enter Approver comments here ...'
                                        className={
                                            disableFields
                                                ? 'modal_comment_textarea modal_comment_textarea_disabled'
                                                : 'modal_comment_textarea'
                                        }
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <div
                                        className={
                                            acceptAlert
                                                ? 'approve_textarea'
                                                : 'reject_textarea'
                                        }>
                                        {comments}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!specificPersona.includes(loggedUser?.roleid) ? 
                    !reject && !acceptAlert ? (
                        <div style={{ display: 'flex' }}>
                            <Switch
                                color='secondary'
                                onChange={handleSwitchChange}></Switch>
                            <div className='switch-modal'>
                                Enable to send below comment to subscribers (
                                Only via Whatsapp )
                            </div>
                        </div>
                    ) : (
                        ''
                    ) :'' }
                    {showAgriCommentBox && (
                        <>
                            <div
                                style={{
                                    paddingTop: '10px',
                                    paddingBottom: '5px',
                                }}>
                                <span className='modal_comment_title'>
                                    {!reject && !acceptAlert
                                        ? 'Comment to subscribers :'
                                        : acceptAlert &&
                                          agricultureComment !== null
                                        ? 'Comment to subscribers :'
                                        : reject && agricultureComment !== null
                                        ? ''
                                        : ''}
                                </span>
                            </div>
                            <div>
                                {!reject && !acceptAlert ? (
                                    <textarea
                                        disabled={disableFields}
                                        placeholder='Enter your comments here ...'
                                        className={
                                            disableFields
                                                ? 'modal_comment_textarea modal_comment_textarea_disabled'
                                                : 'modal_comment_textarea'
                                        }
                                        value={agricultureComment}
                                        onChange={handleAgriCommentChange}
                                    />
                                ) : acceptAlert ? (
                                    <div
                                        className={
                                            acceptAlert
                                                ? 'approve_textarea'
                                                : 'reject_textarea'
                                        }>
                                        {agricultureComment}
                                    </div>
                                ) : (
                                    <div>{''}</div>
                                )}
                            </div>
                        </>
                    )}
                              
                    
                    {!acceptAlert && (
                        <div className='modal_footer_div'>
                            {reject ? (
                                ''
                            ) : (
                                <>
                                    <Button
                                        disabled={disableFields}
                                        className={
                                            disableFields
                                                ? 'modal_reject_btn_disabled modal_reject_btn'
                                                : 'modal_reject_btn'
                                        }
                                        onClick={handleReject}>
                                        <CloseSharpIcon
                                            style={{
                                                fontSize: 'small',
                                                marginRight: '3px',
                                            }}
                                        />
                                        Reject
                                    </Button>
                                    {(loggedUser?.roleid === 'ADMIN' ||
                                        loggedUser?.roleid ===
                                            'SUPER_ADMIN') && (
                                        <Button
                                            disabled={isClicked}
                                            style={{
                                                // fontSize: 'small',
                                                marginRight: '3px',
                                            }}
                                            className={`reminder-button ${
                                                isClicked
                                                    ? 'modal_remind_disable_btn'
                                                    : 'modal_remind_btn'
                                            }`}
                                            onClick={sendReminder}>
                                            <div className='approvers-space'>
                                                <div className='icon-space'>
                                                    {renderSvg(
                                                        svgIconName.bell,
                                                        '12'
                                                    )}
                                                </div>
                                            </div>
                                            {buttonText}
                                        </Button>
                                    )}

                                    <Button
                                        disabled={disableFields}
                                        style={{
                                            borderRadius: '20px',
                                            backgroundColor: '#7eb343',
                                            width: '181px',
                                            height: '35px',
                                            border: 0,
                                            boxShadow: '0px 3px 6px #c7c7c729',
                                        }}
                                        className={
                                            disableFields
                                                ? 'modal_accept_btn_disabled modal_accept_btn'
                                                : 'modal_accept_btn'
                                        }
                                        // onClick={handleAccept}>Accept All
                                        onClick={handleAccept}>
                                        Approve
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Modal;
