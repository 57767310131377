import { Alert } from '../../../utils/toastr';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { getRequest } from '../../../services/apiUtils';


const defaultAll = { value: 'ALL', label: 'ALL' };

const specificPersona = ['FS', 'PO'];

export const getGeoLevelsFirst = (setGeoLevelList:any,urlSearchParams:URLSearchParams,setcurrOptions:any,isCondUpdate?: boolean) => {
    
    let lsData: any = getLocalStorageData('userData');

    let loggeduser = JSON.parse(lsData);

    let obj: any = {
        countrycode: loggeduser?.countrycode,
        lob: loggeduser?.lineofbusiness,
    };

    if (loggeduser?.roleid !== 'ADMIN') {
        obj = {
            ...obj,
            userid: loggeduser?.userid,
            roleid: loggeduser?.roleid,
        };
    }
    // handleLoader(true)
    getRequest({
        url: 'lookup/geolevel123',
        method: 'GET',
        queryParams: obj,
    })
        .then((response: any) => {
            if (response?.body?.data?.length > 0) {
                const data = response?.body?.data;

                let firstLevel: any = {
                    label: '',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: 'geolevel1code',
                };
                let secondLevel: any = {
                    label: '',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: 'geolevel2code',
                };
                let thirdLevel: any = {
                    label: '',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: 'geolevel3code',
                };
                let fourthLevel: any = {
                    label: 'Village',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: 'geolevel4code',
                };

                let locationhierlevel1codes: any = [];
                let locationhierlevel2codes: any = [];
                let locationhierlevel3codes: any = [];

                data?.length > 0 &&
                    data.forEach((level: any) => {
                        // if (level?.locationhierlevel1code) {
                        let bool1 = locationhierlevel1codes.includes(
                            level?.locationhierlevel1code
                        );
                        if (level?.locationhierlevel1code && !bool1) {
                            let first = {
                                value: level?.locationhierlevel1code, //01 or 02
                                label: level?.locationhierlevel1desc, //HP or Telan
                                title: level?.locationhierlevel1name, //state
                            };

                            firstLevel = {
                                ...firstLevel, //label,options,selectedvalue,name
                                label: level?.locationhierlevel1name, //state
                                options: [
                                    ...firstLevel.options, //Now has 2 objects
                                    { ...first },
                                ],
                            };
                            locationhierlevel1codes.push(
                                level?.locationhierlevel1code
                            );
                        }
                        let bool2 = locationhierlevel2codes.includes(
                            level?.locationhierlevel2code
                        );
                        if (level?.locationhierlevel2code && !bool2) {
                            //dist code
                            let second = {
                                value: level?.locationhierlevel2code, //dist code
                                label: level?.locationhierlevel2desc, //dist name
                                title: level?.locationhierlevel2name, //Dist
                            };
                            secondLevel = {
                                ...secondLevel,
                                label: level?.locationhierlevel2name, //Dist
                                options: [
                                    ...secondLevel.options,
                                    { ...second },
                                ],
                            };
                            locationhierlevel2codes.push(
                                level?.locationhierlevel2code
                            );
                        }
                        let bool3 = locationhierlevel3codes.includes(
                            level?.locationhierlevel3code
                        );
                        if (level?.locationhierlevel3code && !bool3) {
                            let third = {
                                value: level?.locationhierlevel3code,
                                label: level?.locationhierlevel3desc,
                                title: level?.locationhierlevel3name, //Sub_dist
                            };
                            thirdLevel = {
                                ...thirdLevel,
                                label: level?.locationhierlevel3name,
                                options: [
                                    ...thirdLevel.options,
                                    { ...third },
                                ],
                            };
                            locationhierlevel3codes.push(
                                level?.locationhierlevel3code
                            );
                        }
                    });

                const mergedData: any = [
                    firstLevel,
                    secondLevel,
                    thirdLevel,
                    fourthLevel,
                ];

                

                if (!isCondUpdate) {
                    const advanceAllGeoLevels = mergedData.map(
                        (level: any) => {
                            return level.options.length === 2
                                ? {
                                      ...level,
                                      options: [level.options[1]],
                                      selectedValue: level.options[1].value,
                                  }
                                : level;
                        }
                    );

                    let temparr: any = [];
                    advanceAllGeoLevels.forEach((geo: any) => {
                        if (geo.name === 'geolevel1code' && urlSearchParams.get('geolevel1code')) {
                            geo.selectedValue = urlSearchParams.get('geolevel1code')
                        }
                        if (geo.name === 'geolevel2code' && urlSearchParams.get('geolevel2code')) {
                            geo.selectedValue = urlSearchParams.get('geolevel2code')
                        }
                        if (geo.name === 'geolevel3code' && urlSearchParams.get('geolevel3code')) {
                            geo.selectedValue = urlSearchParams.get('geolevel3code')
                        }
                        if (geo.name === 'geolevel4code' && urlSearchParams.get('geolevel4code')) {
                            geo.selectedValue = urlSearchParams.get('geolevel4code')
                        }

                        temparr = [...temparr, geo.options];
                    });
                    setcurrOptions([...temparr]);

                    
                    
                    setGeoLevelList(advanceAllGeoLevels);

                    

                    let result: boolean = false;
                    for (
                        let i = 0;
                        i < advanceAllGeoLevels.length - 1;
                        i++
                    ) {
                        if (advanceAllGeoLevels[i].options.length > 1) {
                            result = true;
                            break;
                        }
                    }
                    if (!result) {
                        getGeoLevelLast(
                            `${advanceAllGeoLevels[0].selectedValue},${advanceAllGeoLevels[1].selectedValue},${advanceAllGeoLevels[2].selectedValue}`,
                            advanceAllGeoLevels,setGeoLevelList,urlSearchParams,setcurrOptions
                        );
                    }
                }
            }
            else{
                console.log('error','session expired')
            }
            // handleLoader(false)
        })
        .catch((error: any) => {
            Alert('error', error?.message);
            // handleLoader(false)
        });
};

/**
 * Get all geo level of three and four only
 * @param level
 * @param geoLevel
 */

const getGeoLevelLast = (level: string, geoLevel: any[],setGeoLevelList:any,urlSearchParams:URLSearchParams, setcurrOptions:any) => {

    let lsData: any = getLocalStorageData('userData');

    let loggeduser = JSON.parse(lsData);

    let obj: any = {
        countrycode: loggeduser?.countrycode,
        level,
        lob: loggeduser?.lineofbusiness,
    };
    if(specificPersona.includes(loggeduser?.roleid)){
        obj['userid'] = loggeduser?.userid
        obj['roleid'] = loggeduser?.roleid
    }
    
    getRequest({
        url: 'lookup/geolevel45',
        method: 'GET',
        queryParams: obj,
    })
        .then((response: any) => {
            if (response?.body?.data?.length > 0) {
                const data = response?.body?.data;

                let fourthLevel: any = {
                    label: 'Village',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: 'geolevel4code',
                };
                data?.length > 0 &&
                    data.forEach((level: any) => {
                        if (level?.locationhierlevel4code) {
                            let fourth = {
                                value: level?.locationhierlevel4code, //village code
                                label: level?.locationhierlevel4desc, //village name
                                title: level?.locationhierlevel4name, //village
                            };
                            fourthLevel = {
                                ...fourthLevel,
                                label: level?.locationhierlevel4name,
                                options: [
                                    ...fourthLevel.options,
                                    { ...fourth },
                                ],
                            };
                        }
                    });
                
                fourthLevel.options.length === 2 &&
                    (fourthLevel = {
                        ...fourthLevel,
                        options: [fourthLevel.options[1]],
                        selectedValue: fourthLevel.options[1].value,
                    });
                
                const updatedData =
                    geoLevel?.length > 0
                        ? geoLevel.map((value: any) => {
                              if (value?.name === 'geolevel4code') {
                                  return (value = {
                                      ...value,
                                      ...fourthLevel,
                                  });
                              }
                              return value;
                          })
                        : [];

                        let temparr: any = [];
                        updatedData.forEach((geo: any) => {
                            if (geo.name === 'geolevel1code' && urlSearchParams.get('geolevel1code')) {
                                geo.selectedValue = urlSearchParams.get('geolevel1code')
                            }
                            if (geo.name === 'geolevel2code' && urlSearchParams.get('geolevel2code')) {
                                geo.selectedValue = urlSearchParams.get('geolevel2code')
                            }
                            if (geo.name === 'geolevel3code' && urlSearchParams.get('geolevel3code')) {
                                geo.selectedValue = urlSearchParams.get('geolevel3code')
                            }
                            if (geo.name === 'geolevel4code' && urlSearchParams.get('geolevel4code')) {
                                geo.selectedValue = urlSearchParams.get('geolevel4code')
                            }

                            temparr = [...temparr, geo.options];
                        });

                    setcurrOptions([...temparr]);
                    setGeoLevelList([...updatedData]);
            } else {
            }
            //    handleLoader(false)
        })
        .catch((error: any) => {
            Alert('error', error?.message);
            //    handleLoader(false)
        });
};