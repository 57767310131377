/** @format */

import { toDownload } from '../utils/helper';

import Authorization from '../utils/authorization';
import { Alert } from '../utils/toastr';
//const URL_TYPE = 'LOCAL_HOST';
const URL_TYPE: string = 'FROM_ENV';
const BASE_API_PATH = () => {
    if (URL_TYPE === 'LOCAL_HOST') return 'http://localhost:5000/api/';
    if (URL_TYPE === 'FROM_ENV') return process.env.REACT_APP_API;
    return '';
};
export const REQUEST_BODY_TYPE = {
    formData: 'FormData',
    raw: 'Raw',
    json: 'application/json',
    image: 'Image',
    file: 'File',
};
/**
 * Set header
 */
export function headers(image = false) {
    const headers: any = new Headers({
        Accept: '*/*',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store',
        'content-type': 'application/json; charset=utf-8',
    });
    if (image) {
        headers.delete('content-type');
    }
    const accessToken = Authorization.getAccessToken();
    if (accessToken) {
        headers.set('auth-session-token', accessToken);
    }

    return headers;
}

/**
 * Format url
 * @param {string} path
 * @param {object} queryParams
 */
function getUrl(path: any, queryParams?: any) {
    const url = `${BASE_API_PATH()}/${path}`;
    const finalUrl = queryParams ? url + buildQueryParams(queryParams) : url;

    return finalUrl;
}
/**
 * build the query
 * @param {object} queryParams
 */
function buildQueryParams(queryParams: any) {
    let params: any = false;
    const queryLength = Object.keys(queryParams).length;
    let i = 1;
    for (var iter in queryParams) {
        if (typeof queryParams[iter] !== 'undefined')  {
            if (!params) {
                params = '?';
            }
            if (typeof queryParams[iter] === 'object') {
                if (iter === 'sort' && queryParams[iter].type) {
                    params += `${iter}=${
                        queryParams[iter].type === 'DESC' ? '-' : ''
                    }${queryParams[iter].name}`;
                }
                if (iter === 'filter') {
                    for (var queryParamIter in queryParams[iter]) {
                        if (
                            queryParams[iter][queryParamIter] &&
                            queryParamIter !== 'fromDate' &&
                            queryParamIter !== 'toDate'
                        ) {
                            params += `${queryParamIter}${
                                queryParamIter === 'is_active'
                                    ? '='
                                    : queryParamIter === 'firmwareVersion'
                                    ? '=contains:'
                                    : '=iLike:'
                            }${queryParams[iter][queryParamIter]}`;
                            params += '&';
                        }
                    }
                    if (
                        queryParams[iter]['fromDate'] &&
                        queryParams[iter]['toDate']
                    ) {
                        params += `createdAt=between:${queryParams[iter]['fromDate']},${queryParams[iter]['toDate']}`;
                    }
                }
            } else {
                params += iter + '=' + queryParams[iter];
            }
            if (i < queryLength) {
                params += '&';
            }
            i++;
        }
    }
    return params;
}
/**
 * get base api url
 * @param {object} data
 * @param {string} type
 */
function getRequestBody(data: any, type: any) {
    switch (type) {
        case REQUEST_BODY_TYPE.raw:
        case 'Json':
        case REQUEST_BODY_TYPE.json:
            return JSON.stringify(data);
        case REQUEST_BODY_TYPE.image:
            return data;
        default: {
            let body: any;
            body = new FormData();
            Object.keys(data).forEach((key) => {
                body.append(key, data[key]);
            });
            return body;
        }
    }
}
/**
 * Validate response
 * @param {object} response
 * @param {object} request
 */


async function validateResponse(response: any, request: any) {

     if (response.status === 406 || response.status === 401) {
        Authorization.logOut();
        setTimeout(() => {
            window.location.reload();
        }, 1000)
        return response.json().then((err: any) => {
            throw err;
        });
    } else if (response.status === 502 || response.status === 504) {
        return response.json().then((err: any) => {
            var msg = err.message;
            const modifiedData = {
                ...err,
                message:
                    'Oops!!, something went wrong with your connection, please try again' +
                    ' - ' +
                    msg,
            };
            throw modifiedData;
        });
    }
    return await responseParser(response);
}
async function responseParser(response: any) {
    if (response.status === 409 || response.status === 405) {
        return await response.json().then((res: any) => {
            const modifiedData = { ...res, status: res.code };
            return modifiedData;
        });
    }

    if (response.status >= 400 && response.status < 600) {
        return response.json().then((err: any) => {
            throw err;
        });
    }
    // check for header and send the content
    if (
        response.headers.get('content-type').indexOf(REQUEST_BODY_TYPE.json) !==
        -1
    ) {
        // checking response header
        return await response.json().then((res: any) => {
   
            const modifiedData = {
                ...res,
                body: decryptData(res.body) !== ''? decryptData(res.body):res.body,
                status: res.code,
                
            };
            return modifiedData;
        });
    }
    if (response.status === 200) {
        toDownload(response);
    } else {
        // toDownload(response)
        return response.blob();
    }
}
/**
 * Refresh token
 * @param {object} request
 */
async function refresToken(request: any) {
    const body = JSON.stringify({
        refresh_token: Authorization.getRefreshToken(),
    });
    // Refresh token api call
    const res = await fetch(getUrl('refresh-token'), {
        method: 'POST',
        headers: headers(),
        body,
    });
    if (res.status >= 400 && res.status < 600) {
        Authorization.logOut();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        return await res.json().then((err) => {
            err.message = 'Session Expired';
            throw err;
        });
    } else {
        const token = await res.json();
        Authorization.login(token);
        Authorization.setAuthUser();
        const { url, queryParams, method, data, type = 'Json' } = request;
        return await fetch(getUrl(url, queryParams), {
            method,
            headers: headers(),
            mode: 'cors',
            body: getRequestBody(data, type),
        }).then(async (response) => {
            return await responseParser(response);
        });
    }
}
/**
 * Get request
 * @param {object} request
 */

 var alertShown = false;

export async function getRequest(request: any) {
    if (navigator.onLine === false) {
        alert('No internet connection found, please check and try again.');
        return;
    }
    const { url, queryParams } = request;
    return await fetch(getUrl(url, queryParams), {
        method: 'GET',
        headers: headers(),
        mode: 'cors',
    })
        .then((res) => {
            return validateResponse(res, { ...request, method: 'GET' });
        })
        .catch((error: any) => {
            if (error.response) {
            } else if(error.status === 1402){
                if(!alertShown) {
                    alertShown = true;

                Alert('error','Your session has expired. Please login again')
                }else {
                    alertShown = false;
                }
            }else
                {
                console.log(
                    'error message',
                    'Internal Error, Please refresh the page'
                );
            }
        });
}
/**
 * Post or Put request
 * @param {object} request
 */
export async function saveOrUpdateRequest(request: any) {
    if (navigator.onLine === false) {
        alert('No internet connection found, please check and try again.');
        return;
    }
    const { url, data, method, queryParams, type = 'Json' } = request;
    return await fetch(getUrl(url, queryParams), {
        method,
        headers: headers(),
        body: getRequestBody(data, type),
    })
        .then((res) => validateResponse(res, request))
        .catch((error: any) => {});
}
/**
 * Delete request
 * @param {object} request
 */
export async function deleteRequest(request: any) {
    if (navigator.onLine === false) {
        alert('No internet connection found, please check and try again.');
        return;
    }
    const { url, data, queryParams, type = 'Json' } = request;
    return await fetch(getUrl(url, queryParams), {
        method: 'DELETE',
        headers: headers(),
        body: getRequestBody(data, type),
    }).then((res) => validateResponse(res, { ...request, method: 'DELETE' }));
}

// Decrypt data

export function decryptData(encryptedData: any): any {
    var CryptoJS = require('crypto-js');
    //var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(encryptedData), 'test').toString();
    var decryptedData = ''
    try{
        var bytes = CryptoJS.AES.decrypt(encryptedData, 'ij2KIRTbsa');
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        console.log('decrypt',decryptedData)

    }catch(err:any){
    }
    
    return decryptedData;
}
