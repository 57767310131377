/** @format */

import { Button } from '@mui/material';
import { CloudSync } from '@mui/icons-material';
import './SyncVendor.scss';
import { getLocalStorageData } from '../../../../src/utils/useLocalStorage';


const SyncVendor: React.FC<{
    onVendor: any;
}> = ({ onVendor }) => {
    const handleChange = (vendorName: string) => {
        onVendor(vendorName);
    };

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    return (
        <div>
            {(loggedUser?.roleid === 'ADMIN' || loggedUser?.roleid === 'SUPER_ADMIN')  && (
                <div className='vendor-container'>
                    <Button
                        variant='contained'
                        style={{ backgroundColor: '#10384f' }}
                        startIcon={<CloudSync />}
                        size='small'
                        onClick={() => handleChange('Fasal')}>
                        Fasal
                    </Button>

                    <Button
                        variant='contained'
                        style={{ backgroundColor: '#10384f' }}
                        startIcon={<CloudSync />}
                        size='small'
                        onClick={() => handleChange('Arable')}>
                        Arable
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SyncVendor;
